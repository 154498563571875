<div class="width100 posRelative">

  <div class="header timeline-label font20  posRelative lineHeight40px poppins-semibold txtCenter posRelative mrgnbtm10">
    <div class="posAbsolute posTop posLeft d35 bdrRadius50p ripple bg-F1F4FE flex flexAlignItemsCenter flexJustifyCenter cursorPtr" (click)="backToPost();">
        <i class="icns icon-left-arrow d25 datepicker"></i>
    </div>
      {{'choose a gif' | translate}}
  </div>
  <div class="flex flexAlignItemsCenter flexJustifyCenter">
    <app-search-input (search)="searchText = $event"></app-search-input>
  </div>
  <div class="width100 mrgntop15 flex flexWrap maxHeight80vh overflowAuto custom-scrollbar" *ngIf="gifList.length != 0 && (gifList | search: searchText)?.length != 0">
    <div class="width50 cursorPtr pad5" *ngFor="let item of gifList | search: searchText">
      <img [src]="item" alt="" class="width100 height100p cursor ripple" (click)="addGif(item)">
    </div>
  </div>
  <app-empty-state [stateType]="'gifList'" *ngIf="gifList.length == 0 || (gifList | search: searchText).length == 0"></app-empty-state>
</div>
