import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-post-shared',
  templateUrl: './post-shared.component.html',
  styleUrls: ['./post-shared.component.scss']
})
export class PostSharedComponent implements OnInit {
  @Input('post') post: any;
  showMore: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
