<ng-container *ngIf="post.post_type == 'Briindlet'">
    <span [ngSwitch]="post.template_name?.toLowerCase()?.split(' ')?.join('')" class="mrgnbtm20 flex boxShadow-RGBA00005 bdrRadius10px">
        <span *ngSwitchCase="'template1'" class="width100">
            <app-briindlet-pro-template-one class="width100" [data]="post" [customizeUI]="configUI"></app-briindlet-pro-template-one>
        </span>
        <span *ngSwitchCase="'template2'" class="width100">
            <app-briind-pro-template-two class="width100" [data]="post" [customizeUI]="configUI"></app-briind-pro-template-two>
        </span>
        <span *ngSwitchCase="'template3'" class="width100">
            <app-briindlet-pro-template-three class="width100" [data]="post" [customizeUI]="configUI"></app-briindlet-pro-template-three>
        </span>
        <span *ngSwitchCase="'defaulttemplate'" class="width100">
            <app-default-template class="width100" [data]="post" [customizeUI]="configUI"></app-default-template>
        </span>
        <span *ngSwitchDefault class="width100">
            <app-default-template class="width100" [data]="post" [customizeUI]="defualtTemplate"></app-default-template>
        </span>
    </span>
</ng-container>

<div class="bdrRadius10px bdr-1-EEEEEE boxShadow-RGBA00005 width100 pad15 padright5 bg-white mrgnbtm20"
*ngIf="post.post_type != 'Briindlet'">
    <app-post-profile-section-card [post]="post" (removePost)="removePost($event)"></app-post-profile-section-card>
    <div class="">
        <div class="pad5"></div>
        <ng-container *ngIf="post?.description">
            <div class="font15 color-122A4D poppins-regular wrdBreakAll" *ngIf="post?.description.length > 200">
                {{(showMore) ? post?.description : post?.description | slice:0:200}}<span *ngIf="!showMore">...</span>
                <a href="javascript:;" class="seeMore" (click)="showMore = !showMore">{{showMore ? ' See Less': ' See more'}}</a> 
                <div class="pad5"></div>
            </div>
            <div class="font15 color-122A4D poppins-regular wrdBreakAll" *ngIf="post?.description.length < 200">
                {{post?.description}}
                <div class="pad5"></div>
            </div>
        </ng-container>

        <div class='flex flexWrap flexAlignItemsStrecth'>
            <!-- {{attachments?.length}} -->
            <ng-container *ngFor="let item of attachments; let i = index">
                <div class="padright10  padbtm10" *ngIf="i<5"
                    [ngClass]="{ 'width100': attachments?.length == 1 || (attachments?.length == 3 && i == 2 ),'fxWidth50': attachments?.length == 2 || (attachments?.length == 3 && i < 2 ) || (attachments?.length >= 5 && i > 2) || attachments?.length == 4, 'fxWidth33':  (attachments?.length >= 5 && i <= 2)}">

                    <div [ngSwitch]="item?.type" class="height100p posRealtive">
                        <div *ngSwitchCase="'photo'" class="height100p ripple cursorPtr" (click)="viewPost(i);">
                            <img [src]="item?.data?.photo_url"
                                class="width100 height100p bdrRadius6px boxShadow-RGBA00005">
                            <div class="posAbsolute color-FFFFFF font25 poppins-medium flex flexJustifyCenter flexAlignItemsCenter posTop posBtm posLeft posRight bg-RGBA00007 bdrRadius6px"
                                *ngIf="attachments?.length > 5 && i == 4">
                                {{'+ '+(attachments.length - 5)}}
                            </div>
                        </div>
                        <div *ngSwitchCase="'gif'" class="height100p ripple cursorPtr" (click)="viewPost(i);">
                            <img [src]="item?.data?.gif_url"
                                class="width100 height100p bdrRadius6px boxShadow-RGBA00005">
                            <div class="posAbsolute color-FFFFFF font25 poppins-medium flex flexJustifyCenter flexAlignItemsCenter posTop posBtm posLeft posRight bg-RGBA00007 bdrRadius6px"
                                *ngIf="attachments?.length > 5 && i == 4">
                                {{'+ '+(attachments.length - 5)}}
                            </div>
                        </div>
                        <div *ngSwitchCase="'video'" (click)="viewPost(i);"
                            class="height100p boxShadow-RGBA00005 ripple cursorPtr">
                            <app-video-preview-card [media]="item?.data">
                            </app-video-preview-card>
                            <div class="posAbsolute color-FFFFFF font25 poppins-medium flex flexJustifyCenter flexAlignItemsCenter posTop posBtm posLeft posRight bg-RGBA00007 bdrRadius6px"
                                *ngIf="attachments?.length > 5 && i == 4">
                                {{'+ '+(attachments.length - 5)}}
                            </div>
                        </div>

                    </div>

                </div>
            </ng-container>
        </div>

        <app-post-shared [post]="post.post" *ngIf="post.is_share"></app-post-shared>


    </div>
    <div class="pad5"></div>
    <div class="padright10">
        <app-post-action [post]="post" [comments]="(post?.attachments?.length == 1 ? post?.attachments[0]?.data?.comments :  post?.comments)" [theme]="iconClass" [user]="user" (postAction)="postCTA($event)"></app-post-action>
        <div class="pad5"></div>
        <app-post-comment-list [theme]="iconClass" [media]="post?.attachments?.length == 1 ? post?.attachments[0] : ''" [mediaType]="mediaType" [comments]="(post?.attachments?.length == 1 ? post?.attachments[0]?.data?.comments :  post?.comments)" [post]="post"
            [profilePic]="user?.profile_pic">
        </app-post-comment-list>
        <div class="pad5"></div>
        <app-post-comment [theme]="iconClass" [media]="post?.attachments?.length == 1 ? post?.attachments[0] : ''" [mediaType]="mediaType"  [profilePic]="user?.profile_pic" [post]="post"(update)="updatePosts($event)"></app-post-comment>
    </div>
</div>


<!-- <div class="bdrRadius10px bdr-1-EEEEEE boxShadow-RGBA00005 width100 pad15 padright5 bg-white mrgnbtm20"
*ngIf="hashTag">
    <app-post-profile-section-card [post]="post"></app-post-profile-section-card>
    <div class="">
        <div class="pad5"></div>
        <ng-container *ngIf="post?.description">
            <div class="font15 color-122A4D poppins-regular wrdBreakAll" *ngIf="post?.description.length > 200">
                {{(showMore) ? post?.description : post?.description | slice:0:200}}<span *ngIf="!showMore">...</span>
                <a href="javascript:;" class="seeMore" *ngIf="!showMore" (click)="showMore=true">See more</a> 
                <div class="pad5"></div>
            </div>
            <div class="font15 color-122A4D poppins-regular wrdBreakAll" *ngIf="post?.description.length < 200">
                {{post?.description}}
                <div class="pad5"></div>
            </div>
        </ng-container>

        <div class='flex flexWrap flexAlignItemsStrecth'>
            <ng-container *ngFor="let item of attachments; let i = index">
                <div class="padright10  padbtm10" *ngIf="i<5"
                    [ngClass]="{ 'width100': attachments?.length == 1 || attachments?.length == 2 || (attachments?.length == 3 && i == 2 ), 'fxWidth50': (attachments?.length == 3 && i < 2 ) || (attachments?.length >= 5 && i > 2) || attachments?.length == 4, 'fxWidth33':  (attachments?.length >= 5 && i <= 2)}">

                    <div [ngSwitch]="item?.type" class="height100p posRealtive">
                        <div *ngSwitchCase="'photo'" class="height100p ripple cursorPtr" (click)="viewPost(i);">
                            <img [src]="item?.data?.photo_url"
                                class="width100 height100p bdrRadius6px boxShadow-RGBA00005">
                            <div class="posAbsolute color-FFFFFF font25 poppins-medium flex flexJustifyCenter flexAlignItemsCenter posTop posBtm posLeft posRight bg-RGBA00007 bdrRadius6px"
                                *ngIf="attachments?.length > 5 && i == 4">
                                {{'+ '+(attachments.length - 5)}}
                            </div>
                        </div>
                        <div *ngSwitchCase="'gif'" class="height100p ripple cursorPtr" (click)="viewPost(i);">
                            <img [src]="item?.data?.gif_url"
                                class="width100 height100p bdrRadius6px boxShadow-RGBA00005">
                            <div class="posAbsolute color-FFFFFF font25 poppins-medium flex flexJustifyCenter flexAlignItemsCenter posTop posBtm posLeft posRight bg-RGBA00007 bdrRadius6px"
                                *ngIf="attachments?.length > 5 && i == 4">
                                {{'+ '+(attachments.length - 5)}}
                            </div>
                        </div>
                        <div *ngSwitchCase="'video'" (click)="viewPost(i);"
                            class="height100p boxShadow-RGBA00005 ripple cursorPtr">
                            <app-video-preview-card [media]="item?.data">
                            </app-video-preview-card>
                            <div class="posAbsolute color-FFFFFF font25 poppins-medium flex flexJustifyCenter flexAlignItemsCenter posTop posBtm posLeft posRight bg-RGBA00007 bdrRadius6px"
                                *ngIf="attachments?.length > 5 && i == 4">
                                {{'+ '+(attachments.length - 5)}}
                            </div>
                        </div>

                    </div>

                </div>
            </ng-container>
        </div>

        <app-post-shared [post]="post.post" *ngIf="post.is_share"></app-post-shared>


    </div>
    <div class="pad5"></div>
    <div class="padright10">
        <app-post-action [post]="post" [theme]="iconClass" (postAction)="postCTA($event)"></app-post-action>
        <div class="pad5"></div>
        <app-post-comment-list [theme]="iconClass" *ngIf="post?.comments" [comments]="post?.comments" [post]="post"
            [profilePic]="user?.profile_pic">
        </app-post-comment-list>
        <div class="pad5"></div>
        <app-post-comment [theme]="iconClass" [profilePic]="user?.profile_pic" [post]="post"
            (update)="updatePosts($event)"></app-post-comment>
    </div>
</div> -->