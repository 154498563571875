<div class="posRelative minWidth140px img-overlay height100p">
    <div
        class="posAbsolute  posBtm posLeft posRight overlay bdrRadius6px flex flexAlignItemsCenter flexJustifyCenter flexDirectionCol {{customHeightWidth ? customHeightWidth : ''}}">
        <i class="icns icon-camera white d30"></i>
        <input type="file" id="upload-cover" (change)="getImage($event)" #uploadCover (click)="clearFile()"
            class="posAbsolute posTop posBtm posRight posLeft width100 opacity0"
            accept="image/x-png,image/png,image/jpeg,image/jpg">
        <div class="color-FFFFFF mrgntop5 font15 poppins-medium">
            {{'upload photo' | translate }}
        </div>
    </div>
    <img [src]="placeholderImage" id="ministries-cover-photo" #image
        class="{{customHeightWidth ? customHeightWidth : 'width100'}} bdrRadius6px height100p">
</div>


<app-crop-image-popup (close)="showCropper = false" [cropingRatio]="cropingRatio" (uploadImageEvent)="uploadImage($event)" [imageType]="'media'" [imageChangedEvent]="imageChangedEvent"  *ngIf="showCropper"></app-crop-image-popup>
