import { Injectable } from '@angular/core';
import { observable, action } from "mobx-angular";
import { ElasticSearchModel, NotificationModel, PopupModel, ToastModel } from 'src/app/core/models';
import { RegionModel } from '../models/briindlet.model';
import { ActivityModel } from '../models/activity.model';
import { CountryModel } from '../models/countries.model';
import { BriinIdCheckModel } from '../models/shared.model';
import { PaymentModel } from '../models/payment.model';
import { DeviceInfoModel } from '../models/deviceinfo.model';
import { BlockedUsersModel } from '../models/blockeduserslist.model';
import { SavedCardsModel } from '../models/savedcards.model';
import { PrivacySettingsModel } from '../models/privacysettings.model';


@Injectable({
  providedIn: 'root'
})
export class AppStore {
  @observable apiResp: any;
  @observable notificationToken: any;
  @observable popup: PopupModel | any = new PopupModel();
  @observable countryList: CountryModel[] | any[] = [];
  @observable briindAvailabilty: BriinIdCheckModel | any = new BriinIdCheckModel();
  @observable toast: ToastModel | any = new ToastModel();
  @observable notificationList: NotificationModel[] = []
  @observable elasticSearchData: ElasticSearchModel = new ElasticSearchModel();
  @observable activityYears: string[] = [];
  @observable activityListYear: any= {};
  @observable regionList: RegionModel[] = [];
  @observable iconClass: string = 'blue';
  @observable userType : string = 'user';
  @observable membersActivityListYear: any= {};
  @observable friendsActivityListYear: any= {};
  @observable activityList: any[] = [];
  @observable friendsActivityList: any[] = [];
  @observable membersActivityList: any[] = [];
  @observable organizationActivityList: any[] = [];
  @observable organizationActivityListYear: any= {};
  @observable paymentModel: PaymentModel = new PaymentModel();
  @observable deviceInfoList : DeviceInfoModel[] = [];
  @observable blockedUsersModelList : BlockedUsersModel[] = [];
  @observable savedCardsModelList : SavedCardsModel[] = [];
  @observable privacySettingsModel: PrivacySettingsModel = new PrivacySettingsModel();
  @observable selectedRegionInfo : any[] = [];
  @observable selectedItems : any[] = [];

  constructor() { }

  /**
   * TODO: comment action
   * @description Actions user store service
   * @memberof UserStoreService
   */
  @action setUserPrivacySettings(){
    this.privacySettingsModel = this.apiResp.data || this.apiResp;
  }

  /**
   * @description
   * method is used for getting auth-set up page api
   * 
   * @memberof UserAuthStoreService
   */
  @action authSetupPage() {
    this.countryList = [];
    for (const country of this.apiResp.countries) {
      this.countryList.push({ id: country[0], name: country[1] ,is_added : false})
    }
  }

  @action setUserType(type: any) {
    this.userType = type;
    if (this.userType == 'organization') {
      this.iconClass = 'green';
    } else {
      this.iconClass = 'blue';
    }
  }

  /**
   * @description
   * method is sued for setting notification list
   * 
   * @memberof UserAuthStoreService
   */
  @action setNotificationPage() {
    if (Array.isArray(this.apiResp)) {
      this.notificationList = this.apiResp;
    } else {
      this.notificationList = [];
    }
  }

  /**
   * @description
   * method is used for getting Briind ID Checking data
   * 
   * @memberof AppStore
   */
  @action briindCheck() {
    this.briindAvailabilty = this.apiResp;
  }

  /**
   * @description
   * method is used for getting Briind ID Checking data
   * 
   * @memberof AppStore
   */
  @action getElasticSearchData() {
    console.log(this.apiResp.results)
    this.elasticSearchData = this.apiResp.results || this.apiResp;
  }

  /**
   * @description
   * setting region list
   *
   * @memberof AppStore
   */
  @action getRegionList() {
    this.regionList = this.apiResp;
  }

  /**
  * @description
  * method is used for getting activity logs for user and org
  * 
  * @memberof AppStore
  */
  @action setUserOrgActivityLogs(currentYear:number) {
    this.activityYears = Object.keys(this.apiResp[0]).sort((a, b) => b.localeCompare(a));
    this.activityList = this.apiResp[0];
    this.membersActivityList = this.apiResp[1];
    Object.entries(this.activityList).forEach(
      ([key, value]) => {
        if (parseInt(key) == currentYear) {
          this.activityListYear = value;
        }
      });
    Object.entries(this.membersActivityList).forEach(
      ([key, value]) => {
        if (parseInt(key) == currentYear) {
          this.membersActivityListYear = value;
        }
      });
  }

  /**
   * @description
   * Actions app store
   * @param currentYear 
   * @memberof AppStore
   */
  @action setUserOrgActivityLogsByYear(currentYear:number) {
    this.activityListYear = {};
    this.membersActivityListYear = {};
    Object.entries(this.activityList).forEach(
      ([key, value]) => {
        if (parseInt(key) == currentYear) {
          this.activityListYear = value;
        }
      });
    Object.entries(this.membersActivityList).forEach(
      ([key, value]) => {
        if (parseInt(key) == currentYear) {
          this.membersActivityListYear = value;
        }
      });
  }


  /**
   * @description
   * Actions app store
   * @param currentYear 
   * @memberof AppStore
   */
  @action setUserActivityLogs(currentYear:number) {
    this.activityYears = Object.keys(this.apiResp[0]).sort((a, b) => b.localeCompare(a));
    this.activityList = this.apiResp[0];
    this.friendsActivityList = this.apiResp[1];
    this.organizationActivityList = this.apiResp[2];
    Object.entries(this.activityList).forEach(
      ([key, value]) => {
        if (parseInt(key) == currentYear) {
          this.activityListYear = value;
        }
      });
    Object.entries(this.friendsActivityList).forEach(
      ([key, value]) => {
        if (parseInt(key) == currentYear) {
          this.friendsActivityListYear = value;
        }
      });
      Object.entries(this.organizationActivityList).forEach(
        ([key, value]) => {
          if (parseInt(key) == currentYear) {
            this.organizationActivityListYear = value;
          }
        });
  }


  /**
   * @description
   * Actions app store
   * @param currentYear 
   * @memberof AppStore
   */
  @action setUserActivityLogsByYear(currentYear:number) {
    this.activityListYear = {};
    this.friendsActivityListYear = {};
    this.organizationActivityListYear = {};
    Object.entries(this.activityList).forEach(
      ([key, value]) => {
        if (parseInt(key) == currentYear) {
          this.activityListYear = value;
        }
      });
    Object.entries(this.friendsActivityList).forEach(
      ([key, value]) => {
        if (parseInt(key) == currentYear) {
          this.friendsActivityListYear = value;
        }
      });
      Object.entries(this.organizationActivityList).forEach(
        ([key, value]) => {
          if (parseInt(key) == currentYear) {
            this.organizationActivityListYear = value;
          }
        });
  }


  /**
   * @description
   * Actions app store
   * @param activityType 
   * @memberof AppStore
   */
  @action setActivityYears(activityType: string) {
    this.activityYears = [];
    if (activityType == "socialActivity") {
      this.activityYears = Object.keys(this.activityList).sort((a, b) => b.localeCompare(a));
    } else if (activityType == "membersActivity") {
      this.activityYears = Object.keys(this.membersActivityList).sort((a, b) => b.localeCompare(a));
    }
    else if (activityType == "friendsActivity") {
      this.activityYears = Object.keys(this.friendsActivityList).sort((a, b) => b.localeCompare(a));
    }
    else if (activityType == "organizationActivity") {
      this.activityYears = Object.keys(this.organizationActivityList).sort((a, b) => b.localeCompare(a));
    }
  }


    /**
   * TODO: comment action
   * @description Actions organization store service
   * @memberof AppStore
   */
     @action setPaymentSuccess() {
      this.paymentModel = this.apiResp;
    }


    /**
     * TODO: comment action
     * @description Actions app store
     * @memberof AppStore
     */
    @action setUserDeviceInfo(){
      this.deviceInfoList = this.apiResp
    }


    /**
     * TODO: comment action
     * @description Actions app store
     * @memberof AppStore
     */
    @action setTwoFactorAuthCountries() {
      this.countryList = this.apiResp.countries;
    }

    /**
     * TODO: comment action
     * @description Actions app store
     * @memberof AppStore
     */
    @action setBlockedUsersList(){
      this.blockedUsersModelList = this.apiResp;
    }


    /**
     * TODO: comment action
     * @description Actions app store
     * @memberof AppStore
     */
    @action setSavedPaymentCards(){
      this.savedCardsModelList = this.apiResp;
    }


    /**
     * TODO: comment action
     * @description Actions app store
     * @param data 
     * @memberof AppStore
     */
    @action setSelectedRegionData(selectedRegionInfo:any,selectedItems:any){
      this.selectedRegionInfo = selectedRegionInfo;
      this.selectedItems = selectedItems;
    }


    /**
     * TODO: comment action
     * @description Actions app store
     * @returns  
     * @memberof AppStore
     */
    @action getSelectedRegionData(){
      return this.selectedRegionInfo;
    }

    /**
     * TODO: comment action
     * @description Actions app store
     * @returns  
     * @memberof AppStore
     */
    @action getSelectedItemsData(){
      return this.selectedItems;
    }

}
