import { PushNotificationService } from './core/services/push-notification.service';
import { AuthService } from './core/services/auth-service/auth.service';
import { NotificationSocketService } from './core/services/notification-socket.service';
import { Component, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppStore } from 'src/app/core/stores/app.store';
import { AppService } from './core/services/app.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DomSanitizer } from '@angular/platform-browser';
import { config } from 'src/environments/environment';
import { LoaderService } from './core/services/loader.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loaderColor: string = '#02457A';
  message: any = '';
  constructor(
    public sanitizer: DomSanitizer,
    public appStore: AppStore,
    public router: Router,
    public appService: AppService,
    public route: ActivatedRoute,
    public deviceDetectService: DeviceDetectorService,
    public loaderService: LoaderService,
    private notificationSocketService: NotificationSocketService,
    private authService: AuthService,
    private pushNotificationService: PushNotificationService
  ) {
    this.overrideReuseStrategy();
    if(this.authService.isLoggedIn()) {
      notificationSocketService.messages.subscribe((msg: { message: any; }) => {
        console.log(msg)
      });

      // this.appStore.userType = this.authService.getSessionData().type.toLowerCase();
      // this.appStore.iconClass = this.appStore.userType == 'user' ? 'blue' : 'green';
      // this.qbService.successSubject.subscribe(success => {
      //   console.log(success);
      // });
      if (this.appStore.userType == 'organization') {
        this.loaderColor = '#18878c'
      }
    }
  }

  overrideReuseStrategy() {
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
    }
  };


  /**
   * @description
   * Listener Events to monitor the Device network offiline status
   *
   * @param {*} [event]
   * @memberof AppComponent
   */
  @HostListener('window:offline', ['$event'])
  isOffline(event ?: any){
    this.appService.deviceNetworkStatus(event.type)
  }

  /**
   * @description
   * Listener Events to monitor the Device network  online status
   *
   * @param {*} [event]
   * @memberof AppComponent
   */
  @HostListener('window:online', ['$event'])
  isOnline(event ?: any){
    this.appService.deviceNetworkStatus(event.type)
  }


  /**
   * @description
   * Function to check the browser compatibility
   *
   * @memberof AppComponent
   */
  checkBrowserCompatibility(){
    const deviceDetails = this.deviceDetectService.getDeviceInfo();
    if(config.compatibleBrowsers[deviceDetails.browser.toUpperCase()] > parseInt(deviceDetails.browser_version.split('.')[0])){
      let popupObj = { type: 'incompatible', load: true, overlayClose: true, context: {title: 'browser not compatible'} }
      this.appService.togglePopup(popupObj);
    }
  }

  /**
   * @description
   * Called after the constructor, initializing input properties, and the first call to ngOnChanges.
   * Add 'implements OnInit' to the class.
   *
   * @memberof AppComponent
   */
  ngOnInit(): void {
    //this.appService.checkSession();
    this.pushNotificationService.requestPermission()
    this.pushNotificationService.receiveMessage()
    this.message = this.pushNotificationService.currentMessage
  }

}
