import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth-service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class HomeGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isLoggedIn()) {
      if (route.queryParams['logout']) {
        localStorage.clear();
        return true;
      }
      if (this.authService.getSessionData()?.type == 'User') {
        this.router.navigate(['/users/home']);
        return false;
      } else {
        this.router.navigate(['/organizations/home']);
        return false
      }
      
    } else {
      let param: any = route.queryParams;
      if (param['token']) {
        let logginID = JSON.parse(atob(param['token']))
        this.authService.settingLocalSessionData({"token": logginID.token, "briind_id": logginID.briind_id,  "orgId": logginID.orgId || logginID.id, "type": logginID.type})
        if (logginID['type'].toLowerCase() == 'organization') {
          this.router.navigate(['/organizations/home'])
        } else {
          this.router.navigate(['/users/home'])
        }
      }
      if (param['logout']) {
        localStorage.clear();
      }
      return true;
    }


  }
  
}


