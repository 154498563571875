import { OrganizationStoreService } from './../../../../../organizations/store/organization-store.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-organization-new-to-us-template',
  templateUrl: './organization-new-to-us-template.component.html',
  styleUrls: ['./organization-new-to-us-template.component.scss']
})
export class OrganizationNewToUsTemplateComponent implements OnInit {
  @Input('data') data: any;
  @Input('isDisableBtn') isDisableBtn: boolean = true;
  @Input('organizationId') organizationId: any;
  
  constructor(
    public organizationStoreService: OrganizationStoreService
  ) { }

  ngOnInit(): void {
  }

}
