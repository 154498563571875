import { AppService } from 'src/app/core/services/app.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppStore } from 'src/app/core/stores/app.store';

@Component({
  selector: 'app-briindlet-event-guideline',
  templateUrl: './briindlet-event-guideline.component.html',
  styleUrls: ['./briindlet-event-guideline.component.scss']
})
export class BriindletEventGuidelineComponent implements OnInit {
  @Input() formGrp: any;
  @Output() stepperActions = new EventEmitter();

  constructor(
    public appStore: AppStore,
    private appService: AppService
  ) { }

  actionCTA(event : any) {
    this.stepperActions.emit(event);
  }

  ngOnInit(): void {
    // this.formGrp.controls['step_10'].patchValue({
    //   registration_guidelines: this.appService.cacheData1?.step_10?.registration_guidelines
    // })
    // this.appService.cacheData1.subscribe(res=>{
    //   console.log('steps 10', res)
    //   let steps:any = []
    //   steps = res
    //   this.formGrp.controls['step_10'].patchValue({
    //     registration_guidelines: steps.step_10?.registration_guidelines
    //    })
    // })
  }

}
