import { ActionModel } from './action.model';
import { MembershipTemplateModel } from './organization.model';
import { UserModel } from './user.model';
export class ApplicationSubmittedMembershipModel {
    first_name ?: string = '';
    name ?: string = '';
    is_accepted ?: boolean = false;
    status ?: string ='';
    profile_pic ?: string ='';
    actions: ActionModel[] = [];
    work ?: any;
    id: number = 0;
    is_muted ?: boolean = false;
    role ?: any = {};
    isChecked: boolean = false;
}

export class MembershipApplicationDetail {
    assigned_user ?: any;
    name ?: string ='';
    id ?: number = 0;
    is_user_sent ?: boolean = false;
    membership_form ?: MembershipTemplateModel = new MembershipTemplateModel();
    membership_submitted_data ?: any;
    organization ?: number = 0;
    role ?: any;
    status ?: string = '';
    user ?: number = 0;
    work ?: any;
    profile_pic ?: string = '';
    description ?: string = '';
}



export class MembershipGroupList {
    id ?: number = 0;
    name ?: string = '';
    members_count ?: number = 0;
    user ?: any = [];
    cover_photo ?: string = '';
}


export class VolunteerManagementListModel {
    created_at ?: string = '';
    description ?: string = '';
    event_date?: string = '';
    id?: number = 0;
    location?: string = '';
    organization ?: number = 0;
    photo?: string = '';
    status?: string = '';
    title?: string = '';
    volunteers: any =  [];
    chat_id: string = '';
    volunteer_chat_ids: any = []
}

export class EventVolunteerListModel {
    accepted_at ?: string = '';
    id ?: number = 0;
    invited_at ?: string = '';
    organization_event ?: number = 0;
    status ?: string = '';
    user ?: UserModel = new UserModel();
    
}


export class UserMembershipTrendingListModel {
    id ?: number = 0;
    cover_photo ?: string = '';
    name ?: string = '';
    members_count ?:  number = 0 ;
    description ?: string = '';
    actions ?: ActionModel[] = [];
}

export class MemberInvitationListModel {
    id : number = 0;
    role : any;
    actions: ActionModel[] = [];
    organization_data : UserMembershipTrendingListModel = new UserMembershipTrendingListModel();
}

export class RbacRoleModel {
    created_at ?: string = '';
    description ?: string = '';
    functionality  ?: any = [];
    id ?: number = 0;
    is_rbac ?: boolean = false;
    name ?: string = '';
    organization ?: any = 0;
    briind_id ?: any = '';
}