import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PopupModel } from 'src/app/core/models';
import { AppService } from 'src/app/core/services/app.service';
import { UserService } from 'src/app/users/services/user.service';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { OrganizationService } from '../../../../organizations/service/organization.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-create-album',
  templateUrl: './create-album.component.html',
  styleUrls: ['./create-album.component.scss']
})
export class CreateAlbumComponent implements OnInit, OnDestroy, OnChanges {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  fileSrc: any = [];
  disabledOnClick:any = false;
  selectedFiles: any = [];
  createAlbumForm: any = FormGroup;
  subscription: Subscription = new Subscription();
  @Input('postToType') postToType: string = '';
  @Input('postToId') postToId: string = '';
  privacy: string = '';
  removedIds: any = [];
  constructor(
    private userService: UserService,
    private appService: AppService,
    private authService: AuthService,
    private organizationService: OrganizationService
  ) {
    this.initForm();
  }



  /**
   * @description
   * this method is used for intializae form
   *
   * @memberof CreateAlbumComponent
   */
  initForm() {
    this.createAlbumForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      post_type: new FormControl('Album', [Validators.required]),
      description: new FormControl(''),
      photos: new FormControl([])
    });
  }

  /**
   * @description
   * create album form submit method
   *
   * @memberof CreateAlbumComponent
   */
  createAlbumFormSubmit() {
    if (this.createAlbumForm.valid && this.selectedFiles.length || this.fileSrc) {
      let formData = new FormData();
      formData.append('album_privacy', this.privacy ? this.privacy : 'private');
      formData.append('name', this.createAlbumForm.value.name);
      formData.append('description', this.createAlbumForm.value.description);
      formData.append('post_type', 'Album');
      switch (this.popup.context.mediaType) {
        case 'photo':
          if(this.removedIds.length > 0 ) {
            for (let i = 0; i< this.removedIds.length; i++) {
              formData.append('delete_photo_ids[' + i + ']', this.removedIds[i]);
            }
          }
          for (let i = 0; i < this.selectedFiles.length; i++) {
            formData.append('photos[' + i + '][caption]', '');
            formData.append('photos[' + i + '][photo]', this.selectedFiles[i].file);
          }
          break;
        case 'video':
          if(this.removedIds.length > 0 ) {
            for (let i = 0; i< this.removedIds.length; i++) {
              formData.append('delete_video_ids[' + i + ']', this.removedIds[i]);
            }
          }
          for (let i = 0; i < this.selectedFiles.length; i++) {
            formData.append('videos[' + i + '][caption]', '');
            formData.append('videos[' + i + '][video]', this.selectedFiles[i].file);
          }
          break;
        default:
          break;
      }
      formData.append('post_to_type', this.popup.context.postToType || this.authService.getSessionData().type);
      formData.append('post_to_id', this.popup.context.postToId || this.authService.getSessionData().orgId);
      switch (this.authService.getSessionData().type.toLowerCase()) {
        case 'user':
          if(this.popup.context.albumDetails) {
            this.UpdateUserPost(this.popup.context.albumDetails.id, formData);
          } else {
            this.createUserPost(formData);
          }
          break;
        case 'organization':
          if(this.popup.context.albumDetails) {
            this.updateOrgnizationPost(this.popup.context.albumDetails.id, formData);
          } else {
            this.createOrganizationPost(formData);
          }
          break;
        default:
          break;
      }
    }
  }

  /**
   * @description
   * create User Post
   *
   * @param {*} formData
   * @memberof CreateAlbumComponent
   */
   UpdateUserPost(id: number, formData: any) {
    this.disabledOnClick = true
    this.subscription.add(
      this.userService.updateUserPost(id, formData).subscribe((response: any) => {
        this.createAlbumForm.reset();
        this.closePopup();
        this.postSuccessToast(response);
      })
    );
  }

  /**
   * @description
   * create User Post
   *
   * @param {*} formData
   * @memberof CreateAlbumComponent
   */
  createUserPost(formData: any) {
    this.disabledOnClick = true
    this.subscription.add(
      this.userService.createPost(formData).subscribe((response: any) => {
        this.createAlbumForm.reset();
        this.closePopup();
        this.postSuccessToast(response);
      })
    );
  }

  /**
   * @description
   * create organization post
   *
   * @param {*} formData
   * @memberof CreateAlbumComponent
   */
  createOrganizationPost(formData: any) {
    this.disabledOnClick = true
    this.subscription.add(
      this.organizationService.createPost(formData).subscribe((response: any) => {
        this.createAlbumForm.reset();
        this.closePopup();
        this.postSuccessToast(response);
      })
    );
  }

  /**
   * @description
   * create User Post
   *
   * @param {*} formData
   * @memberof CreateAlbumComponent
   */
   updateOrgnizationPost(id: number, formData: any) {
    this.disabledOnClick = true
    this.subscription.add(
      this.organizationService.updateOrgnizationPost(id, formData).subscribe((response: any) => {
        this.createAlbumForm.reset();
        this.closePopup();
        this.postSuccessToast(response);
      })
    );
  }

  /**
   * @description
   * this method is used for show the success toast
   *
   * @memberof CreateAlbumComponent
   */
  postSuccessToast(response?: any) {
    this.appService.toggleToast({
      type: 'success',
      load: true,
      position: 'bottom-left',
      context: { description: response.message }
    });
  }

  /**
   * @description
   * update photos method
   * 
   * @param {*} event
   * @memberof CreateAlbumComponent
   */
  updatePhotosOrVideos(event: any) {
    // const allowed_types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        // if (!_.includes(allowed_types, event.target.files[i].type)) {
        //   return false;
        // }
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.fileSrc.push(event.target.result);
        }
        this.selectedFiles.push({ file: event.target.files[i], caption: '' });
        reader.readAsDataURL(event.target.files[i]);
      }
    }
    console.log(this.selectedFiles);
  }

  /**
   * TODO: comment onAboutSelect
   * @description Determines whether about select on
   * @param event 
   * @memberof PersonalInformationComponent
   */
   onPrivacySelect(event:string){
    this.privacy = event;
  }

  /**
   * @description
   * this method is used for removing the files
   *
   * @param {number} index
   * @memberof CreateAlbumComponent
   */
  removeFiles(index: number, id?: number) {
    this.fileSrc.splice(index, 1);
    this.selectedFiles.splice(index, 1);
    if(id){
      this.removedIds.push(id);
    }
  }


  /**
   * @description
   * this method is used for closing the popup
   *
   * @memberof CreateAlbumComponent
   */
  closePopup() {
    console.log('close');
    this.close.emit('icon');
  }

  /**
   * @description
   * Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
   * Add '${implements OnChanges}' to the class.
   *
   * @param {SimpleChanges} changes
   * @memberof CreateGroupPopupComponent
   */
   ngOnChanges(changes: SimpleChanges): void {
    if (changes.popup && changes.popup.currentValue && this.popup.context.albumDetails && this.popup.context.albumDetails.id) {
      this.createAlbumForm.setValue({
        "name": this.popup.context.albumDetails.name,
        "description": this.popup.context.albumDetails.description,
        "post_type": 'Album',
        "photos": this.popup.context.albumDetails.attachments
      });
      this.fileSrc = this.popup.context.albumDetails.attachments;
    }
  }

  /**
   * @description
   * this method is used for initialize life cycle
   *
   * @memberof CreateAlbumComponent
   */
  ngOnInit(): void {

  }

  /**
   * @description
   * Called once, before the instance is destroyed.
   * Add 'implements OnDestroy' to the class.
   *
   * @memberof CreateAlbumComponent
   */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
