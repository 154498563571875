<div class="pad20 bdrRadius6px bdr-1-EEEEEE bg-white posRelative {{popup?.context?.userType}} posRelative"
    id="addLeaderPopup">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>
    <ng-container *ngIf="isShowGifSection && !isShowTag">
        <app-post-gift-list [gifList]="gifList" (actionCTA)="gifOrTagActionCTA($event,'gif')"></app-post-gift-list>
    </ng-container>
    <ng-container *ngIf="!isShowGifSection && isShowTag">
        <app-post-tag-list [selectedData]="selectedData" (actionCTA)="gifOrTagActionCTA($event,'tag')">
        </app-post-tag-list>
    </ng-container>

    <ng-container *ngIf="!isShowGifSection && !isShowTag">
        <div class="header timeline-label font20 lineHeight40px poppins-semibold txtCenter posRelative">
            {{'create post' | translate }}
        </div>
        <div class="mrgntop20 ">
            <div class="create-post-box bdrRadius10px pad15 boxShadow-RGBA00005 bdr-1-EEEEEE bg-white">
                <div class="flex">
                    <div>
                        <span class="online-img-shadow bg-RGBA2691221 padleft8 bdrRadius50p posRelative displayFlex">
                            <img [src]="popup?.context?.userData?.profile_pic" class="d40 bdrRadius50p">
                        </span>
                    </div>
                    <div class="pad5"></div>
                    <div id="create-post" class="width100 poppins-regular font15">
                        <span class="poppins-semibold timeline-label font16 txtTransformCaps">
                            {{popup?.context?.userData?.name | translate}}
                        </span>
                        <ng-container *ngIf="taggedNames">
                            {{' is with ' + taggedNames}}
                        </ng-container>
                        <app-privacy-check [isCreatePost]="true" [theme]="popup.context.userType == 'user' ? 'blue' : 'green'" [selectedPrivacy]="postPermmision" (onSelect)="selectPrivacy($event)"></app-privacy-check>
                        <textarea [(ngModel)]="description" id="create-post-input" (input)="postbtn()" placeholder="{{'whats on your mind' | translate: {name: (popup?.context?.userData?.first_name || popup?.context?.userData?.name | titlecase)} }}"
                            class="poppins-regular  padtop0">
                        </textarea>
                    </div>
                </div>
                <div class="flex flexWrap flexResponsive flexJustifyBetween maxHeight300px overflowAuto custom-scrollbar"
                    *ngIf="uploadedFiles?.length">
                    <div class="width50Minus10pxLap height100p posRelative mrgnbtm5"
                        *ngFor="let file of uploadedFiles; let i = index;">
                        <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="removeFile(i)">×</span>
                        <div [ngSwitch]="true" class="width100 height100p">
                            <div *ngSwitchCase="file.type == 'photo' || file.type == 'gif'" class="width100 height100p">
                                <img [src]="file.url" class="width100 height100p maxHeight250px bdrRadius4px">
                            </div>
                            <div *ngSwitchCase="file.type == 'video'" class="width100 height100p">
                                <app-video-preview-card [media]="file.url"></app-video-preview-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pad5"></div>
            <app-post-photos-videos-other-upload [postPermission]="postPermmision" [parent]="'popup'" (uploadedFiles)="uploadedFile($event)">
            </app-post-photos-videos-other-upload>
            <div class="flex mrgntop20">
                <button (click)="closePopup();"
                    class="btn-get-started padleft20 padright20 padtop10 minWidth120px padbtm10 cancel-btn font15 ripple poppins-medium cursorPtr">{{'cancel' | translate}}</button>
                <div class="pad5"></div>
                <button (click)="createPost();"
                    class="btn-get-started flex flexJustifyCenter posRelative padleft20 padright20 minWidth120px padtop10 padbtm10 btn-user-default font15 ripple poppins-medium cursorPtr"
                    [disabled]="((uploadedFiles?.length == 0) && btnPost) || submittingForm">{{'post' | translate}}
                    <div class="posAbsolute posRight5px" *ngIf="submittingForm">
                        <app-loader [widthClass]="'d20'"></app-loader>
                    </div>
                </button>
            </div>
        </div>
    </ng-container>
</div>