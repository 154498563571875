<div 
    class="bdr-1-EEEEEE bdrRadius6px bg-FFFFFF boxShadow-RGBA00005  flex flexAlignItemsStart flexResponsive flexJustifyBetween organization pad15 posRelative">
    <div [routerLink]="['/organizations',organizationID,'membership','event',data?.id]" class="cursorPtr flex flexAlignItemsStart flexResponsive posRelative">
    <img [src]="data?.photo" class="d120 bdrRadius6px">
    <div class="pad10"></div>
    <div class="flex1">
        <div class="font18 poppins-semibold color-122A4D">
            {{data?.title}}
        </div>
        <div class="mrgntop5 flex">
            <div class="flex flexResposive">
                <div class="padright10 flex color-122A4D poppins-medium font14">
                    <i class="icns icon-time green d20 mrgnright5"></i>
                    {{data?.event_date | fullDate}}
                </div>
                <div class="padright10 flex color-122A4D poppins-medium font14">
                    <i class="icns icon-location green d20 mrgnright5"></i>
                    {{data?.location}}
                </div>
            </div>
        </div>
        <div class="color-122A4D poppins-regular font14 mrgntop10">
            {{data.description | truncate:[250]}}
        </div>
    </div>
</div>
    <div (click)="editEventCTA(data)"
        class="d30 flex flexJustifyCenter flexAlignItemsCenter cursorPtr ripple bdrRadius50p">
        <i class="icns icon-edit blue d20"></i>
    </div>
</div>