import { AuthService } from './../../../../../core/services/auth-service/auth.service';
import { UserService } from './../../../../../users/services/user.service';
import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-post-tag-list',
  templateUrl: './post-tag-list.component.html',
  styleUrls: ['./post-tag-list.component.scss']
})
export class PostTagListComponent implements OnInit {
  @Output() actionCTA = new EventEmitter();
  subscription: Subscription = new Subscription();
  @Input() selectedData: any = [];
  friendsList: any = [];
  constructor(
    private userService: UserService,
    private authService: AuthService
  ) { }

  backToPost() {
    this.actionCTA.emit('');
  }

  selectFriends(index: number) {
    this.friendsList[index].isChecked = true;
    this.selectedData.push(this.friendsList[index].targeted_user)
  }

  cancelTaggedFriend(user: any) {
    _.remove(this.selectedData, (obj: any) => obj.id == user.id);
    for (let friend of this.friendsList) {
      console.log(friend)
      if (friend.targeted_user.id == user.id) {
        friend.isChecked = false;
        break;
      } 
    }
  }

  addTaggedFriends() {
    this.actionCTA.emit(this.selectedData);
  }

  getFriendsList(event ?: any) {
    let data = {
      'user_id': this.authService.getSessionData().orgId,
      'search_param': event || ''
    }
    this.subscription.add(
      this.userService.getFriendsList(data).subscribe((response: any) => {
        this.friendsList = response;
        for (let user of this.selectedData) {
          for (const friend of  this.friendsList) {
            if (user.id == friend.targeted_user.id) {
              friend.isChecked = true;
              break;
            }
          }
        }
      })
    );
  }

  ngOnInit(): void {
    this.getFriendsList();
  }

}
