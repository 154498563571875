<div class="{{appStore?.userType}}">
    <div class="timeline-label  header txtCenter font23 poppins-medium fontWeight600 color-122A4D mrgnbtm20">
        {{'add photos or videos' | translate}}
    </div>
    <div class="timeline-label  header font16 poppins-medium fontWeight500 color-18878C mrgnbtm20">
        {{'you can add three videos or photos' | translate}}
    </div>
    <div class="body width100">
        <div class="width100 flex flexResponsive flexAlignItemsCenter mrgnbtm20 posRelative">
            <div class="d140 bdrRadius6px mrgnright10 posRelative" *ngFor="let item of selectedFiles; let i = index;">
                <img src="{{item.base64}}" class="d140 bdrRadius6px">
                <span class="close-popup ripple bg-F1F4FE bdrRadius4px timeline-label" (click)="removeImage(i);">×</span>
            </div>
            <div class="d140 posRelative bdrRadius6px" *ngIf="selectedFiles?.length < 3">
                <img src="assets/images/add-pic-layer.png" class="d140 bdrRadius6px">
                <input type="file" (change)="getImage($event)" class="posAbsolute posTop posLeft posRight posBottom width100 height100p opacity0">
                <!-- <app-upload-photo (imageEvent)="getImage($event)" [isMultiShow]="true" [placeholderImage]="'assets/images/add-pic-layer.png'"></app-upload-photo> -->
            </div>
        </div>
        <div class="width100 padtop30 flex">
            <button  (click)="actionCTA('previous')"  class="cancel-btn bdr-1-EEEEEE pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr">
                {{'back' | translate}}
            </button>
            <div class="pad5"></div>
            <button [disabled]="formGrp?.controls?.step_13?.invalid" (click)="actionCTA('continue_to_campaign')"  class="btn-user-default pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr">
                {{'continue' | translate}}
            </button>
        </div>
    </div>
    <app-crop-image-popup (close)="showCropper = false" (uploadImageEvent)="uploadImage($event)" [imageType]="'media'" [imageChangedEvent]="imageChangedEvent"  *ngIf="showCropper"></app-crop-image-popup>
</div>


