import { AppService } from 'src/app/core/services/app.service';
import { config } from 'src/environments/environment';
import { Component, OnInit, Output, EventEmitter, Input, ElementRef, ViewChild, HostListener } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { getImagePath } from 'src/app/core/essentials/common.libs';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-post-photos-videos-other-upload',
  templateUrl: './post-photos-videos-other-upload.component.html',
  styleUrls: ['./post-photos-videos-other-upload.component.scss']
})
export class PostPhotosVideosOtherUploadComponent implements OnInit {
  @Output() uploadedFiles = new EventEmitter();
  toggled: boolean = false;
  @Input('parent') parent: string = 'card';
  @Input() postPermission: string = 'everyone';
  @ViewChild('emojiIconContainer') emojiIconContainer: ElementRef | any;
  loader = this.loadingBar.useRef();

  constructor(
    public authService: AuthService,
    public loadingBar: LoadingBarService,
    private appService: AppService
  ) { }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event ?: any): void {
    if (((this.emojiIconContainer && !this.emojiIconContainer.nativeElement.contains(event.target)))) {
      this.toggled = false;
    }
  }

  uploadGifOrTagFriend(event: any, type: string) {
    this.uploadedFiles.emit({url: '', file:  '', type: type })
  }

  handleSelection(event: any) {
    this.uploadedFiles.emit({type: 'emoji', value: event.emoji ? event.emoji.native : '' })
    if (this.parent == 'card') {
      this.toggled = false;
    }
  }

  showEmoji(event: any) {
      this.toggled = !this.toggled;
  }

  /**
   * @description
   * upload file method
   *
   * @param {*} event
   * @param {string} type
   * @memberof PostPhotosVideosOtherUploadComponent
   */
  uploadFile(event: any, type: string) {
    if (event.target && event.target.files[0]) {
      this.loader.start();
      let url: any = '';
      switch (type) {
        case 'photo': case 'video':
          console.log(event.target.files[0]) 
          if ((event.target.files[0].size /1024/1024) < config.postFileSize) {
            let reader: any = getImagePath(event,'');
            reader.onload = (e:any) => {
              this.uploadedFiles.emit({url: e.target.result, file: event.target.files[0], type: type });
              this.loader.stop();
            };
          } else {
            this.appService.toggleToast({
              type: 'error',
              load: true,
              position: 'bottom-left',
              context: { description: `Maximum file size ${config.postFileSize}MB` }
            });
            this.loader.stop();
          }
         
          break;
        default:
          this.loader.stop();
          break;
      }
    }

  }


  ngOnInit(): void {
  }

}
