import { AppService } from 'src/app/core/services/app.service';
import { AppStore } from './../../../../../core/stores/app.store';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-briindlet-event-entrance',
  templateUrl: './briindlet-event-entrance.component.html',
  styleUrls: ['./briindlet-event-entrance.component.scss']
})
export class BriindletEventEntranceComponent implements OnInit {
  @Input() formGrp: any;
  @Output() stepperActions = new EventEmitter();
  constructor(
    public appStore: AppStore,
    private appService: AppService
  ) { }

  actionCTA(event : any) {
    this.stepperActions.emit(event);
  }

  ngOnInit(): void {
    // this.formGrp.controls['step_9'].patchValue({
    //   fee: this.appService.cacheData1?.step_9?.fee
    // })
    // this.appService.cacheData1.subscribe(res=>{
    //   console.log('steps 9', res)
    //   let steps:any = []
    //   steps = res
    //   this.formGrp.controls['step_9'].patchValue({
    //     fee: steps.step_9?.fee
    //    })
    // })
  }

}
