import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationService } from './../../../organizations/service/organization.service';
import { UserService } from './../../../users/services/user.service';
import { AppStore } from 'src/app/core/stores/app.store';
import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { AppService } from 'src/app/core/services/app.service';
import { QbService } from 'src/app/core/services/qb.service';

@Component({
  selector: 'app-user-organization-header',
  templateUrl: './user-organization-header.component.html',
  styleUrls: ['./user-organization-header.component.scss']
})
export class UserOrganizationHeaderComponent implements OnInit, OnDestroy {
  @Output() inIt = new EventEmitter();
  notificationList : any = [];
  @ViewChild('mobileNav') mobileNav: ElementRef | any;
  public subscription: Subscription = new Subscription();
  @Input() data: any;
  userData: any;
  userType: string = 'user';
  parentRouter: string = 'users';
  iconClass: string = 'blue';
  routerLinkForProfile: string = '';
  postType: string  = 'Post';
  postToType: string  = '';
  postToId: string  = '';
  isActiveLink: boolean = false;
  currentUserBriindID: any = '';
  unreadMessage: number = 0;
  notificationCount: number = 0;
  constructor(
    private authService: AuthService,
    private appService: AppService,
    public appStore: AppStore,
    private organizationService: OrganizationService,
    private userService: UserService,
    private translateService: TranslateService,
    private router: Router,
    private qbService: QbService,
  ) { 
    this.userType = this.authService.getSessionData().type.toLowerCase();
    this.currentUserBriindID = this.authService.getSessionData()?.briind_id;
    if (this.userType == 'organization') {
      this.parentRouter = 'organizations';
      this.iconClass = 'green';
    }

    this.isActiveLink = this.router.url.includes('membership')
    this.qbService.successSubject.subscribe(res => {
      this.getMessageCount();
    })
  }

  getMessageCount() {
    setInterval( (x: any)=> {
      this.appService.getMessageCount().subscribe((response: any) => {
        this.unreadMessage = response.total;
      })
    }, 4000)
  }

  getNotificationCOunt() {
    this.subscription.add(
      this.appService.getNotificationsCount(this.parentRouter).subscribe((response: any) => {
        this.notificationCount = response.not_seen_count;
        console.log(response)

      })
    );
  }

  currentData(data: any) {
    if (data) {
      this.userData = data;
      localStorage.setItem('data', JSON.stringify(data));
      this.appStore.userType = this.userType ;
      this.appStore.iconClass = this.iconClass;
      this.appStore.setUserType(this.userType)
      if (this.userType == 'organization') {
        this.routerLinkForProfile = `/${this.parentRouter}/${this.userData.briind_id}/profile/bussiness`;
        if (!this.userData.chat_id) {
          this.updateOrganizationChatID(this.userData.id)
        }
      } else {
        this.routerLinkForProfile = `/${this.parentRouter}/${this.userData.briind_id}/timeline`;
        if (!this.userData.chat_id) {
          this.updateUserChatID(this.userData.id)
        }
      };
      this.inIt.emit(true);
      this.getUserSettings();
      this.getNotificationCOunt();
    }
    
   
  }


  updateOrganizationChatID(id: any) {
    const param = {
      chat_id: JSON.parse(localStorage.getItem('loggedinUser') || '').id
    }
    this.subscription.add(
      this.organizationService.updateOrganizationInfo(param, id).subscribe(response => {
      })
    )
  }

  updateUserChatID(id: any) {
    let param = {
      chat_id: ""
    }
    try {
      param = {
        chat_id: JSON.parse(localStorage.getItem('loggedinUser') || '').id
      }
    } catch (error) {

    }
    this.subscription.add(
      this.userService.updateUserInfo(param, id).subscribe(response => {
        console.log(response)
      })
    )
  }

  getTopNotifications() {
    this.subscription.add(
      this.appService.getTopNotifications(this.userType +'s', {}).subscribe(response => {
        this.notificationList = response;
      })
    )
  }

   /**
   * @description
   * This method is used for toggle the mobile menu
   *
   * @memberof UserOrganizationHeaderComponent
   */
  mobileNavToggleCTA() {
    let mobileNative = this.mobileNav.nativeElement.classList;
    mobileNative.contains('mobile-nav-container') ? mobileNative.remove('mobile-nav-container') : mobileNative.add('mobile-nav-container');
  }

  /**
   * @description
   * This method is used for removinng local storage and signed out user
   * @memberof UserOrganizationHeaderComponent
   */
  logoutCTA() {
    this.appService.logoutSession();
  }

  ngOnInit(): void {
    // this.openSearchPopup();
    this.getTopNotifications();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.subscription)
    this.subscription.unsubscribe();
  }

  openSearchPopup(){
    this.appService.togglePopup({
      type: 'elasticSearch',
      load: true,
      refreshParent: true,
      overlayClose: true,
      context: {
        theme: 'green',
        parent: 'organization',
        mediaType: "photo",
      }
    });
  }
  
  getUserSettings() {
    this.appService.getUserSettings(`${this.userType}s`).subscribe(response => {
      this.appStore.setUserPrivacySettings();      
      this.translateService.setDefaultLang(this.appStore.privacySettingsModel.language || 'en');
    })
  }

    /**
   * @description
   * this method is used for open the create post
   *
   * @param {*} [file]
   * @memberof UserOrganizationHeaderComponent
   */
     openCreatePostPopup(file ?: any) {
      this.appService.togglePopup({
        type: 'createPost',
        load: true,
        refreshParent: true,
        overlayClose: true,
        context: {
          userType: this.userType,
          userData: this.userData,
          uploadedFiles: (file && file.type != 'giff' && file.type != 'emoji'  && file.type != 'tag')   ? [file]: [],
          postType: this.postType,
          postToType: this.postToType,
          postToId: this.postToId,
          fileType: file ? file.type : '',
          emoji: file ? file.value : ''
        }
      });
    }

    openInviteUserPopup() {
      this.appService.togglePopup({
        type: 'inviteUser',
        load: true,
        refreshParent: true,
        overlayClose: true,
        context: {
          userType: this.userType,
          userData: this.userData
        }
      });
    }
  

}
