<div [formGroup]="addCausesForm"
        class="transitionMaxHeightEaseOutp2 bg-F9F9F9 pad30 bdrRadiusBottomLeftt6px bdr-1-EEEEEE bdrRadiusBottomRight6px"
        id="causeTemplate">
        <div class="padbtm20">
            <input type="text" formControlName="title"
                [ngClass]="{ 'bdr-red': addCausesForm?.controls?.title?.invalid && (addCausesForm?.controls?.title?.touched || addCausesForm?.controls?.title?.dirty) }"
                placeholder="{{'cause title' | translate}}"
                class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px">
        </div>
        <div class="padbtm20">
            <textarea type="text" formControlName="description"
                [ngClass]="{ 'bdr-red': addCausesForm?.controls?.description?.invalid && (addCausesForm?.controls?.description?.touched || addCausesForm?.controls?.description?.dirty) }"
                placeholder="{{'cause description' | translate}}"
                class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px"></textarea>
        </div>
        <div class="padbtm20">
            <input type="url" formControlName="web_link"
                [ngClass]="{ 'bdr-red': addCausesForm?.controls?.web_link?.invalid && (addCausesForm?.controls?.web_link?.touched || addCausesForm?.controls?.web_link?.dirty) }"
                placeholder="{{'weblink' | translate}}"
                class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px">
        </div>
        <div class="pad15"></div>
        <div class="timeline-label font18 poppins-semibold">{{'cause photos' | translate}}</div>
        <div class="pad10"></div>
        <div class="flex">
            <div class="d150">
                <div class="mrgnauto d140">
                    <app-upload-photo (imageEvent)="addImages($event)"
                        [placeholderImage]="'assets/images/add-pic-layer.png'" [isMultiShow]="true">
                    </app-upload-photo>
                </div>
            </div>
            <div class="d150" *ngFor="let image of displayImages; let i = index;">
                <div class="posRelative width100 d140 mrgnauto">
                    <img [src]="image?.base64" class="bdrRadius6px d140">
                    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="removeImage(i);">×</span>
                </div>
            </div>
        </div>
        <div class="pad5"></div>
        <div class="width100 padtop15 minHeight300px">
            <div class="width100 flex flexResponsive flexWrap">
                <div class="padright10 padbtm10 width33" *ngFor="let bank of data?.account_list" (click)="selectAccount(bank)">
                    <app-bank-account-card [data]="bank"
                        [isSelected]="addCausesForm?.value?.bank_account_id?.toString() == bank.id.toString()">
                    </app-bank-account-card>
                </div>
            </div>
        </div>
        <!-- <div class="flex">
            <div class="width50" *ngFor="let bank of data?.account_list">
                <div class="mrgnauto ripple cursorPtr" (click)="selectAccount(bank)">
                    <app-bank-account-card [data]="bank"
                        [isSelected]="addCausesForm?.value?.bank_account_id?.toString() == bank.id.toString()">
                    </app-bank-account-card>
                </div>
            </div>
        </div> -->
        <div class="pad10"></div>
        <button [disabled]="addCausesForm?.invalid || ((displayImages?.length == 0)) || btndisable" (click)="onSubmit()"
            class="btn-get-started btn-18878C minwidth100px bdr-1-EEEEEE color-18878C ripple cursorPtr font14 poppins-regular pad1030">
            {{'submit' | translate}}
        </button>
    </div>