<div
    class="bdrRadius10px cursorPtr ripple bdr-1-EEEEEE boxShadow-RGBA00005 posRelative width100 pad15 bg-white mrgnbtm15">
    <div class="width100 posRelative" [routerLink]="['/organizations',(organization.briind_id || organization.id) ,'profile','bussiness']">
        <img [src]="organization.cover_photo || organization.profile_pic || organization.photo || organization.photo_url"
            class="bdrRadius10px width100 minHeight150px maxHeight150px">
        <a *ngIf="organization.is_promoted"
            class="bg-F1F4FE pad0510 timeline-label poppins-light posRight5px  posTop5px bdrRadius30px font14 posAbsolute">{{'promoted'
            | translate}}</a>
    </div>
    <div class="width100 flex flexJustifyBetween mrgntop10 flexAlignItemsCenter flexWrap">
        <div>
            <div class="poppins-semibold font14 color-122A4D ">{{organization?.name}}</div>
            <div class="poppins-regular font13 color-8E8E8E "
                *ngIf="organization?.overview?.city && organization?.overview?.country">
                {{organization?.overview?.city}}, {{organization?.overview?.country}}</div>
        </div>
        <div class="" *ngIf="!organizationFollowed && organization?.is_followed == false">
            <button (click)="followOrganization(organization?.id, organization?.name)"
                class="btn-get-started ripple cursorPtr mrgnauto btn-user-default poppins-medium font14 flexAlignSelfCenter">
                {{'follow' | translate}}
            </button>
        </div>
        <div class="" *ngIf="organizationFollowed || organization?.is_followed == true">
            <button (click)="unfollowOrganization(organization?.id, organization?.name)"
                class="btn-get-started ripple cursorPtr mrgnauto btn-user-default poppins-medium font14 flexAlignSelfCenter">
                {{'unfollow' | translate}}
            </button>
        </div>
    </div>
</div>