import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { PopupModel } from 'src/app/core/models';
import { UserService } from 'src/app/users/services/user.service';
import { UserStoreService } from 'src/app/users/store/user-store.service';
import { suggestedFriends } from 'src/app/core/mock-api';
import { AppService } from 'src/app/core/services/app.service';


@Component({
  selector: 'app-friends-popup',
  templateUrl: './friends-popup.component.html',
  styleUrls: ['./friends-popup.component.scss']
})
export class FriendsPopupComponent implements OnInit, OnDestroy, OnChanges {
  subscription: Subscription = new Subscription();
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  userID: number = 0;
  friendList: any = [];
  constructor(
    public userStoreService: UserStoreService,
    private userService: UserService,
    public appService: AppService
  ) { 
  }

  /**
   * @description
   * Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
   * Add '${implements OnChanges}' to the class.
   *
   * @param {SimpleChanges} changes
   * @memberof InviteFriendsPopupComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.popup && changes.popup.currentValue) {
      if (this.popup.context.data && this.popup.context.data.length != 0) {
        this.friendList = this.popup.context.data;
      }
    }
  }

  ngOnInit(): void {
  }
  /**
   * @description
   * method is used for search suggested friends
   * 
   * @param {*} param
   * @memberof SuggestedFriendsPopupComponent
   */
  searchSuggestedFriend(param: any) {
    this.subscription.add(
      this.userService.getSuggestedUserList(param).subscribe((response) => {
        this.userStoreService.getSuggestedUserList();
        this.friendList = JSON.parse(JSON.stringify(this.userStoreService.suggestedUserList));
      })
    )
  }

  /**
   * @description
   * method is used for search pending friends
   * 
   * @param {*} param
   * @memberof SuggestedFriendsPopupComponent
   */
  searchPendingRequest(param: any) {
    this.subscription.add(
      this.userService.getPendingUserList(param).subscribe((response) => {
        this.friendList = response;
      })
    )
  }
  
  /**
   * @description
   * method is used for call method for search suggested friends / pending friend request
   *
   * @param {*} param
   * @memberof SuggestedFriendsPopupComponent
   */
  searchFriends(event: any) {
    const param = {
      search_param: event
    }
    if(this.popup.context.actionType == "FriendRequest") {
      this.searchPendingRequest(param);
    } else if (this.popup.context.actionType == "SuggestedFriends") {
      this.searchSuggestedFriend(param);
    }
  }

  /**
   * @description
   * this method is used for Add Friend
   *
   * @param {*} data
   * @memberof SuggestedFriendsPopupComponent
   */
  createFriend(id: number) {
    let data = {
      'targeted_user_id': id
    }
    this.subscription.add(
      this.userService.createFriend(data).subscribe((response: any) => {
        if(response.status.toLowerCase() == "success") {
          this.appService.showToaster(response);
        }
        for (const item of this.friendList) {
          if (id == item.id) {
            item.connection_detail = {
              "id": response.result ? response.result.id : '',
              "status":  response.result ? response.result.id: '',
            }
            item.actions = [suggestedFriends[1]]
            break;
          }
        }
        // this.closePopup();
        // this.getSuggestedFriendList();
      })
    );
  }

  /**
   * @description
   * this method is used for Add Friend
   *
   * @param {*} data
   * @memberof SuggestedFriendsPopupComponent
   */
  cancelFriend(id: number) {
    this.subscription.add(
      this.userService.cancelFriend(id).subscribe((response: any) => {
        if(response.status.toLowerCase() == "success") {
          this.appService.showToaster(response);
        }
        for (const item of this.friendList) {
          if (id == item.connection_detail.id) {
            item.connection_detail = { }
            item.actions = [suggestedFriends[0]]
            break;
          }
        }
      })
    );
  }

  /**
   * @description
   * this method is used for Accept Friend Request
   *
   * @param {number} id
   * @param {string} notification_id
   * @memberof SuggestedFriendsPopupComponent
   */
  acceptFriendRequest(id: number, notification_id: string) {
    let data = {
      'notification_id': notification_id
    }
    this.subscription.add(
      this.userService.acceptFriendRequest(id, data).subscribe(response => {
        this.closePopup();
      })
    );
  }

  /**
   * @description
   * this method is used for Accept Friend Request
   *
   * @param {number} id
   * @param {string} notification_id
   * @memberof SuggestedFriendsPopupComponent
   */
  rejectFriendRequest(id: number, notification_id: string) {
    let data = {
      'notification_id': notification_id
    }
    this.subscription.add(
      this.userService.rejectFriendRequest(id, data).subscribe(response => {
        this.closePopup();
      })
    );
  }

  /**
   * @description
   * this method is used for call add friend/cancel friend api
   *
   * @param {*} data
   * @memberof SuggestedFriendsPopupComponent
   */
  cardActionCTA(event: any) {
    console.log(event);
    switch (event.action_text) {
      case 'addfriend':
        this.createFriend(event.id);
        break;
      case 'cancel':
        this.cancelFriend(event.id);
        break;
      case 'accept':
        this.acceptFriendRequest(event.id, event.notification_id);
        break;
      case 'reject':
        this.rejectFriendRequest(event.id, event.notification_id);
        break;  
      default:
        break;
    }
  }

  /**
  * @description
  * Click on close button this method will call
  *
  * @memberof SuggestedFriendsPopupComponent
  */
  closePopup() {
    this.close.emit('icon');
  }

  /**
    * @description
    * Called once, before the instance is destroyed.
    * Add 'implements OnDestroy' to the class.
    * @memberof SuggestedFriendsPopupComponent
    */
   ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
