import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  /**
   * @description
   * This method is using to find wheather the user is signed in or not
   * @returns This method will return True / False
   * @memberof AuthService
  */
  isLoggedIn() {
    return (localStorage.getItem('sessionData') ? true : false)
  }

  /**
   * @description
   * this is used for getting data from localstorage
   *
   * @returns this method will return session data 
   * @memberof AuthService
   */
  getSessionData() {
    if (localStorage.getItem('sessionData') ) {
      return JSON.parse(localStorage.getItem('sessionData') || '');
    }
  }

  /**
   * @description
   * this method using for setting session data in local storage
   *
   * @param {*} [data]
   * @memberof AuthService
   */
  settingLocalSessionData(data ?: any) {
    localStorage.setItem('sessionData', JSON.stringify(data))
  }

  /**
   * @description
   * this method using for removing session data in local storage
   *
   * @param {*} [data]
   * @memberof AuthService
   */
  removingLocalSessionData(data ?: any) {
    localStorage.removeItem('sessionData')
  }
}
