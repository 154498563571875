import { AppService } from 'src/app/core/services/app.service';
import { AppStore } from './../../../../../core/stores/app.store';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-briindlet-event-about',
  templateUrl: './briindlet-event-about.component.html',
  styleUrls: ['./briindlet-event-about.component.scss']
})
export class BriindletEventAboutComponent implements OnInit {
  @Input() formGrp: any;
  @Output() stepperActions = new EventEmitter();
  constructor(
    public appStore: AppStore,
    private appService: AppService
  ) { }

  actionCTA(event: any) {
    this.stepperActions.emit(event);
  }

  ngOnInit(): void {
    // this.formGrp.controls['step_5'].patchValue({
    //   description: this.appService.cacheData?.step_5?.description
    //  })
    //  this.appService.cacheData.subscribe(res=>{
    //   console.log('steps 5', res)
    //   let steps:any = []
    //   steps = res
    //   this.formGrp.controls['step_5'].patchValue({
    //     description: steps.step_5.description,
    //    })
    // })
  }

}
