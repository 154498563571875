<div id="create-organization-event"
    class="bdrRadius6px bg-white pad20 padright10 organization posRelative overflowHidden">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>
    <div id="create-organization-event-popup" class="overflowHidden">
        <div class="width100 mrgnauto">
            <div class="poppins-semibold font22 timeline-label padbtm20 txtCenter ">
                {{ 'enter event details' | translate}}
            </div>
            <form class="width100" [formGroup]="form">
                <ng-container *ngIf="stepper == 1">
                    <div class="padright10">

                        <div class="width100 mrgntop10">
                            <input class="card-input" formControlName="name"
                                [ngClass]="{ 'bdr-red': form?.controls?.name?.invalid && (form?.controls?.name?.touched || form?.controls?.name?.dirty) }"
                                [placeholder]="'event title' | translate" />
                        </div>
                        <div class="width100 mrgntop10">
                            <textarea class="card-input" formControlName="description"
                                [ngClass]="{ 'bdr-red': form?.controls?.description?.invalid && (form?.controls?.description?.touched || form?.controls?.description?.dirty) }"
                                [placeholder]="'description' | translate"></textarea>
                        </div>
                        <div class="width100 mrgntop10 flex">
                            <!-- [ngClass]="{ 'bdr-red': control['aboutMe'].invalid && (control['aboutMe'].touched || control['aboutMe'].dirty) }" -->
                            <div class="width50">
                                <input class="card-input" formControlName="location"
                                    [ngClass]="{ 'bdr-red': form?.controls?.location?.invalid && (form?.controls?.location?.touched || form?.controls?.location?.dirty) }"
                                    [placeholder]="'location' | translate">
                            </div>
                            <div class="pad5"></div>
                            <div class="width50">
                                <input formControlName="event_date" [min]="min"
                                    [ngClass]="{ 'bdr-red': form?.controls?.event_date?.invalid && (form?.controls?.event_date?.touched || form?.controls?.event_date?.dirty) }"
                                    [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="{{'date' | translate}}"
                                    type="text" readonly="true"
                                    class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular input-icon date bdr-1-EEEEEE bg-white outline0 bdrRadius6px calender-green input-icon">
                                <owl-date-time #dt1></owl-date-time>
                            </div>
                        </div>
                        <a class="timeline-label font15 poppins-medium flex fontItalic ripple mrgntop10 mrgnbtm10 flexJustifyEnd cursorPtr" (click)="continueNext()" *ngIf="popup?.context?.data">{{'add more members' | translate}}</a>
                        <div class="pad20 bg-F1F4FE bdrRadius6px mrgntop10 txtCenter posRelative ripple bgRepeat-noRepeat bgSize100p100p minHeight250px" style="background-image: url({{selectedFile?.cover_photo || popup?.context?.data?.photo}})">
                            <input type="file" (change)="uploadPhoto($event)"
                                class="posAbsolute posTop posLeft posRight posLeft ripple opacity0 height100p width100" />
                            <i class="icns icon-camera green d30 mrgntop10"></i>
                            <ng-container *ngIf="!selectedFileData">
                                <div class="timeline-label font14 poppins-medium">
                                    {{'ready to add something' | translate}}
                                </div>
                                <div class="timeline-label font14 poppins-medium mrgnbtm10">
                                    {{'drag or upload single photo or video' | translate}}
                                </div>
                            </ng-container>
                            <ng-container *ngIf="selectedFileData">
                                <div class="timeline-label font14 poppins-medium mrgnbtm10">
                                    {{selectedFileData?.name | translate}}
                                </div>
                            </ng-container>
                        </div>
                        <span class="color-red">{{showImageError}}</span>
                    </div>
                </ng-container>


                <ng-container *ngIf="stepper == 2">
                    <div class="flex flexAlignItemsCenter padright10">
                        <div class="width40">
                            <select class="card-input" (change)="changesMembersType($event)">
                                <!-- <option [value]="'all_members'">{{'all members' | translate}}</option> -->
                                <option [value]="'few_members'">{{'all members' | translate}}</option>
                                <option [value]="'group'">{{'group' | translate}}</option>
                            </select>
                        </div>
                        <div class="pad10"></div>
                        <div class="width60">
                            <app-search-input (search)="searchList($event)" [searchPlaceholder]="'search'"
                                [icon]="'searchIcon'" [isLimitWidth]="false">
                            </app-search-input>
                        </div>
                    </div>
                    <div class="flex mrgntop15 flexWrap">
                        <ng-container *ngFor="let item of list">
                            <div class="width50 padright10 padbtm10" *ngIf="(!popup?.context?.data?.volunteers?.includes(item.user_id) && popup?.context?.data?.volunteer_type == 'few_members') || (!popup?.context?.data?.volunteer_group_ids?.includes(item.id) && popup?.context?.data?.volunteer_type == 'group') || !popup?.context?.data?.volunteers" >
                                <app-general-card (cardActionCTA)="cardActionCTA()" [data]="item" [type]="'OrganizationGroupMembers'"></app-general-card>
                            </div>
                        </ng-container>
                        
                        
                    </div>
                    <app-empty-state *ngIf="list.length == 0 || checkData()"></app-empty-state>
                </ng-container>

                <ng-container *ngIf="stepper == 3">
                    <div class="width100">
                        <div class="width60 mrgnauto">
                            <app-search-input (search)="searchText = $event;" [searchPlaceholder]="'search'"
                                [icon]="'searchIcon'" [isLimitWidth]="false">
                            </app-search-input>
                        </div>
                        <div class="flex mrgntop15">
                            <div class="width50 padright10 padbtm10" *ngFor="let item of selectedList | search: searchText">
                                <app-general-card (cardActionCTA)="cardActionCTA()"  [data]="item" [type]="'OrganizationGroupMembers'"></app-general-card>
                            </div>
                        </div>
                        <app-empty-state *ngIf="selectedList.length == 0 || (selectedList | search: searchText)?.length == 0"></app-empty-state>
                    </div>
                </ng-container>
                <div class="flex mrgntop15" *ngIf="!popup?.context?.data">
                    <button (click)="stepper = 2; buttonName = 'send invite';" *ngIf="stepper == 3"
                        class="btn-get-started bdr-1-EEEEEE padtop10 padbtm10 minWidth100px flexJustifyCenter flex flexAlignItemsCenter cancel-btn cursorPtr ripple poppins-regular bdrRadius30px font14">
                        {{ 'edit' | translate}}
                    </button>
                    <button (click)="continueNext()" [disabled]="(stepper == 1 && form.invalid) || (stepper == 2 && this.selectedList?.length == 0)"
                        class="btn-get-started bdr-1-EEEEEE padtop10 padbtm10 minWidth100px flexJustifyCenter flex flexAlignItemsCenter btn-user-default cursorPtr ripple poppins-regular bdrRadius30px font14">
                        {{ buttonName | translate}}
                    </button>
                </div>
                <div class="flex mrgntop15" *ngIf="popup?.context?.data">
                    <button (click)="updateEvent()"
                    class="btn-get-started bdr-1-EEEEEE padtop10 padbtm10 minWidth100px flexJustifyCenter flex flexAlignItemsCenter btn-user-default cursorPtr ripple poppins-regular bdrRadius30px font14">
                    {{ 'save' | translate}}
                </button>
                </div>
            </form>
        </div>
    </div>
</div>

<app-crop-image-popup (close)="showCropper = false" (uploadImageBase64Event)="uploadImage($event)" [imageType]="imageType"
  [imageChangedEvent]="imageChangedEvent" *ngIf="showCropper"></app-crop-image-popup>