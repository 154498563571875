import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { Subscription } from 'rxjs';
import { PopupModel } from 'src/app/core/models';
import { AppService } from 'src/app/core/services/app.service';
import { AppStore } from 'src/app/core/stores/app.store';
import { OrganizationService } from 'src/app/organizations/service/organization.service';
import { OrganizationStoreService } from 'src/app/organizations/store/organization-store.service';

@Component({
  selector: 'app-cause-donate-popup',
  templateUrl: './cause-donate-popup.component.html',
  styleUrls: ['./cause-donate-popup.component.scss']
})
export class CauseDonatePopupComponent implements OnInit, OnDestroy {
  @Input() userType: string = '';
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  stepperCount:number = 1;
  @ViewChild(StripeCardComponent)
  public card!: StripeCardComponent;
  subscription: Subscription = new Subscription();
  stripeTest!: FormGroup;
  disablePayBtn: boolean = false;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };
  constructor(private fb: FormBuilder, public stripeService: StripeService,
    public organizationService: OrganizationService,
    public organizationStoreService: OrganizationStoreService, 
    private router: Router, public appService: AppService, 
    public appStore:AppStore) { }

    giveCauseDonate() {
      console.log(this.popup.context.id);
      console.log(this.stripeTest);
      if (this.stripeTest.valid) {
        this.disablePayBtn = true;
        this.stripeService.stripe
          .createToken(this.card.element)
          .subscribe((result) => {
            if (result.token) {
              let data = { 
                card_token: result.token.id,
                amount: this.stripeTest.value.amount,
                currency: this.stripeTest.value.currency ? this.stripeTest.value.currency : 'USD',
                describe: this.stripeTest.value.describe
              };
              this.subscription.add(
                this.appService.proceedToPayment(data).subscribe((response: any) => {
                  if (response.status.toLowerCase() == "success") {
                    this.appStore.setPaymentSuccess();
                    this.disablePayBtn = false;
                        if (response.status == 'success') {
                          let data = {
                            "content": "Cause",
                            "content_id": this.popup.context.id,
                            "status": "success",
                            "message": "Payment for cause",
                            "amount": this.stripeTest.value.amount,
                            "currency": this.stripeTest.value.currency ? this.stripeTest.value.currency : 'USD',
                          }
                          this.subscription.add(
                            this.appService.savePaymentStatus(data).subscribe((res: any) => {
                              
                            })
                          )
                          this.appService.toggleToast({
                            type: 'success',
                            load: true,
                            position: 'bottom-left',
                            context: { description: 'Payment successfull' }
                          });
                          this.stepperCount = 2;
                        }  
                  } else if (response.status.toLowerCase() == "failure") {
                    this.disablePayBtn = false;
                    this.appService.toggleToast({
                      type: 'failure',
                      load: true,
                      position: 'bottom-left',
                      context: { description: 'Payment failed' }
                    });
                    this.stepperCount = 3;
                  }
                })
              );
  
            } else if (result.error) {
              // Error creating the token
              console.log(result.error.message);
              this.disablePayBtn = false;
            }
          });
      }
    }

    /**
  * @description
  * Click on close button this method will call
  *
  * @memberof CauseDonatePopupComponent
  */
     closePopup() {
      // if (this.stepperCount == 1) {
      //   this.router.navigate(['/advertisement/create']);
      // } else {
      //   this.router.navigate(['/advertisement/list']);
      // } 
      this.close.emit('icon');
    }

  ngOnInit(): void {
    this.stripeTest = this.fb.group({
      amount: ['', [Validators.required]],
      currency: ['',[Validators.required]],
      description: ['']
    });
  }

  /**
  * @description
  * Called once, before the instance is destroyed.
  * Add 'implements OnDestroy' to the class.
  *
  * @memberof CauseDonatePopupComponent
  */
   ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
