<div class="flex flexJustifyBetween">
    <div class="color-122A4D poppins-semibold font20 mrgnleft5">{{'name and basic informations' | translate}}</div>
    <div *ngIf="showEditIcon" [routerLink]="['/organizations',organizationID,'profile','social','basic-info']" class="d30 flex flexJustifyCenter flexAlignItemsCenter cursorPtr ripple bdrRadius50p">
        <i class="icns icon-edit blue d20"></i>
    </div>
</div>
<div class="flex flexResponsive flexWrap">
    <div class="width50Minus10pxLap mrgn5 bg-E5ECF1 bdrRadius6px flex pad10 width100" *ngIf="data && data[data?.display_type]">
        <div class="d20">
            <i class="icns icon-map green d20"></i>
        </div>
        <div class="padleft10 flex flexDirectionCol flexJustifyBetween">
            <div class="color-8E8E8E font14 poppins-regular lineHeight18px">
                {{'organization name' | translate}}
            </div>
            <div class="color-122A4D font14 poppins-medium">{{data[data?.display_type] }}
            </div>
        </div>
    </div>
</div>
<div class="mrgn5 bg-E5ECF1 bdrRadius6px flex pad10 width100" *ngIf="data?.door_no">
    <div class="d20">
        <i class="icns icon-map green d20"></i>
    </div>
    <div class="padleft10 flex flexDirectionCol flexJustifyBetween">
        <div class="color-8E8E8E font14 poppins-regular lineHeight18px">
            {{'address' | translate}}
        </div>
        <div class="color-122A4D font14 poppins-medium">
            <ng-container *ngIf="data?.email_name">
                {{data?.email_name}},<br/>
            </ng-container>
            <ng-container *ngIf="data?.door_no || data?.street">
                {{data?.door_no}} {{data?.street}},<br/>
            </ng-container>
            <ng-container *ngIf="data?.city || data?.state">
                {{data?.city}} {{data?.state}},<br/>
            </ng-container>
            <ng-container *ngIf="data?.country || data?.zip_code">
                {{data?.country}} {{data?.zip_code}}
            </ng-container>
        </div>
    </div>
</div>
<div class="flex flexResponsive flexWrap">
    <div class="width50Minus10pxLap mrgn5 bg-E5ECF1 bdrRadius6px flex pad10 width100" *ngIf="data?.phone_number">
        <div class="d20">
            <i class="icns icon-work green d20"></i>
        </div>
        <div class="padleft10 flex flexDirectionCol">
            <div class="color-8E8E8E font14 poppins-regular lineHeight18px">
                {{'established on' | translate}}
            </div>
            <div class="color-122A4D font14 poppins-medium">{{data?.established_on | date: 'dd/MM/yyyy'}}
            </div>
        </div>
    </div>
    <div class="width50Minus10pxLap mrgn5 bg-E5ECF1 bdrRadius6px flex pad10 width100 wrdBreakWord" *ngIf="data?.website">
        <div class="d20">
            <i class="icns icon-email green d20"></i>
        </div>
        <div class="padleft10 flex flexDirectionCol flexJustifyBetween wrdBreakWord">
            <div class="color-8E8E8E font14 poppins-regular lineHeight18px">
                {{'email' | translate}}
            </div>
            <div class="color-122A4D font14 poppins-medium">{{data?.email_name}}
            </div>
        </div>
    </div>
</div>
<div class="flex flexResponsive flexWrap">
    <div class="width50Minus10pxLap mrgn5 bg-E5ECF1 bdrRadius6px flex pad10 width100" *ngIf="data?.phone_number">
        <div class="d20">
            <i class="icns icon-call green d20"></i>
        </div>
        <div class="padleft10 flex flexDirectionCol">
            <div class="color-8E8E8E font14 poppins-regular lineHeight18px">
                {{'phone number' | translate}}
            </div>
            <div class="color-122A4D font14 poppins-medium">{{data?.phone_number}}
            </div>
        </div>
    </div>
    <div class="width50Minus10pxLap mrgn5 bg-E5ECF1 bdrRadius6px flex pad10 width100 wrdBreakWord" *ngIf="data?.website">
        <div class="d20">
            <i class="icns icon-public green d20"></i>
        </div>
        <div class="padleft10 flex flexDirectionCol flexJustifyBetween wrdBreakWord">
            <div class="color-8E8E8E font14 poppins-regular lineHeight18px">
                {{'website' | translate}}
            </div>
            <div class="color-122A4D font14 poppins-medium">{{data?.website}}
            </div>
        </div>
    </div>
</div>