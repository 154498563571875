export class PostModel {
    created_at ?: string = '';
    description ?: string = '';
    gifs ?: any[] = [];
    id ?: number = 0;
    name ?: string = '';
    photos ?: PostPhotosModel[] = [];
    post_type ?: string = '';
    item_type ?: string = '';
    likes_count : number = 0;
    liked ?: boolean = false;
    updated_at ?: string = '';
    videos ?: any[] = [];
    attachments ?: PostAttachmentModel[] = []
    posted_by ?: PostedByModel = new PostedByModel();
    posted_to ?: PostedByModel = new PostedByModel();
    is_share ?:boolean;
    content_type ?: string = '';
    object_id ?: number = 0;
    user ?: any[] = [];
    album_privacy ?: string = '';
}

export class PostPhotosModel {
    caption ?: string = '';
    created_at ?: string = '';
    id ?: number = 0;
    photo ?: string ='';
    updated_at ?: string = '';  
    photo_url ?: string = '';
    video_url ?: string = '';
    gif_url ?: string = '';
    post ?: any = '';
} 

export class PostAttachmentModel {
    data : PostPhotosModel = new PostPhotosModel();
    type : string = '';
}

export class PostedByModel {
    photo ?: string = '';
    user_type ?: string = '';
    name ?: string = '';
    id ?: number = 0;
}