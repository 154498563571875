<div class="{{userType}}">
    <div class="timeline-label header txtCenter font23 poppins-medium fontWeight600 color-122A4D mrgnbtm20">
        {{'your template preview' | translate}}
    </div>

    <!-- start banner  -->
    <div class="body width100 maxHeight520px overflowAuto custom-scrollbar posRelative" *ngIf="data">
        <div class=" flex">
            <div class="briindlet-banner flex cursorPtr width100" routerLink="/organizations/briindlet/detail">
                <div class="posRelative width100">
                    <div class="card_image">
                        <img class="width100 height300px bdrRadius6px boxShadow-RGBA00005 valignMiddle"
                            [src]="photo ? photo : 'assets/images/briindlet-preview.png'">
                    </div>
                    <div [ngClass]="userType == 'user' ? 'bg-02457A' : 'bg-18878C'"
                        class="opacityp8 posTop15px posAbsolute lef pad10 mrgn10" style="width: 95%;">
                        <h3 class="poppins-medium txtCenter mrgnbtm10 font16 color-white mrgn0 padbtm5">
                            {{data?.name}}
                        </h3>
                        <ng-container *ngIf="perviewType == 'briindltePerview'">
                            <div class="width100 mrgnbtm10 flex flexResponsive flexJustifyCenter">
                                <div class="flex pad5">
                                    <i class="icon-location green icns d18"></i>
                                    <p class="mrgn0 padbtm5 color-white font14 poppins-semibold mrgnleft10">
                                        {{ venue }}
                                    </p>
                                </div>
                                <div class="flex pad5">
                                    <i class="icon-clock-green icns d18"></i>
                                    <p class="mrgn0 color-white font14 fontWeight500 poppins-semibold mrgnleft10">
                                        {{'last date of registration' | translate}} : {{data.registration_last_date | date: 'd/M/yyyy'}}
                                    </p>
                                </div>
                            </div>
                            <p class="card_text mrgn0 padbtm5 txtCenter padbtm5 font16 fontWeight500 
                           poppins-semibold color-white">
                                {{data.event_from | fullDate}}
                            </p>
                        </ng-container>
                        <ng-container *ngIf="perviewType == 'briindadPerview'">
                            <div class="width100 mrgnbtm10 flex flexResponsive flexJustifyCenter">
                                <div class="flex pad5">
                                    <i class="icon-clock-green icns d18"></i>
                                    <p class="mrgn0 padbtm5 color-white font14 poppins-semibold mrgnleft10">
                                       {{ 'start date' | translate}} : {{data.start_date | minimalDate}}
                                    </p>
                                </div>
                                <div class="flex pad5">
                                    <i class="icon-clock-green icns d18"></i>
                                    <p class="mrgn0 color-white font14 fontWeight500 poppins-semibold mrgnleft10">
                                        {{ 'end date' | translate}} : {{data.end_date | minimalDate}}
                                    </p>
                                </div>
                            </div>
                            <p class="card_text mrgn0 padbtm5 txtCenter padbtm5 font16 fontWeight500 
                           poppins-semibold color-white">
                                {{data.event_from | date:'EE, d MMM AT HH:mm'}}
                            </p>
                        </ng-container>
                        
                    </div>
                </div>
            </div>
        </div>
        <!-- end banner  -->

        <div class="bg-E5ECF1">
            <div class=" section660 txtCenter font16 color-122A4D poppins-regular mrgnbtm20 mrgntop20">
                {{data.description}}
            </div>
            <div *ngIf="data.website_link != '' && perviewType == 'briindltePerview'" class="section660 txtCenter font16 color-122A4D poppins-regular mrgnbtm20">
                <button type="button" class="btn-user-default mrgnright25 btn-get-started btn-18878C 
                minwidth100px bdr-1-EEEEEE color-18878C ripple cursorPtr 
                font14 poppins-semibold pad1030" (click)="redirectToWebsite(data.website_link)">
                {{'website link ' | translate}}
                    </button>
               
                <!-- {{'website link ' | translate}}:- <a href="https://www.{{data.website_link}}">{{data.website_link}}</a> -->
            </div>
        </div>
        <div *ngIf="userType != 'user' && data.cause != '' && perviewType == 'briindltePerview'">
            <div class="width100">
                <button type="button" class="width100 btn-user-default mrgnright25 btn-get-started btn-18878C 
            minwidth100px bdr-1-EEEEEE color-18878C ripple cursorPtr 
            font14 poppins-semibold pad1030">
                    {{ 'donate now' | translate }}
                </button>
            </div>
        </div>
    </div>