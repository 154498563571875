import { PushNotificationService } from './core/services/push-notification.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/* Core libs start here */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
/* Core libs end here */


/* Third party library start here */
export function TranslatorFactory(httpClient: HttpClient) { return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json'); }
import { MobxAngularModule } from 'mobx-angular';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { HttpInterceptorService } from './core/interceptor/http.interceptor';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { DeviceDetectorModule } from 'ngx-device-detector';

export function getSocialLoginServiceConfigs() {
  let socialConfig = new SocialLoginServiceConfig(
    [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider(config.facebookAppId)
      },
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(config.googleClientId)
      }
    ]
  );
  return socialConfig;
}

/* Third party library end here */

/* components start here */
import { HomeComponent } from './components/home/home.component';
/* components end here */

/* modules start here */
import { SharedModule } from './shared/shared.module';
import { SocialLoginServiceConfig, SocialLoginService } from './core/services/social-login/social-login.service';
import { FacebookLoginProvider, GoogleLoginProvider } from './core/providers';
import { config } from 'src/environments/environment';
import { ComingSoonComponent } from './shared/components/coming-soon/coming-soon.component';
import { QBHelper } from './core/helper/qbHelper';
import { Helpers } from './core/helper/helpers';
import { ViewPostDetailComponent } from './components/view-post-detail/view-post-detail.component';
import { AsyncPipe } from '@angular/common';

/* modules start here */

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ComingSoonComponent,
    ViewPostDetailComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AngularFirestoreModule,
    AngularFireAuthModule, 
    AngularFireStorageModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(config.firebase),
    FormsModule,
    ReactiveFormsModule,
    MobxAngularModule,
    SharedModule,
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,
    DeviceDetectorModule.forRoot(),
     /* Setting up the Translate Module with factory and loader as Root  */
     TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslatorFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
  ],
  providers: [
    SocialLoginService,
    {
      "provide": SocialLoginServiceConfig,
      "useFactory": getSocialLoginServiceConfigs
    },
    {
      provide:HTTP_INTERCEPTORS,
      useClass:HttpInterceptorService,
      multi:true
    },
    QBHelper,
    Helpers,
    PushNotificationService,
    AsyncPipe
  ],

  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {
  constructor(public translateService: TranslateService){
    translateService.addLangs(["en","hi","es"]);
    translateService.setDefaultLang('en'); /* Setting up the Translate Json to English - `en` */
  }
 }
