import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OtpFieldModel } from 'src/app/core/models/form.model';

@Component({
  selector: 'app-two-factor-auth-code',
  templateUrl: './two-factor-auth-code.component.html',
  styleUrls: ['./two-factor-auth-code.component.scss']
})
export class TwoFactorAuthCodeComponent implements OnInit, OnChanges {
  otpFields: number[]= [];
  form: FormGroup = new FormGroup({});
  @Input() public data: OtpFieldModel | any;
  @Input() public error: string = '';
  @Output('otp') otp = new EventEmitter();
  @Output() resend = new EventEmitter();

  constructor(

  ) { }

  /**
   * @description
   * This Method Is For Getting The each form Controls of formGroup to Check The Validation
   * @param {string} [controlName]
   * @returns the specfic FormControl Based on the Param
   * @memberof TwoFactorAuthCodeComponent
  */
  f(controlName?: string) {
    return (this.form.get(controlName || ''))
  }

  /**
   * @description
   * This Method Will Call After Calling Forgot Api To Construct OTP Fields
   * @param {number} num
   * @memberof TwoFactorAuthCodeComponent
  */
  constructOTPFields(num: number) {
    this.otpFields = [];
    this.form = new FormGroup({});
    for (let i = 0; i < num; i ++) {
      this.form.addControl(`otp${i}`,  new FormControl( '' , [Validators.required]) )
      this.otpFields.push(i)
    }
  }

  /**
   * @description
   * This Method Will Call On Entering Keys In Otp Fields
   * 
   * @param {number} i
   * @param {*} event
   * 
   * @returns
   * This method Will return Either True / False
   * @memberof TwoFactorAuthCodeComponent
   */

  checkOtp(i : number, event ?: any) {
    let id: any = i;
    let div: any;
    if (event.keyCode == 8) {
      id = `otp${i-1}`;
      div = document.getElementById(id) as HTMLInputElement;
      if (div) {
        div.focus();
      }
    } else if (!(event.key).match(/^\d+$/)) {
      return false;
    } else {
      id = `otp${i+1}`;
      div = document.getElementById(id) as HTMLInputElement;
      if (div) {
        div.focus();
      }
    }
  }

  /**
   * @description
   * submit auth form
   *
   * @memberof TwoFactorAuthCodeComponent
   */
  onSubmit() {
    let value: string = '';
    for (let item of this.otpFields) {
      value = value+''+this.form.value[`otp${item}`];
    };
    this.otp.emit(value);
  }

  
  /**
   * @description
   * Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
   * Add '${implements OnChanges}' to the class.
   *
   * @param {SimpleChanges} changes
   * @memberof TwoFactorAuthCodeComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      this.constructOTPFields(this.data.count)
    }
  }

  resendOtp(){
    this.resend.emit()
  }

  /**
   * @description
   * Called after the constructor, initializing input properties, and the first call to ngOnChanges.
   * Add 'implements OnInit' to the class.
   *
   * @memberof TwoFactorAuthCodeComponent
   */
  ngOnInit(): void {
    this.constructOTPFields(this.data.count)
  }
}
