<div id="createalbum-popup" class="bdrRadius6px bg-white pad20 posRelative custom-scrollbar {{popup?.context?.parent}}">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>
    <div id="social-create-album-pop" class="overflowHidden">
        <div class="width100 mrgnauto">
            <div class="poppins-semibold font22 timeline-label padbtm20 txtCenter ">
                {{ popup.context.title ? popup.context.title :  'create album' | translate}}</div>
            <div class="width100 mrgnauto bdr-1-EEEEEE boxShadow-RGBA00005 bg-FFFFFF bdrRadius6px flex flexResponsive overflowAuto maxHeight80vh custom-scrollbar">
                <div class="width40 height100p bdr-1-EEEEEE bdrRadius6px bg-E7E8Eb boxShadow-RGBA00005">
                    <form [formGroup]="createAlbumForm" (ngSubmit)="createAlbumFormSubmit()">
                        <div class="pad15">
                            <div class="flex flexJustifyBetween flexAlignItemsCenter padbtm30">
                                <div class="poppins-medium font18 timeline-label">{{'upload' | translate}}</div>
                                <div class="posRelative bg-FFFFFF bdrRadius6px">
                                    <app-privacy-check (onSelect)="onPrivacySelect($event)" [selectedPrivacy]="popup.context.albumDetails ? popup.context.albumDetails.album_privacy : 'private'" [theme]="popup?.context?.theme || 'blue'"></app-privacy-check>
                                </div>
                            </div>
                            <div class="padbtm10">
                                <input type="text" formControlName="name" [ngClass]="{ 'bdr-red': createAlbumForm?.controls?.name?.invalid && (createAlbumForm?.controls?.name?.touched || createAlbumForm?.controls?.name?.dirty) }" placeholder="{{'album name' | translate}}"
                                    class="card-input boxShadow-RGBA00005 color-484848 poppins-regular">
                            </div>
                            <div class="padbtm10">
                                <textarea formControlName="description"
                                    class="font16 width100 boxShadow-RGBA00005 color-484848 resizeNone pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px minHeight150px"
                                    placeholder="{{'description (optional)' | translate}}" rows="2"></textarea>
                            </div>
                            <div
                                class="posRelative bg-RGBA2691221 bdrRadius6px padtop15 padbtm15 flex flexAlignItemsCenter flexJustifyCenter">
                                <input type="file" 
                                    class="posAbsolute ripple posTop posRight posLeft posRight opacity0 width100 height100p"
                                    multiple (change)="updatePhotosOrVideos($event)">
                                <i class="icns icon-camera {{popup?.context?.theme}} d20"></i>
                                <span
                                    class="poppins-medium color-02457A font14 padleft10">{{'upload photos/videos' | translate}}</span>
                            </div>
                            <div class="width30 padtop20">
                                <button type="submit" [disabled]="createAlbumForm.invalid || fileSrc?.length  == 0 || disabledOnClick"
                                    class="btn-get-started btn-user-default minwidth100px bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad1030">
                                    {{'post' | translate}}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="width60 pad15 flex flexAlignItemsStart flexWrap">
                    <div *ngIf="!fileSrc?.length" class="width100 height100p flex flexAlignItemsCenter flexJustifyCenter">
                        <img src="assets/images/cr-album.png" />
                    </div>
                    <ng-container *ngIf="fileSrc?.length > 0">
                        <div class="mrgnright10 mrgnbtm10 posRelative" *ngFor="let item of fileSrc; let i = index;">
                            <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="removeFiles(i, item?.data?.id);">×</span>
                            <div [ngSwitch]="popup?.context?.mediaType">
                                <!-- <img [src]="item" *ngSwitchCase="'photo'" class="bdrRadius10px d120" /> -->
                                <div class="cover-profile-image posRelative flex flexJustifyCenter">
                                    <span class="online-img-shadow bdrRadius50p posRelative displayFlex">
                                      <img [src]="item?.data ? item?.data?.photo_url : item" *ngSwitchCase="'photo'" class="d120 bdrRadius10px objectFitContain">
                                   
                                    </span>
                                  </div>
                                <div  *ngSwitchCase="'video'" class="bdrRadius10px d120">
                                    <app-video-preview-card [media]="item?.data ? item?.data?.video_url : item"></app-video-preview-card>
                                </div>
                                <div *ngSwitchDefault>output2</div>
                            </div>
                            
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>