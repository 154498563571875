import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-profile-thumbnail',
  templateUrl: './profile-thumbnail.component.html',
  styleUrls: ['./profile-thumbnail.component.scss']
})
export class ProfileThumbnailComponent implements OnInit {
  @Input('image') image: string ='';
  @Input('imageClass') imageClass: string = 'd45';
  @Input('isShowBorder') isShowBorder: boolean = true;
  constructor() { }

  ngOnInit(): void {
    
  }

}
