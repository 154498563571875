export class NotificationModel {
    approval_status: string = '';
    approve_url: string = '';
    content_id: number = 0;
    content_type: string = '';
    created_at: string = '';
    description: string = '';
    detail_url: string = '';
    error_message: any =  [];
    created_by ?: any;
    id: string = '';
    name: string = '';
    notification_sub_type: string = '';
    notification_type: string = '';
    organization_id: number = 0;
    reject_url: string = '';
    seen_at: string = '';
    status: string = '';
    title: string = '';
    user_type: string = '';
}

export class ElasticSearchModel {
    "users" ?:  any = []
    "videos" ?: any = []
    "photos" ?: any = []
    "gifs" ?: any = []
    "groups" ?: any = []
    "organizations" ?: any = []
    "posts" ?: any = []
}