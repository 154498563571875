import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { PopupModel, DropDownModel } from 'src/app/core/models';
import { AuthService } from '../../../../core/services/auth-service/auth.service';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { OrganizationService } from '../../../../organizations/service/organization.service';
import { AppService } from '../../../../core/services/app.service';

@Component({
  selector: 'app-create-rbac-popup',
  templateUrl: './create-rbac-popup.component.html',
  styleUrls: ['./create-rbac-popup.component.scss']
})
export class CreateRbacPopupComponent implements OnChanges{
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  parent: string = '';
  configDataForMultiSelect: DropDownModel = new DropDownModel();
  form: FormGroup = new FormGroup({});
  private subscription: Subscription = new Subscription();
  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    private appService: AppService
  ) {
    this.parent = this.authService.getSessionData().type.toLowerCase();
    this.form = fb.group({
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      functionality:  this.fb.array([]),
      organization: new FormControl(this.popup.context.organizationID) 
    })
    
  }

  /**
   * @description
   * for adding the
   *
   * @param {string} funtcionality
   * @param {*} isChecked
   * @memberof CreateRbacPopupComponent
   */
  onChange(funtcionality: string, event: any) {
    const functionalityFormArray = <FormArray>this.form.controls.functionality;
    let isChecked:any = event.target ? event.target.checked : event;
    if (isChecked) {
      functionalityFormArray.push(new FormControl(funtcionality));
    } else {
      let index = functionalityFormArray.controls.findIndex(x => x.value == funtcionality)
      functionalityFormArray.removeAt(index);
    };
  }

  onSubmit() {
    let params = this.form.value;
    if (!params['organization']) {
      params['organization'] = this.popup.context.organizationID
    }
    if (this.popup.context.formValue.id) { 
      this.subscription.add(
        this.organizationService.updateRole(this.popup.context.formValue.id, params).subscribe(response => {
          this.toggleToast('Role Updated Successfully')
        })
      )
    } else{
      this.subscription.add(
        this.organizationService.createRole(params).subscribe(response => {
          this.toggleToast('Role Created Successfully')
        })
      )
    }
    
  }

  toggleToast(msg: string) {
    this.appService.toggleToast({
      type: 'success',
      load: true,
      position: 'bottom-left',
      context: { description: msg }
    });
    this.close.emit('icon');
  }

  /**
   * @description
   * Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
   * Add '${implements OnChanges}' to the class.
   *
   * @param {SimpleChanges} changes
   * @memberof CreateRbacPopupComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.popup && changes.popup.currentValue && this.popup.context.formValue) {
      this.form.patchValue({
        name:  this.popup.context.formValue.name || '',
        description:  this.popup.context.formValue.description || '',
        organization: this.popup.context.organizationID
      });
     
      for (const item of this.popup.context.formValue.functionality) {
        for (const functionality of this.popup.context.functionalityList) {
          if (functionality.id == item.id) {
            this.onChange(item.id, true);
            functionality.isChecked = true;
            break;
          }
        };
      }
    }
  }


  /**
   * @description
   * this method is used for closing the popup
   *
   * @memberof CreateRbacPopupComponent
   */
  closePopup() {
		this.close.emit('icon');
  }
                                                                                                                                                                
}
