import { AppService } from 'src/app/core/services/app.service';
import { AppStore } from './../../../../../core/stores/app.store';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-briindlet-event-briindlet-media',
  templateUrl: './briindlet-event-briindlet-media.component.html',
  styleUrls: ['./briindlet-event-briindlet-media.component.scss']
})
export class BriindletEventBriindletMediaComponent implements OnInit {
  @Output() stepperActions = new EventEmitter();
  @Input() formGrp: any;
  selectedFiles: any = [];
  showCropper:any = false;
  imageChangedEvent: any = '';
  constructor(
    public appStore: AppStore,
    private appService: AppService
  ) { }

  getImage(event: any) {
    this.imageChangedEvent = event;
    this.showCropper =true;
  }

  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  uploadImage(event: any) {
    this.getBase64(event).then(data => {
      this.selectedFiles.push({base64: data, file: event })
      this.setFormValue();
      this.showCropper = false;
    })
  }

  actionCTA(event : any) {
    this.stepperActions.emit(event);
    this.appService.setCache1(this.formGrp.value)
    // sessionStorage.setItem('briindletCache1', JSON.stringify(this.formGrp.value))
  }

  setFormValue() {
    this.formGrp['controls']['step_13'].patchValue({
      photos: this.selectedFiles
    })
  }

  removeImage(index: number) {
    this.selectedFiles.splice(index,1);
    this.setFormValue();
  }

  ngOnInit(): void {
    if (this.formGrp['value']['step_13']['photos']['length']) {
      this.selectedFiles = this.formGrp['value']['step_13']['photos'];
    }
  }

}
