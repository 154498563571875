import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'src/app/core/services/app.service';

@Component({
  selector: 'app-milestone-card',
  templateUrl: './milestone-card.component.html',
  styleUrls: ['./milestone-card.component.scss']
})
export class MilestoneCardComponent implements OnInit {
  @Input('data') data : any;
  constructor(public appService: AppService) { }

  ngOnInit(): void {
  }

  editMilestonePopupCTA(data: any): void {
    this.appService.togglePopup({
      type: 'addMilestone',
      load: true,
      refreshParent: true,
      overlayClose: true,
      context: {
        data:data
      }
    });
  }
}
