<div class="timeline-label font18 poppins-semibold">{{ title| translate }}</div>
<form [formGroup]="formGrp">

<div class="flex flexWrap mrgntop5">
    <div class="d150">
        <div class="posRelative  width100 d140 img-overlay mrgnauto">
            <div
                class="posAbsolute  posBtm posLeft posRight overlay bdrRadius6px flex flexAlignItemsCenter flexJustifyCenter flexDirectionCol">
                <i class="icns icon-camera white d30"></i>
                <input type="file" id="upload-ministry-leader" (change)="updatePhoto($event,'cover_photo')"
                    class="posAbsolute posTop posBtm posRight posLeft width100 opacity0"
                    accept="image/x-png,image/png,image/jpeg,image/jpg">
                <div class="color-FFFFFF mrgntop5 font16 poppins-regular">
                    {{'upload photo' | translate }}
                </div>
            </div>
            <img src="assets/images/add-pic-layer.png" id="ministries-leader-photo objectFitContain" class="width100 d140 bdrRadius6px">
        </div>
    </div>
    <div class="d150" *ngFor="let item of displayImages; let i = index;">
        <div class="posRelative width100 d140 mrgnauto">
            <img [src]="item.photo_url || item" class="bdrRadius6px d140 objectFitContain">
            <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="removeImage(i);">×</span>
        </div>
    </div>
</div>
<div class="pad10"></div>
<div class="flex flexWrap flexAlignItemsCenter">
    <div class="width70Minus10pxLap">
        <input formControlName="title"  [ngClass]="{ 'bdr-red': formGrp?.controls?.title?.invalid && (formGrp?.controls?.title?.touched || formGrp?.controls?.title?.dirty) }"
            class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px"
            [placeholder]="'title of the activity' | translate" />
    </div>
    <div class="pad10"></div>
    <div class="width30Minus10pxLap">
            <!-- <app-datepicker [formCntrlName]="'date'" [formGrp]="formGrp" [placeholder]="'date'" [iconCss]="'calender-green input-icon'" [value]="formGrp?.value?.date"  class="width100"></app-datepicker> -->
            <input [ngClass]="{ 'bdr-red': formGrp?.controls?.date?.invalid && (formGrp?.controls?.date?.touched || formGrp?.controls?.date?.dirty) }" [owlDateTime]="dt1"  [owlDateTimeTrigger]="dt1" [placeholder]="'date'| translate" formControlName="date"  type="text" readonly="true"
            class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px calender-green input-icon">
            <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
        <!-- <input type="date"  formControlName="date"  [ngClass]="{ 'bdr-red': formGrp?.controls?.date?.invalid && (formGrp?.controls?.date?.touched || formGrp?.controls?.date?.dirty) }"
            class="font16 width100 boxShadow-RGBA00005 pad13 input-icon date-green poppins-regular  bdr-1-EEEEEE bg-white outline0 bdrRadius6px" /> -->
    </div>
    <div class="pad10"></div>
    <div class="width100">
        <textarea rows="3"  formControlName="description" [placeholder]="'description' | translate"  [ngClass]="{ 'bdr-red': formGrp?.controls?.description?.invalid && (formGrp?.controls?.description?.touched || formGrp?.controls?.description?.dirty) }"
            class="font16 resizeNone width100 boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px"></textarea>
    </div>
</div>

<div class="mrgntop10 mrgnbtm10">
    <button type="button" (click)="onSubmit()" [disabled]="formGrp.invalid || isDisable"
        class="btn-get-started minwidth100px btn-user-default bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad0815">{{'add activity' | translate}}</button>
</div>
</form>

<ng-container  *ngIf="activityList?.length">
    <ng-container *ngFor="let activity of activityList">
        <div class="flex posRelative  boxShadow-RGBA00005 bg-white bdrRadius6px bdr-1-EEEEEE mrgnbtm10" >
            <span class="close-popup ripple bg-18878C bdrRadius50p color-FFFFFF posTop5px posRight5px" (click)="removeActivity(activity.id)">×</span>
            <div class="minWidth280px">
                <app-simple-carousel>
                    <ng-container *ngFor="let item of activity.photos; let i = index">
                        <ng-container *carouselItem>
                            <div class="width100 bdrRadius6px posRelative posRelative maxHeight150px">
                                <img src="{{item.photo_url}}" (click)="showImagePopup(activity.photos, i)"
                                    class="width100 height100p bdrRadius6px cursorPtr maxWidth280px minWidth280px maxHeight150px objectFitContain">
                                <div *ngIf="activity?.date"
                                    class="bg-18878CDB bdrRadius4px padleft10 padright10 pad5 font14 color-FFFFFF posAbsolute posLeft5px posBtm5px poppins-regular ">
                                    {{ 'date show' | translate: {date: (activity?.date ? (activity?.date | minimalDate) : '') } }}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </app-simple-carousel>
            </div>
            <div class="pad5"></div>
            <div class="padtop15 padbtm15">
                <div class="timeline-label poppins-bold fon18 ">
                    {{activity?.title}}
                </div>
                <div class="color-484848 poppins-regular font14 wrdBreakAll ">
                    {{activity?.description}}
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>

<app-crop-image-popup (close)="showCropper = false" [cropingRatio]="3/2" (uploadImageEvent)="uploadImage($event)" [imageType]="imageType"
  [imageChangedEvent]="imageChangedEvent" *ngIf="showCropper"></app-crop-image-popup>
<app-action-confirm (close)="confirmCTA($event)" *ngIf="isShowConfirm"></app-action-confirm>