import { Component, OnChanges, SimpleChanges, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ActionModel } from 'src/app/core/models/action.model';
import * as _ from 'lodash';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-general-card',
  templateUrl: './general-card.component.html',
  styleUrls: ['./general-card.component.scss']
})
export class GeneralCardComponent implements OnChanges {
  @Input('actions') actions: ActionModel[] = [];
  linkActions: ActionModel[] = [];
  primaryActions: ActionModel[] = [];
  moreActions: ActionModel[] = [];
  @Input('type') type: string = "";
  @Input('userID') userID : any = "";
  @Input('data') data: any;
  @Input('customClass') customClass: any;
  
  @Input() isClickCard: boolean = true;
  @Input() profilePicClass: string = '';
  userType: string = '';
  @Input() showCheckBox: boolean = true;
  @Output() cardActionCTA = new EventEmitter();
  @ViewChild('actionCmp') actionCmp: ElementRef | any;
  constructor(
    public authService: AuthService,
    private router: Router
  ) {

  }

  cardClickCTA(event ?: any, id ?: any) {
    if (((this.actionCmp && !this.actionCmp.nativeElement.contains(event.target)) || !this.actionCmp)) {
      this.cardActionCTA.emit({'action_text': 'card_navigate', 'id': id || this.data.id});
    }
  }

  /**
   * @description
   * method is used for routing the card
  */

  goToRoute(type: string) {
    this.cardActionCTA.emit({'action_text': 'card_navigate', 'id':  this.data.id});
    if (this.isClickCard) {
      switch (type) {
        case 'groups':
          this.router.navigate(['/',this.userType.toLowerCase()+'s',this.userID,'groups',this.data.id])
          break;

        default:
          break;
      }
    }
  }

  /**
   * @description
   * Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
   * Add '${implements OnChanges}' to the class.
   *
   * @param {SimpleChanges} changes
   * @memberof GeneralPostCardComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      if (!this.userType) {
        this.userType = this.authService.getSessionData().type;
      }
    }
  }

  /**
   * @description
   * method is used for emitting the input action
   * @param event
   * @param id
   */
  inputActionCTA(event: any, id: number) {
    this.cardActionCTA.emit({'event': event.target ? event.target.checked : false, 'id': id || this.data.id});
  }

  /**
   *  @description
   * this method is used for emiting the card action
   *
   * @param {*} action_text
   * @memberof GeneralCardComponent
   */
  cardCTA(action_text ?: any, id ?: number) {
    this.cardActionCTA.emit({'action_text': action_text, 'id': id || this.data.id});
  }

}
