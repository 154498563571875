import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-invite-friend-item',
  templateUrl: './invite-friend-item.component.html',
  styleUrls: ['./invite-friend-item.component.scss']
})
export class InviteFriendItemComponent implements OnInit {
  @Input('user') user: any;
  @Output() userEvent = new EventEmitter();
  constructor() { }

  /**
   * @description
   * change check box value
   *
   * @param {*} event
   * @memberof InviteFriendItemComponent
   */
  changeItem(event: any) {
    this.userEvent.emit({id: this.user.id, event: event.target.checked})
  }

  ngOnInit(): void {
    console.log('this.user', JSON.stringify(this.user))
  }

}
