<div id="add-payment-card-popup" class="bdrRadius6px bg-white pad40 posRelative {{popup?.context?.userType}}">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>
    <ng-container *ngIf="stepperCount==1">
    <form novalidate (ngSubmit)="addPaymentCard()" [formGroup]="stripeTest">
        <input placeholder="{{'name' | translate}}" formControlName="name" type="text" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular 
    bdr-1-EEEEEE bg-white outline0 bdrRadius6px">
        <div class="pad10"></div>
        <div class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular 
    bdr-1-EEEEEE bg-white outline0 bdrRadius6px">
            <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions"></ngx-stripe-card>
        </div>
        <div class="pad10"></div>
        <div class="width100">
            <button type="submit" [disabled]="stripeTest.invalid || isSubmitted" class="btn-user-default mrgnright25 btn-get-started btn-18878C 
        minwidth100px bdr-1-EEEEEE color-18878C ripple cursorPtr 
        font14 poppins-semibold pad1030">
                {{ 'Add Card' | translate}}
            </button>
        </div>
    </form>
    </ng-container>
</div>