import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-form-navigation',
  templateUrl: './form-navigation.component.html',
  styleUrls: ['./form-navigation.component.scss']
})
export class FormNavigationComponent {
  @Output() formClickEvent = new EventEmitter();
  @Input() theme: string = 'user';
  @Input() isShowPrevious: boolean = true;
  @Input() isShowPreview: boolean = true;
  @Input() isShowContinue: boolean = true;
  @Input() isShowExit: boolean = true;
  @Input() isDisableContinue: boolean =false;
  @Input() isDisablePreview: boolean =false;
  @Input() isDisablePrevious: boolean =false;
  constructor() { }

  /**
   * @description
   * this method will emit the button type
   *
   * @param {string} [type]
   * @memberof FormNavigationComponent
   */
  btnCTA(type ?: string ) {
    this.formClickEvent.emit(type);
  }

}
