<div class="width100 flex flexAlignItemsCenter flexJustifyBetween">
    <div class="flex width100 flexAlignItemsCenter overflowAuto scrollbar-width0">
        <div
            class="displayFlex flexNoShrink ripple cursorPtr posRelative flexAlignItemsCenter bdrRadius30px bg-ECF2FF pad5 color-122A4D">
            <div class="d35 displayFlex flexAlignItemsCenter flexJustifyCenter bdrRadius50p bg-0053FF">
                <i class="icns icon-photo d20"></i>
            </div>
            <div class="pad3 desktopVisible"></div>
            <input type="file" class="posAbsolute width100 posRight posTop posBtm posLeft opacity0" accept="image/*"
                (change)="uploadFile($event,'photo' )">
            <span class="font14 poppins-semibold desktopVisible padright15">{{'photos' | translate}}</span>
        </div>
        <div class="pad5"></div>
        <div
            class="displayFlex posRelative flexNoShrink ripple cursorPtr flexAlignItemsCenter bdrRadius30px bg-FFF0F2 pad5 color-122A4D">
            <div class="d35 displayFlex flexAlignItemsCenter flexJustifyCenter bdrRadius50p bg-FF4154">
                <i class="icns icon-video d20"></i>
            </div>
            <div class="pad3 desktopVisible"></div>
            <input type="file" class="posAbsolute width100 posRight posTop posBtm posLeft opacity0"
                accept="video/mp4,video/x-m4v,video/*" (change)="uploadFile($event,'video' )">
            <span class="font14 poppins-semibold desktopVisible padright15">{{'videos' | translate}}</span>
        </div>
        <div class="pad5"></div>
        <div class=" bdrRadius30px bg-FBDDB2  color-122A4D" #emojiIconContainer>
            <div class="displayFlex ripple flexNoShrink pad5  cursorPtr flexAlignItemsCenter" (click)="showEmoji($event)" >
                <div class="d35 displayFlex flexAlignItemsCenter flexJustifyCenter bdrRadius50p bg-F49000">
                    <i class="icns icon-smiley d20"></i>
                </div>
                <div class="pad3 desktopVisible"></div>
                <span class="font14 poppins-semibold desktopVisible padright15">{{'smiley' | translate}}</span>
            </div>
            <emoji-mart [style]="{ 'position': 'absolute', 'bottom': 0, 'right': 0 , 'z-index': '120'}" *ngIf="toggled"
                [showPreview]="false" [enableSearch]="false" (emojiClick)="handleSelection($event)" set="google" native="true"></emoji-mart>
        </div>

        <div class="pad5"></div>
        <div (click)="uploadGifOrTagFriend($event,'gif' )"
            class="displayFlex flexNoShrink ripple cursorPtr flexAlignItemsCenter bdrRadius30px bg-F1DBF7 pad5 color-122A4D">
            <div class="d35 displayFlex flexAlignItemsCenter flexJustifyCenter bdrRadius50p bg-A310C8">
                <i class="icns icon-gif d20"></i>
            </div>
            <div class="pad3 desktopVisible"></div>
            <span class="font14 poppins-semibold desktopVisible padright15">{{'gif' | translate}}</span>
        </div>
        <div class="pad5"></div>
        <div *ngIf="authService.getSessionData()?.type == 'User' && postPermission != 'private'"
            class="displayFlex flexNoShrink ripple cursorPtr flexAlignItemsCenter bdrRadius30px bg-F1DBF7 pad5 color-122A4D"
            (click)="uploadGifOrTagFriend($event,'tag' )">
            <div class="d35 displayFlex flexAlignItemsCenter flexJustifyCenter bdrRadius50p bg-A310C8">
                <i class="icns icon-tag white  d20"></i>
            </div>
            <div class="pad3 desktopVisible"></div>
            <span class="font14 poppins-semibold desktopVisible padright15">{{'tag' | translate}}</span>
        </div>
    </div>
</div>