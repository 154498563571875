import { AuthService } from './../../core/services/auth-service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { SocialNetworkAdvantageListModel } from 'src/app/core/models';
import { socialNetworkSection, featuresYouHaveSection, userCardList, getInTouchList } from '../../core/mock-api/home';
import { AppService } from '../../core/services/app.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
 subscription: Subscription = new Subscription();
  public socialNetworkList: SocialNetworkAdvantageListModel[] | any = socialNetworkSection;
  public featuresList: SocialNetworkAdvantageListModel[] | any = featuresYouHaveSection;
  public cardList: SocialNetworkAdvantageListModel[] | any = userCardList;
  public getInTouchList: SocialNetworkAdvantageListModel[] | any = getInTouchList;
  public form: FormGroup;
  constructor(
    private appService: AppService,
    public fb: FormBuilder
    // private activatedRoute: ActivatedRoute,
    // private authService: AuthService,
    // private router: Router
  ) {
   this.form = fb.group({
     first_name: new FormControl ('', [Validators.required]),
     last_name: new FormControl ('', [Validators.required]),
     email: new FormControl ('', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
     message: new FormControl ('', [Validators.required])
   })
  }

  
  /**
   * @description
   * Method is used for toggling the Choose your account popup 
   *
   * @memberof HomeComponent
   */
  signInCTA() {
    this.appService.togglePopup({
      type: 'chooseAccount',
      load: true,
      refreshParent: false,
      overlayClose: true,
    });
  }

  f(controlName?: string) {
    return this.form.get(controlName || '');
  }

  /**
   * @description
   * method is use for save the get in touch data
   * 
   * @memberof HomeComponent
   */
  onSubmit() {
    this.subscription.add(
      this.appService.getInTouch(this.form.value).subscribe((response: any) => {
        this.appService.toggleToast({
          type: 'success',
          load: true,
          position: 'top-center',
          context: { description: `${response.status}` }
        });
        this.form.reset();  
      })
    )
  }
  /**
   * @description
   * Called after the constructor, initializing input properties, and the first call to ngOnChanges.
   * Add 'implements OnInit' to the class.
   *
   * @memberof HomeComponent
   */
  ngOnInit(): void {

  }

}
