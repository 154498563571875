<div class="{{appStore?.userType}}" [formGroup]="formGrp">
    <div class="timeline-label header txtCenter font23 poppins-medium fontWeight600 color-122A4D mrgnbtm20">
        {{'last date of registration' | translate}}
    </div>
    <div class="body width100" formGroupName="step_7">
        <div class="width100 flex flexResponsive">
            <div class="width100 flex">
                <!-- <app-datepicker [formCntrlName]="'dob'" [formGrp]="form" [placeholder]="'date of birth'" [iconCss]="'calender-blue input-icon'" [value]="form?.value?.dob"  class="width100"></app-datepicker> -->
                <input [owlDateTime]="dt1" [min]="miniDate" [max]="maxDate" [owlDateTimeTrigger]="dt1"  formControlName="registration_last_date" placeholder="{{'date' | translate}}" type="text" readonly="true"
                    class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular input-icon date bdr-1-EEEEEE bg-white outline0 bdrRadius6px calender-{{appStore?.iconClass}} input-icon">
                <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
            </div>
        </div>
        <div class="width100 padtop30 flex">
            <button  (click)="actionCTA('previous')"  class="cancel-btn bdr-1-EEEEEE pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr">
                {{'back' | translate}}
            </button>
            <div class="pad5"></div>
            <button [disabled]="formGrp?.controls?.step_7?.invalid" (click)="actionCTA('continue')"  class="btn-user-default pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr">
                {{'continue' | translate}}
            </button>
        </div>
    </div>
</div>