<div class="posRelative" [ngClass]="{'pointerEventsNone': isPreview, 'mrgnauto bdr-1-EEEEEE boxShadow-RGBA00005 bg-FFFFFF bdrRadius6px pad20': isShowSubmitButton}">
    <!-- <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span> -->
    <div id="membershipTemplate"
        class="custom-scrollbar mrgnauto bdrRadius6px theme-{{popup?.template?.id || popup.field_settings?.template?.id}} bdr-1-EEEEEE boxShadow-RGBA00005">
        <div class="header">&nbsp;</div>
        <div class="mrgnauto content pad20" [formGroup]="form" [ngClass]="{'pointerEventsNone': popup?.ispreview}">
            <div class="logo flex flexAlignItemsCenter">
                    <img [src]="popup?.profile_pic" class="d80 bdrRadius50p" />
                    <div class="pad5"></div>
                    <div class="poppins-medium font18">{{name || popup?.name}}</div>
            </div>
            <div class="pad10"></div>
            <ng-container *ngFor="let field of fields; let i = index;" >
                <label class="padtop20 padbtm10 font18 poppins-semibold">{{field?.field_name}}</label>

                <ng-container *ngIf="field.field_type != 'textarea' && field.field_type != 'date'">
                <input [ngClass]="{ 'bdr-red': form.controls[field?.field_name?.toLowerCase().split(' ').join('')].invalid && (form.controls[field?.field_name?.toLowerCase().split(' ').join('')].touched || form.controls[field?.field_name?.toLowerCase().split(' ').join('')].dirty) }" formControlName="{{field?.field_name?.toLowerCase().split(' ').join('')}}" [ngClass]="field.icon" [placeholder]="field.field_name" [type]="field.field_type"
                        class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px ">
                </ng-container>
                <ng-container *ngIf="field.field_type == 'date'">
                    <input [ngClass]="{ 'bdr-red': form.controls[field?.field_name?.toLowerCase().split(' ').join('')].invalid && (form.controls[field?.field_name?.toLowerCase().split(' ').join('')].touched || form.controls[field?.field_name?.toLowerCase().split(' ').join('')].dirty) }" [owlDateTime]="dt1"  [owlDateTimeTrigger]="dt1" [ngClass]="field.icon" [placeholder]="field.field_name" formControlName="{{field?.field_name?.toLowerCase().split(' ').join('')}}"  type="text" readonly="true"
                        class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px {{field.icon}}">
                    <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                </ng-container>
                <ng-container *ngIf="field.field_type == 'textarea'">
                    <textarea  formControlName="{{field?.field_name?.toLowerCase().split(' ').join('')}}" [placeholder]="field.field_name" rows="3"  [ngClass]="{ 'bdr-red': form.controls[field?.field_name?.toLowerCase().split(' ').join('')].invalid && (form.controls[field?.field_name?.toLowerCase().split(' ').join('')].touched || form.controls[field?.field_name?.toLowerCase().split(' ').join('')].dirty) }"
                        class="font16 width100 resizeNone  boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px"></textarea>
                </ng-container>
            </ng-container>
            <div class="txtCenter color-red padtop20" *ngIf="showError">
                {{showError | translate}}
            </div>
            <div class="flex mrgntop30 padbtm20" *ngIf="popup?.fields?.length != 0 && isShowSubmitButton">
                <button type="button" (click)="onSubmit();" [disabled]="form.invalid"
                    class="btn-get-started minwidth100px bdr-1-EEEEEE color-FFFFFF ripple cursorPtr font14 poppins-regular pad1030">
                    {{'submit' | translate}}
                </button>
            </div>
        </div>
        <div class="footer">
            <div class="txtRight padright50 flex theme3Footer flexAlignItemsCenter flexJustifyEnd">
                <img [src]="popup?.profile_pic" class="d80 bdrRadius50p" />
                <div class="pad5"></div>
                <div class="poppins-medium font18">{{popup?.name}}</div>
            </div>
        </div>
    </div>
</div>