<div class="{{appStore?.userType}}" [formGroup]="formGrp">
    <div class="timeline-label header txtCenter font23 poppins-medium fontWeight600 color-122A4D mrgnbtm20">
        {{'choose donation campaign' | translate}}
    </div>
    <div class="body width100" formGroupName="step_14">
        <div class="width100 flex flexResponsive">
            <div class="width100">
                <select formControlName="cause" class="card-input ripple cursorPtr poppins-regular color-484848">
                    <option value="" class="ripple cursorPtr">{{'choose a donation campaign' | translate }}</option>
                    <option [value]="item.id" *ngFor="let item of causesList">{{item.title}}</option>
                </select>
            </div>
        </div>
        <div class="width100 padtop30 flex">
            <button  (click)="actionCTA('previous')"  class="cancel-btn bdr-1-EEEEEE pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr">
                {{'back' | translate}}
            </button>
            <div class="pad5"></div>
            <button [disabled]="formGrp?.controls?.step_14?.invalid" (click)="actionCTA('continue_to_campaign')"  class="btn-user-default pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr">
                {{'continue' | translate}}
            </button>
        </div>
    </div>
   
</div>