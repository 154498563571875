import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { config } from 'src/environments/environment';
import { forkJoin } from 'rxjs';
import { OrganizationStoreService } from '../store/organization-store.service';
import { AuthService } from '../../core/services/auth-service/auth.service';
import { AppStore } from 'src/app/core/stores/app.store';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(
    private organizationStoreService: OrganizationStoreService,
    private http: HttpClient,
    private authService: AuthService,
    public appStore: AppStore,
  ) { }

  /**
   * @description
   * this method is used for checking is organization is valid for edit
   *
   * @param {*} id
   * @returns
   * @memberof OrganizationService
   */
  getIsValidOrganizationForEdit(id: any) {
    if ((id == this.authService.getSessionData().orgId ||  id == this.authService.getSessionData().briind_id) && this.authService.getSessionData().type == 'Organization') {
      return true;
    }
    return false;
  }


  /**
   * @description
   * this method is used for updating user informations
   *
   * @param {*} [data]
   * @param {number} [id]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  updateOrganizationInfo(data?: any, id?: number) {
    return this.http.patch(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${id}/`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  /**
   * @description
   * this method is used for updating user informations
   *
   * @param {number} [id]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getOrganizationDetail(id?: number) {
    const params : any = {
      organization_id: id
    }
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.organizationDetailURI}`, {params: params})
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for updating organization bio informations
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  updateSocialProfilDetail(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.socialProfileURI}${config.createInfoURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for updating organization bio informations
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  updateOrganizationBasicProfilDetail(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.profileDetailURI}${config.createInfoURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for getting organization bio informations
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getOrganizationBasicProfilDetail(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.profileDetailURI}${config.detailsURI}`, { params: param })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for creating milestone
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  createMilestone(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.milestonesURI}${config.createInfoURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for getting organization milestone list
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getOrganizationMilestoneList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.milestonesURI}${config.milestoneListURI}`, { params: param })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  /**
   * @description
   * this method is used for getting organization services list
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getOrganizationServicesList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.servicesURI}${config.serviceListURI}`, { params: param })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for creating Organization Services
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  createOrganizationService(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.servicesURI}${config.createInfoURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for update Organization Services
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  updateOrganizationServiceOffered(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.servicesURI}${config.updateServicesOfferedURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for get Organization social medias
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getSocialMedias(data?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.socialMediasURI}${config.detailsURI}`, { params: data })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for create Organization social medias
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  createSocialMedias(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.socialMediasURI}${config.createInfoURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for create Organization leaders
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  createLeader(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.leadersURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for get Organization leaders
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getLeaders(data?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.leadersURI}`, { params: data })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  deleteLeaders(id : number) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.leadersURI}${id}`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }



  /**
   * @description
   * this method is used for get Organization invite list
   *
   * @param {*} [params]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getBranchInviteList(params?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.branchesURI}${config.invitesListURI}`, { params: params })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for create Organization invites
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  createBranchInvites(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.branchesURI}${config.inviteURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for create leaders
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  createMinistryLeaders(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  createMinistryActivity(data : any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.ministryActivitiesURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  removeActivity(id : number) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.ministryActivitiesURI}${id}/`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  /**
   * @description
   * this method is used for create leaders
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getUsersList(data?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}`, { params: data })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for create bussiness profile details
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  createbusinessProfile(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.businessProfileURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for create bussiness membership form
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  createmembershipForm(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipFormURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for create bussiness membership form
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getmembershipForm(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipFormURI}`, { params: param })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  /**
   * @description
   * this method is used for create bussiness profile details
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getBusinessProfile(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.businessProfileURI}`, { params: param })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  /**
   * @description
   * this method is used for create Ministry
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  createMinistry(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.ministriesURI}${config.createMinistryURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for list ministry
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  ministryList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.ministriesURI}${config.ministryListURI}`, { params: param })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for list ministry
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getMembershipRolePermission(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.membershipsURI}${config.permissionsURI}`, { params: param })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for create New to Us
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  createNewToUs(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.newToUsURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for list ministry
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getNewToUsDetails(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.newToUsURI}`, { params: param })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  /**
   * @description
   * this method is used for create bank account
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  createBankAccount(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.bankAccountsURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for list ministry
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getBankAccount(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.bankAccountsURI}`, { params: param })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  /**
   * @description
   * this method is used for create causes
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  createCauses(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.causesURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
  * @description
  * this method is used for update causes
  *
  * @param {*} [data]
  * @param {number} [id]
  * @returns this api will return a observalble
  * @memberof OrganizationService
  */
  updateCauses(data?: any, id?: number) {
    return this.http.put(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.causesURI}${id}/`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for causes
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getCauses(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.causesURI}`, { params: param })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


 

  /**
   * @description
   * this method is used for getting the fork join data of the Give Component
   *
   * @param {*} [params]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getGiveForkJoinData(params?: any) {
    return forkJoin([
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.bankAccountsURI}`, { params: params }),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.causesURI}`, { params: params }),
    ]).pipe(map(
      response => this.organizationStoreService.apiResp = response
    ));
  }



  /**
  * @description
  * this method used for getting Videos list
  *
  * @param {*} [param]
  * @returns this api will return a observalble
  * @memberof OrganizationService
  */
  getOrganizationAllVideos(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.videosURI}`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for creating Post for user
   *
   * @param {*} [data]
   * @returns
   * @memberof OrganizationService
   */
  createPost(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.postsURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting user post List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getPosts(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.postsURI}`, { params: param })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for creating Post Like
   *
   * @param {*} [data]
   * @returns
   * @memberof OrganizationService
   */
  likePost(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.likesURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting user Photos
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getOrganizationPhoto(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.photosURI}`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting group list
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getGroupList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.groupsURI}`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method used for getting create Group
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  createGroup(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.groupsURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method used for update the group details
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  updateGroup(data?: any, id?: number) {
    return this.http.patch(`${config.baseApiURL}${config.apiURI}${config.groupsURI}${id}/`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting Group Details
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getGroupDetail(id?: number) {
    return forkJoin([
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.groupsURI}${id}/`),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.groupsURI}${id}/${config.groupTimelineURI}`),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.groupsURI}${id}/${config.invitesListURI}`)])
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method used for invite group members
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  inviteGroupMembers(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.groupsURI}${config.organizationInviteURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting organization List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getOrganizationsList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}`, { params: param })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for search users List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  searchOrganization(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}?search=${param}`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting group list
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getInvitesOrganizationList(id?: number) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.groupsURI}${id}/${config.invitesListURI}`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting organization Post
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getOrganizationPosts(param?: any, id?: number) {
    const orgID: any = {
      organization_id: id
    }
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.organizationTimelineURI}`, {params: {...param, ...orgID}})
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  /**
    * @description
    * This method is used for getting trending organizations for organization
    *
    * @param {*} [param]
    * @returns this api will return a observalble
    * @memberof OrganizationService
    */
  getTrendingOrganizations(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}`, { params: param })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
    * @description
    * This method is used for getting Organizaztion Home Content
    *
    * @param {*} [param]
    * @returns this api will return a observalble
    * @memberof OrganizationService
    */
  getOrganizationHomeContent(param?: any) {
    return forkJoin([
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.postsURI}`, { params: param }),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.organizationsURI}${config.trendingListURI}`, { params: param }),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.briindadURI}${config.timelineURI}`, {params: param}),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.postsURI}${config.mostLikedSharedPostURI}`, { params: param }),
    ]).pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  /**
    * @description
    * This method is used for getting trending organizations for organization
    *
    * @param {number} [id]
    * @returns this api will return a observalble
    * @memberof OrganizationService
    */
  getOrganizationOverview(id: number) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${id}/${config.overviewURI}`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  /**
    * @description
    * This method is used for getting Organizaztion Home Content
    *
    * @param {*} [param]
    * @returns this api will return a observalble
    * @memberof OrganizationService
    */
  getOrganizationTimelineContent(id?: number, params?: any) {
    const param: any = {
      organization_id: id
    }
    return forkJoin([
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.organizationTimelineURI}`, { params: {...params, ...param} }),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.overviewURI}`, {params: param}),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.organizationPhotosURI}`, {params: param})])
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  getOrganizationTimelinePost(id: any,param: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${id}/${config.organizationTimelineURI}`,  {params: param})
    .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting organization Photos
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getOrganizationPhotos(id?: any, param ?: any) {
    const params: any = {
      organization_id: id
    }
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.organizationPhotosURI}`,  {params: {...param,...params}})
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting user Albums
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getOrganizationAlbums(id?: any,  param ?: any) {
    const params: any = {
      organization_id: id
    }
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.organizationAlbumsListURI}`, {params: {...param, ...params}})
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting user Videos
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getOrganizationVideos(id?: any,  param ?: any) {
    const params: any = {
      organization_id: id
    }
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.organizationVideosURI}`, {params: {...param, ...params}})
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting user overview
   *
   * @param {number} [id]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getOrganizationOverView(id: number) {
    const params: any = {
      organization_id: id
    }
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.overviewURI}`, {params: params})
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
  * @description
  * this method used for sharing organization post
  *
  * @param {*} [param]
  * @returns this api will return a observalble
  * @memberof OrganizationService
  */
  shareOrganizationPost(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.sharesURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  /**
   * @description
   * This method is used for getting organization groups
   *
   * @param {number} [id]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getOrganizationGroupsList(id: number) {
    const params: any = {
      organization_id: id
    }
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.groupsURI}`, {params: params})
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting user overview
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  createMembership(data: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipURI}${config.createMembershipURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting user overview
   *
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getPendingMembershipList() {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipURI}${config.pendingListURI}`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for accepting the membership form
   * @param {number} id
   * @param {*} data
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  acceptMembershipForm(id: number, data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipURI}${id}/${config.acceptURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for rejecting the membership form
   * @param {number} id
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  rejectMembershipForm(id: number) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipURI}${id}/${config.rejectURI}`, {})
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  /**
   * @description
   * This method is used for getting application details
   * @param {number} id
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getApplicationDetail(id: number) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipURI}${id}/${config.detailsURI}`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for forwrd membership
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  forwardMembership(data: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipURI}${config.forwardURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting functionality list
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getFunctionalitiesList() {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.functionalitiesURI}`, {})
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for create role
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  createRole(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.rolesURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for create role
   *
   * @param {number} [id]
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  updateRole(id: number, data?: any) {
    return this.http.patch(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.rolesURI}${id}/`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for get role detailss
   *
   * @param {number} [id]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getRoleDetails(id: number) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.rolesURI}${id}/`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting roles list
   *
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getRoleList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.rolesURI}`, { params: param })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for follow the organization
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  followOrganization(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.followersURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description 
   * This method is used for unfollow organization
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
   unfollowOrganization(param?: any) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.followersURI}${config.unfollowURI}`, { params: param })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting followers
   *
   * @returns this api will return a observable
   * @memberof OrganizationService
   */
  getOrganizationFollowersList(params ?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.followersURI}${config.getFollowersURI}`, {params: params})
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
  * @description
  * This method is used for getting Organizaztion shared and tagged post
  *
  * @param {*} [param]
  * @returns this api will return a observalble
  * @memberof OrganizationService
  */
  getOrganizationSharedAndTagged(id?: number, param ?: any) {
    const params : any = {
      organization_id: id
    }
    return forkJoin([
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.organizationTimelineURI}`, {params: {...param,...params}}),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.postTagsURI}`, {params: param})])
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting Most Like Post List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getMostLikedPostList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.postsURI}${config.mostLikedPost}`, { params: param })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
     * @description
     * This method is used for getting Most Shared Post List
     *
     * @param {*} [param]
     * @returns this api will return a observalble
     * @memberof OrganizationService
     */
  getMostSharedPostList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.postsURI}${config.mostSharedPost}`, { params: param }).pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  getHasTagList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.hashTagURI}`, { params: param }).pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  getHasTagData(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.hashTagData}?hash_tag=${param.hash_tag}`).pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting Invited List
   *
   * @param {*} param
   * @returns this api will return a observable
   * @memberof OrganizationService
   */
  getOrganizationMemberInvitedList(param: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipURI}${config.invitedListURI}`, { params: param })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for delete a member from organization
   *
   * @param {number} [id]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  deleteMemberFromOrganization(id: number) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipURI}${id}/${config.deleteURI}`, {})
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for delete a member from organization
   *
   * @param {number} [id]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
   cancelAdvertisement(id: number) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.briindadURI}${id}/${config.cancelURI}`, {})
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for mute a member from organization
   *
   * @param {number} [id]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  muteMemberFromOrganization(id: number) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipURI}${id}/${config.muteURI}`, {})
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for unmute a member from organization
   *
   * @param {number} [id]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  unmuteMemberFromOrganization(id: number) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipURI}${id}/${config.unmuteURI}`, {})
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting organization group List
   *
   * @param {*} param
   * @returns this api will return a observable
   * @memberof OrganizationService
   */
  getOrganizationMembersGroupList(param: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipGroupsURI}`, { params: param })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for create organization group
   *
   * @param {*} param
   * @returns this api will return a observable
   * @memberof OrganizationService
   */
  createOrganizationMembersGroup(param: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipGroupsURI}`, param)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for create organization events
   *
   * @param {*} param
   * @returns this api will return a observable
   * @memberof OrganizationService
   */
  createOrganizationEvents(param: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.eventsURI}`, param)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  getOrgganizationEventMembers(id: number, param ?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.eventsURI}${id}/${config.allMembersURI}`, {params: param})
    .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for create organization events
   *
   * @param {*} param
   * @returns this api will return a observable
   * @memberof OrganizationService
   */
  getOrganizationEventsList(param: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.eventsURI}`, { params: param })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for inivite volunteers to organization events
   *
   * @param {*} param
   * @returns this api will return a observable
   * @memberof OrganizationService
   */
  inviteEventVolunteers(param: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.eventsURI}${config.inviteVolunteersURI}`, param)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for inivite volunteers to organization events
   *
   * @param {*} param
   * @returns this api will return a observable
   * @memberof OrganizationService
   */
  getEventDetail(id: number, param: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.eventsURI}${id}/`, { params: param })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
 * @description
 * This method is used for inivite volunteers to organization events
 *
 * @param {*} param
 * @returns this api will return a observable
 * @memberof OrganizationService
 */
  updateEventDetail(id: number, param: any) {
    return this.http.patch(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.eventsURI}${id}/`, param)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  /**
   * @description
   * This method is used for inivite volunteers to organization events
   *
   * @param {*} param
   * @returns this api will return a observable
   * @memberof OrganizationService
   */
  getEventVolunteerList(id: number, param: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.eventsURI}${id}/${config.eventVolunteersURI}?status=` + param.status)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for inivite volunteers to organization events
   *
   * @param {*} data
   * @returns this api will return a observable
   * @memberof OrganizationService
   */
  cancelVolunteer(data: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.eventsURI}${config.cancelVolunteerURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }



  /**
   * @description
   * this method is used for get Invitation Management Data
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getInvitationManagementData(data?: any) {
    return forkJoin([
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipURI}${config.nonMembersURI}`, { params: data }),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipURI}${config.pendingListOrgURI}`, { params: data }),
    ]).pipe(map(
      response => this.organizationStoreService.apiResp = response
    ));
  }

  /**
   * @description
   * this method is used for create leaders
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getIvitationManagementUsersList(data?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipURI}${config.nonMembersURI}`, { params: data })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  sendOrganizationMemberInvited(data: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipURI}${config.sendInviteURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for rejecting the membership form
   * @param {number} id
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  cancelInviteUser(id: number) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipURI}${id}/${config.cancelInviteURI}`, {})
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting membership List
   *
   * @param {*} param
   * @returns this api will return a observable
   * @memberof OrganizationService
   */
  getMembershipList(param: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipURI}`, { params: param })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting membership List
   *
   * @param {*} param
   * @returns this api will return a observable
   * @memberof OrganizationService
   */
  getInvitationFollowersUserList(param: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipURI}${config.getUserFollowersURI}`, { params: param })
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting membership List
   *
   * @param {number} id
   * @returns this api will return a observable
   * @memberof OrganizationService
   */
  getMembershipDetail(id: number) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipURI}${id}/${config.detailsURI}`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for update membership detail
   *
   * @param {*} data
   * @returns this api will return a observable
   * @memberof OrganizationService
   */
  updateMembershipDetail(data: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipURI}${config.manageURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting group members list
   *
   * @param {number} id
   * @returns this api will return a observable
   * @memberof OrganizationService
   */
  getMembershipGroupMembersList(id: number) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipGroupsURI}${id}/${config.memberListURI}`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
 * @description
 * this method is used for creating Comment/Reply Like
 *
 * @param {*} [data]
 * @returns
 * @memberof OrganizationService
 */
  likeCommentReply(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.likesURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  /**
  * @description
  * this method is used for briindlet pro payment
  *
  * @param {*} [data]
  * @returns
  * @memberof OrganizationService
  */
  briindletProPayment(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.paymentURI}${config.startPaymentURI}`, data)
      .pipe(map(response => this.appStore.apiResp = response));
  }


  /**
   * @description
   * Gets organization promotion price calc
   * @param [param] 
   * @returns  
   * @memberof OrganizationService
   */
  getOrganizationPromotionPriceCalc(param?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.promotionsURI}${config.priceCalcURI}`, param)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  /**
  * @description
  * this method is used to create org promotion
  *
  * @param {*} [data]
  * @returns
  * @memberof OrganizationService
  */
  createOrganizationPromotion(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.promotionsURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  /**
   * TODO: comment getOrganizationPromotion
   * @description Gets organization promotion
   * @returns  observable
   * @memberof OrganizationService
   */
  getOrganizationPromotion() {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.promotionsURI}`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  /**
   * TODO: comment getUserSettings
   * @description Gets user settings
   * @param id 
   * @returns  
   * @memberof OrganizationService
   */
  getUserSettings(id: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${id}/`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  /**
   * TODO: comment updateOrganizationGeneralSettings
   * @description Updates organization general settings
   * @param data 
   * @returns  observable
   * @memberof OrganizationService
   */
  updateOrganizationGeneralSettings(data: any) {
    return this.http.put(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.settingsURI}${config.generalSettingsURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  /**
   * TODO: comment getUserPrivacySettings
   * @description Gets organization privacy settings
   * @returns  
   * @memberof OrganizationService
   */
  getOrganizationPrivacySettings() {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.settingsURI}`)
      .pipe(map(response => this.appStore.apiResp = response));
  }


  /**
   * TODO: comment updateOrganizationPrivacySettings
   * @description Updates organization privacy settings
   * @param data 
   * @returns  
   * @memberof OrganizationService
   */
  updateOrganizationPrivacySettings(data: any) {
    return this.http.put(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.settingsURI}${config.updateUserSettingsURI}`, data)
      .pipe(map(response => this.appStore.apiResp = response));
  }


  /**
   * TODO: comment getOrganizationMenuCustomization
   * @description Gets organization menu customization
   * @param id 
   * @returns  
   * @memberof OrganizationService
   */
  getOrganizationMenuCustomization(id: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.menuCustomizationURI}?organization_id=${id}`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  /**
   * TODO: comment updateOrganizationMenuCustomization
   * @description Updates organization menu customization
   * @param data 
   * @returns  
   * @memberof OrganizationService
   */
  updateOrganizationMenuCustomization(data: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.createMenuCustomizationURI}`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
* @description
* this method is used for remove photo
*
* @param {*} [data]
* @returns
* @memberof UserService
*/
  removePhoto(data?: any) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.organizationPhotosURI}${data}/`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for remove post
   *
   * @param {*} [data]
   * @returns
   * @memberof UserService
   */
  removePost(data?: any) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.postsURI}${data}/`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  removeSharedPost(data?: any) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.sharesURI}${data}/`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  removeTaggedPost(data?: any) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.postTagsURI}${data}/`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  /**
   * TODO: comment deleteSavedCard
   * @description Deletes saved card
   * @param [id] 
   * @returns  
   * @memberof OrganizationService
   */
  deleteSavedCard(id?: any) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.settingsURI}${id}/${config.deleteSavedCardURI}`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description this method is used for getting photo details
   * @param id 
   * @returns  
   * @memberof OrganizationService
   */
  getPhotoDetails(id: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.organizationPhotosURI}${id}/`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * TODO: comment updateMilestone
   * @description Updates milestone
   * @param [data] 
   * @returns  
   * @memberof OrganizationService
   */
  updateMilestone(data?: any, id?: number) {
    return this.http.put(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.milestonesURI}${id}/`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * TODO: comment deleteSavedCard
   * @description Deletes give cause
   * @param [id] 
   * @returns  
   * @memberof OrganizationService
   */
  deleteGiveCause(id?: any) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.causesURI}${id}/`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for get post details
   *
   * @param {number} [id]
   * @returns this api will return a observalble
   * @memberof OrganizationService
   */
  getPostDetails(id: number) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.postsURI}${id}/`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
  * @description
  * this method is used for update causes
  *
  * @param {*} [data]
  * @param {number} [id]
  * @returns this api will return a observalble
  * @memberof OrganizationService
  */
  updateOrgnizationPost(id?: number, data?: any) {
    return this.http.put(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.postsURI}${id}/`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * TODO: comment updateMinistry
   * @description Updates ministry
   * @param [data] 
   * @returns  
   */
  updateMinistry(data?: any, id?: number) {
    return this.http.patch(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.ministriesURI}${id}/`, data)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
* @description
* this method is used for remove video
*
* @param {*} [data]
* @returns
* @memberof UserService
*/
  removeVideo(id?: any) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.organizationVideosURI}${id}/`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }


  /**
   * TODO: comment cancelPromotion
   * @description Cancels promotion
   * @param id 
   * @returns  
   * @memberof OrganizationService
   */
  cancelPromotion(id: number) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.promotionsURI}${id}/${config.cancelPromotionURI}`,{})
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }
}
