<div *ngIf="type == 'friend'"  class="bdr-1-EEEEEE posRelative {{userType.toLowerCase()}} boxShadow-RGBA00005 cursorPtr flexResponsive bg-FFFFFF bdrRadius6px pad10 flex flexAlignItemsCenter">
    <div class="flex flexAlignItemsCenter flex2 width100" (click)="navigateToProfile(data?.user_type || data?.targeted_user?.user_type || 'User')" >
        <div class="cursorPtr">
            <app-profile-thumbnail [image]="data?.targeted_user?.photo|| data?.send_user?.photo || data?.photo "></app-profile-thumbnail>
        </div>
        <div class="padleft10 cursorPtr">
            <div class="poppins-medium font14 timeline-label">{{ data?.targeted_user?.name|| data?.send_user?.name || data?.name }} 
            </div>
        </div>
    </div>
    <app-card-actions [actions]="actions" (cardActionCTA)="cardActions($event, data?.connection_detail?.id || data?.id, data?.notification_id)"></app-card-actions>
</div>
<div *ngIf="type == 'homePageFriend'" class="posRelative width100 {{userType.toLowerCase()}} cursorPtr flexResponsive width100  flex flexAlignItemsCenter">
    <div class="flex flexAlignItemsCenter flex2 width100" [routerLink]="['/users',data?.targeted_user?.briind_id || data?.send_user?.briind_id || data?.briind_id || data?.id,'timeline']">
        <div class="cursorPtr">
            <app-profile-thumbnail [image]="data?.targeted_user?.photo|| data?.send_user?.photo || data?.photo "></app-profile-thumbnail>
        </div>
        <div class="padleft10 cursorPtr">
            <div class="poppins-medium font14 timeline-label">{{ data?.targeted_user?.name.substring(0, 9) || data?.send_user?.name.substring(0, 9) || data?.name.substring(0, 9) }} 
                <span *ngIf="(data?.targeted_user?.name?.length || data?.send_user?.name?.length || data?.name?.length) > 9">.</span></div>
        </div>
    </div>
    <app-card-actions [actions]="actions" (cardActionCTA)="cardActions($event, data?.connection_detail?.id || data?.id)"></app-card-actions>
</div>