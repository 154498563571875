import { AppService } from 'src/app/core/services/app.service';
import { QbService } from './../../../core/services/qb.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrganizationService } from '../../../organizations/service/organization.service';
import { OrganizationStoreService } from '../../../organizations/store/organization-store.service';
import { UserService } from '../../../users/services/user.service';
import { UserStoreService } from '../../../users/store/user-store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { AppStore } from 'src/app/core/stores/app.store';

@Component({
  selector: 'app-set-user-details',
  templateUrl: './set-user-details.component.html',
  styleUrls: ['./set-user-details.component.scss']
})
export class SetUserDetailsComponent implements OnInit {
  subscription: Subscription = new Subscription();
  id: any = 0;
  @Output('inIt') inIt =  new EventEmitter();
  authData: any;
  userData: any;
  constructor(
    private organizationService: OrganizationService,
    private organizationStoreService: OrganizationStoreService,
    private userService: UserService,
    private userStoreService: UserStoreService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private qbService: QbService,
    public appStore: AppStore,
    private appService: AppService
  ) {
    this.authData = this.authService.getSessionData();
    this.getActivatedRouterParams();
  }

  getCurrenLoggedInUserDetails() {
    if (this.authData) {
      if (this.authData?.type?.toLowerCase() == 'organization') {
        this.getCurrentOrganizationDetails();
      } else {
        this.getCurrenUserDetails();
      }
       this.getPrivacySettings();
    }
    
  }

  setConnection(response: any) {
    // console.log(response)
    this.qbService.login({'userLogin': response.briind_id, 'userName': response.name});
  }

  checkTheModule() {
    if (this.router.url.includes('organizations')) {
      if((this.id && (this.id != this.authService.getSessionData().briind_id))) {
        this.getOtherOrganizationDetails();
      } else {
        this.organizationStoreService.profileDetail = this.organizationStoreService.organizationDetails;
        // this.inIt.emit(this.organizationStoreService.organizationDetails)
      }
    } else {
      if (this.id && (this.id != this.authService.getSessionData().briind_id)) {
        this.getOtherUserDetails();
      } else {
        this.userStoreService.profileDetail = this.userStoreService.userDetails;
        // this.inIt.emit(this.userStoreService.userDetails)
      }
    }
  }

  /**
   * @description
   * this method is used for getting user information calling in ng onit function
   * 
   * @memberof SharedUserDetailComponent
   */
  getOtherUserDetails() {
    this.subscription.add(
      this.userService.getUserInfo(this.id).subscribe((response: any) => {
        this.userStoreService.getProfileDetails();
        this.inIt.emit('')
      })
    );
  }

  getCurrenUserDetails() {
    this.subscription.add(
      this.userService.getUserInfo(this.authService.getSessionData().briind_id).subscribe((response: any) => {
        this.userStoreService.getUserDetails();
        this.setConnection(response)
        this.inIt.emit(response);
        this.checkTheModule();
      })
    );
  }


  /**
   * @description
   * this method is used for getting other organization information calling in ng onit function
   * 
   * @memberof SharedUserDetailComponent
   */
  getOtherOrganizationDetails() {
    this.subscription.add(
      this.organizationService.getOrganizationDetail(this.id).subscribe(response => {
        this.organizationStoreService.getOrganizationProfileDetails();
        this.inIt.emit('');
      })
    )
  }

  getCurrentOrganizationDetails() {
    this.subscription.add(
      this.organizationService.getOrganizationDetail(this.authService.getSessionData().briind_id).subscribe(response => {
        this.organizationStoreService.getOrganizationDetails();
        this.setConnection(response)
        this.inIt.emit(response);
        // this.userData = response;
        this.checkTheModule();
      })
    )
  }

    /**
   * TODO: comment getPrivacySettings
   * @description Gets privacy settings
   * @memberof SetUserDetailsComponent
   */
     getPrivacySettings(): void {
      if (this.authData.type.toLowerCase() == "user") {
        this.subscription.add(
          this.userService.getUserPrivacySettings().subscribe((response: any) => {
            this.appStore.setUserPrivacySettings();
          })
        );
      } else {
        this.subscription.add(
          this.organizationService.getOrganizationPrivacySettings().subscribe((response: any) => {
            this.appStore.setUserPrivacySettings();
          })
        );
      }
    }

   /**
   * @description 
   * get router params
   *
   * @memberof SharedOrganizationDetailComponent
   */
  getActivatedRouterParams() {
    if (this.activatedRoute.pathFromRoot[2]) {
      this.subscription.add(
        this.activatedRoute.params.subscribe(param => {
          this.id = param['id'];
        }
        )
      );
    }
  }

  ngOnInit(): void {
    this.getCurrenLoggedInUserDetails();
    this.appService.onlineUserStatusUpdate().subscribe(response => {
    })
  }

}
