<section id="elasticSearch" class="{{userType.toLowerCase()}} height100vh bg-white overflowAuto custom-scrollbar">
    <div class="mrgnauto posRelative width70 pad15">
        <div class="flex flexJustifyEnd width100">
            <div (click)="closePopup();" class=" d40 cursorPtr ripple bg-common  bdrRadius6px font32 poppins-regular color-white flex flexAlignItemsCenter flexJustifyCenter">
                ×
            </div>
        </div>
        
        <div class="poppins-semibold timeline-label font28 color-122A4D padbtm30 txtCenter padtop30">
            {{'search here' | translate}}
        </div>

        <div class="padbtm20">
            <app-search-input [isLimitWidth]="false" [icon]="'searchIcon icnsright'" (search)="searchAll($event)"></app-search-input>
        </div>
        <div class="bdr-1-EEEEEE boxShadow-RGBA00005 bdrRadius6px bg-FFFFFF pad25 padright15">
            <div *ngIf="searchInput && isTyped">
                <div class="poppins-semibold font22 timeline-label ">
                    {{'search results for' | translate: {search: searchInput} }}</div>
                <div class="bdr-1-EEEEEE mrgnbtm20 mrgntop20"></div>
            </div>
            
            <div class="width100" *ngIf="tabs && tabs?.length != 0">
                <div class="flex ">
                    <a class="links cursorPtr padbtm15 color-122A4D poppins-medium font14 mrgnright30 posRelative"
                        [ngClass]="{'tab-active': searchType == item?.id}" (click)="changeTab(item?.id)"
                        *ngFor="let item of tabs">{{item?.name | translate}}</a>
                </div>
            </div>
            <ng-container *ngIf="(appStore.elasticSearchData.gifs?.length || appStore.elasticSearchData.users?.length  || appStore.elasticSearchData.posts?.length || appStore.elasticSearchData.videos?.length || appStore.elasticSearchData.photos?.length || appStore.elasticSearchData.organizations?.length || appStore.elasticSearchData.users?.length || appStore.elasticSearchData.groups?.length) && searchInput">
                <div [ngSwitch]="true" class="minHeight65vh">
                    <div *ngSwitchCase="appStore.elasticSearchData.photos?.length != 0 && (searchType == 'all' || searchType == 'photos')">
                        <div class="timeline-label font25 poppins-medium mrgntop25">{{'photos' | translate}}</div>
                        <div class="flex mrgntop10 flexWrap">
                            <div *ngFor="let item of appStore.elasticSearchData.photos; let i = index;" class="posRelative d150 padright10 padbtm10 bdrRadius4px" (click)="viewPhotos(item?.get_data?.id, i, item)">
                                <img [src]="item?.get_data?.photo_url || item?.photo_url" class="cursorPtr width100 bdrRadius4px height100p ripple " />
                            </div>
                        </div>
                        <div class="bdr-1-E5ECF1 mrgnbtm10 mrgntop10 mrgnright10" *ngIf="searchType == 'all'"></div>
                    </div>
                    <div *ngSwitchCase="appStore.elasticSearchData.videos?.length != 0 && (searchType == 'all' || searchType == 'videos')">
                        <div class="timeline-label font25 poppins-medium mrgntop15">{{'videos' | translate}}</div>
                        <div class="flex mrgntop10 flexWrap">
                            <div *ngFor="let item of appStore.elasticSearchData?.videos; let i = index;" (click)="viewVideos(item?.get_data?.id || item?.id, item)" class="posRelative d150 padright10 padbtm10 bdrRadius4px cursorPtr">
                                <app-video-preview-card [media]="item?.get_data?.video_url || item?.video_url"></app-video-preview-card>
                            </div>
                        </div>
                        <div class="bdr-1-E5ECF1 mrgnbtm10 mrgntop10 mrgnright10" *ngIf="searchType == 'all'"></div>
                    </div>
                    <div *ngSwitchCase="appStore.elasticSearchData.gifs?.length != 0 && (searchType == 'all' || searchType == 'gifs')" >
                        <div class="timeline-label font25 poppins-medium mrgntop15">{{'gifs' | translate}}</div>
                        <div class="flex mrgntop10 flexWrap">
                            <div *ngFor="let item of appStore.elasticSearchData?.gifs; let i = index;" class="posRelative d150 padright10 padbtm10 bdrRadius4px" (click)="viewPhotos(item?.get_data?.id, i, item)">
                                <img [src]="item?.get_data?.gif_url" class="cursorPtr width100 bdrRadius4px height100p ripple " />
                            </div>
                        </div>
                        <div class="bdr-1-E5ECF1 mrgnbtm10 mrgntop10 mrgnright10" *ngIf="searchType == 'all'"></div>
                    </div>
                    <div *ngSwitchCase="(appStore.elasticSearchData.posts?.length != 0 && (searchType == 'all' || searchType == 'posts'))">
                        <div class="timeline-label font25 poppins-medium mrgntop15">{{'posts' | translate}}</div>
                        <div class="flex mrgntop10 flexWrap">
                            <div class="width50 padbtm10 padright10" *ngFor="let item of appStore.elasticSearchData.posts" >
                                <app-elastic-search-post-item [data]="item"></app-elastic-search-post-item>
                            </div>
                        </div>
                        <div class="bdr-1-E5ECF1 mrgnbtm10 mrgntop10 mrgnright10" *ngIf="searchType == 'all'"></div>
                    </div>
                    <div *ngSwitchCase="(appStore.elasticSearchData.users?.length != 0 && (searchType == 'all' || searchType == 'user'))">
                        <div class="timeline-label font25 poppins-medium mrgntop15">{{'users' | translate}}</div>
                        <div class="flex mrgntop10 flexWrap">
                            <div class="width50 padbtm10 padright10" *ngFor="let item of appStore.elasticSearchData.users" >
                                <app-general-card [type]="'SearchUserCard'" (cardActionCTA)="cardActionCTA($event, 'user', item)" [data]="item || item.get_data" ></app-general-card>
                            </div>
                        </div>
                        <div class="bdr-1-E5ECF1 mrgnbtm10 mrgntop10 mrgnright10" *ngIf="searchType == 'all'"></div>
                    </div>
                    <div *ngSwitchCase="(appStore.elasticSearchData.organizations?.length != 0 && (searchType == 'all' || searchType == 'organization'))">
                        <div class="timeline-label font25 poppins-medium mrgntop15">{{'organizations' | translate}}</div>
                        <div class="flex mrgntop10 flexWrap">
                            <div class="width50 padbtm10 padright10" *ngFor="let item of appStore.elasticSearchData.organizations" >
                                <app-general-card [type]="'SearchOrganizationCard'" (cardActionCTA)="cardActionCTA($event, 'organization', item)" [data]="item.get_data || item " ></app-general-card>
                            </div>
                        </div>
                        <div class="bdr-1-E5ECF1 mrgnbtm10 mrgntop10 mrgnright10" *ngIf="searchType == 'all'"></div>
                    </div>
                    <div *ngSwitchCase="(appStore.elasticSearchData.groups?.length != 0 && (searchType == 'all' || searchType == 'groups'))">
                        <div class="timeline-label font25 poppins-medium mrgntop15">{{'groups' | translate}}</div>
                        <div class="flex mrgntop10 flexWrap">
                            <div class="width50 padbtm10 padright10" *ngFor="let item of appStore.elasticSearchData.groups" >
                                <app-general-card [type]="'Groups'" [isClickCard]="false" (cardActionCTA)="cardActionCTA($event, 'groups')" [data]="item.get_data || item" ></app-general-card>
                            </div>
                        </div>
                        <div class="bdr-1-E5ECF1 mrgnbtm10 mrgntop10 mrgnright10" *ngIf="searchType == 'all'"></div>
                    </div>
                   
                </div>
            </ng-container>
            
            

            <div class="minHeight65vh flex flexDirectionCol flexJustifyCenter flexAlignItemsCenter" *ngIf="!appStore.elasticSearchData.gifs?.length && !appStore.elasticSearchData.videos?.length && !appStore.elasticSearchData.posts?.length && !appStore.elasticSearchData.photos?.length  && !appStore.elasticSearchData.gifs?.length && !appStore.elasticSearchData.users?.length&& !appStore.elasticSearchData.videos?.length && !appStore.elasticSearchData.organizations?.length && !appStore.elasticSearchData.users?.length && !appStore.elasticSearchData.groups?.length">
                <app-empty-state ></app-empty-state>
            </div>
        </div>
        
    </div>
</section>