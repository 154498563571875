<div class="timeline-label font18 poppins-semibold" *ngIf="title">{{title | translate }}</div>
<div class="mrgntop10">
    <div class="flex flexAlignItemsCenter">
        <input [(ngModel)]="briindId" (ngModelChange)="errorStatus = ''"
            class="font16 width50 boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px"
            [placeholder]="'enter your briind id or email' | translate" />
        <div class="pad5"></div>
        <input [(ngModel)]="designation"
            class="font16 width50 boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px"
            [placeholder]="'designation' | translate" />
        <div class="pad5"></div>
        <button (click)="checkBriindId()"
            class="btn-get-started minwidth100px btn-user-default bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad0815"
            [disabled]="!briindId || !designation">{{'go' | translate}}</button>
    </div>
    <strong class="color-red poppins-regular font13 mrgnbtm0" *ngIf="briindId && errorStatus">
        {{errorStatus | translate}}
    </strong>
    <div class="flex flexAlignItemsCenter flexJustifyCenter mrgntop10 mrgnbtm10">
        <div class="fxWidth20 bdr-1-EEEEEE"></div>
        <div class="mrgnleft10 mrgnright10 timeline-label poppins-medium font16">{{'or' | translate}}</div>
        <div class="fxWidth20 bdr-1-EEEEEE"></div>
    </div>

    <form [formGroup]="addMinistryLeaderForm" [ngClass]="{'pointerEventsNone': briindId}">
        <div class="flex flexAligItemsCenter flexResponsive">
            <div class="d140">
                <app-upload-photo [placeholderImage]="imageSrc" (imageEvent)="uploadImage($event)"></app-upload-photo>
                <!-- <div class="posRelative width100 d140 img-overlay">
                    <div
                        class="posAbsolute  posBtm posLeft posRight overlay bdrRadius6px flex flexAlignItemsCenter flexJustifyCenter flexDirectionCol">
                        <i class="icns icon-camera white d30"></i>
                        <input type="file" id="upload-ministry-leader" (change)="uploadImage($event)"
                            class="posAbsolute posTop posBtm posRight posLeft width100 opacity0"
                            accept="image/x-png,image/png,image/jpeg,image/jpg">
                        <div class="color-FFFFFF mrgntop5 font16 poppins-regular">
                            {{'upload photo' | translate }}
                        </div>
                    </div>
                    <img [src]="imageSrc" id="ministries-leader-photo"
                        class="width100 d140 bdrRadius6px">
                </div> -->
            </div>
            <div class="pad10"></div>
            <div class="width100">
                <div class="flex flexAlignItemsCenter flexWrap width100">
                    <div class="width50Minus10pxLap">
                        <input formControlName="first_name"
                            [ngClass]="{ 'bdr-red': addMinistryLeaderForm?.controls?.first_name?.invalid && (addMinistryLeaderForm?.controls?.first_name?.touched || addMinistryLeaderForm?.controls?.first_name?.dirty) }"
                            class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px"
                            [placeholder]="'enter name' | translate" />
                    </div>
                    <div class="pad10"></div>
                    <div class="width50Minus10pxLap">
                        <input type="text" formControlName="contact_no"
                            [ngClass]="{ 'bdr-red':  addMinistryLeaderForm?.controls?.contact_no?.invalid && ( addMinistryLeaderForm?.controls?.contact_no?.touched ||  addMinistryLeaderForm?.controls?.contact_no?.dirty) }"
                            class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px"
                            [placeholder]="'contactno' | translate" />
                    </div>
                </div>
                <div class="pad10"></div>
                <div class="flex flexAlignItemsCenter flexWrap width100">
                    <div class="width50Minus10pxLap">
                        <input type="email" formControlName="email"
                            [ngClass]="{ 'bdr-red':  addMinistryLeaderForm?.controls?.email?.invalid && ( addMinistryLeaderForm?.controls?.email?.touched ||  addMinistryLeaderForm?.controls?.email?.dirty) }"
                            class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px"
                            [placeholder]="'enter email' | translate" />
                    </div>
                    <div class="pad10"></div>
                    <div class="width50Minus10pxLap">
                        <input formControlName="designation"
                            [ngClass]="{ 'bdr-red': addMinistryLeaderForm?.controls?.designation?.invalid && ( addMinistryLeaderForm?.controls?.designation?.touched ||  addMinistryLeaderForm?.controls?.designation?.dirty) }"
                            class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px"
                            [placeholder]="'designation' | translate" />
                    </div>
                </div>
            </div>
            
        </div>
        <strong class="color-red poppins-regular font13 mrgnbtm0 txtTransformCaps" *ngIf="!briindId && errorStatus && errorStatus?.email && errorStatus.email?.length != 0">
            {{errorStatus?.email[0] | translate}}
        </strong>
        <strong class="color-red poppins-regular font13 mrgnbtm0 txtTransformCaps"
            *ngIf="!briindId && errorStatus && ! errorStatus?.email">
            {{errorStatus | translate}}
        </strong>
        <div class="mrgntop10">
            <button type="button" (click)="formSubmit()" [disabled]="addMinistryLeaderForm.invalid || isDisabled "
                class="btn-get-started minwidth100px btn-user-default bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad0815">{{'save' | translate}}</button>
        </div>
    </form>
</div>
<div class="mrgntop flex flexAlignItemsCenter flexWrap" *ngIf="userList?.length != 0">
    <div class="width50 pad5" *ngFor="let item of userList">
        <app-leader-card [data]="item" [leaderType]="'ministry'" [showClose]="true" (removeItem)="removeItem($event)"></app-leader-card>
    </div>
</div>

