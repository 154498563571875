import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/core/services/app.service';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { OrganizationService } from 'src/app/organizations/service/organization.service';
import { OrganizationStoreService } from 'src/app/organizations/store/organization-store.service';
import { UserService } from 'src/app/users/services/user.service';
import { UserStoreService } from 'src/app/users/store/user-store.service';

@Component({
  selector: 'app-post-comment-list',
  templateUrl: './post-comment-list.component.html',
  styleUrls: ['./post-comment-list.component.scss']
})
export class PostCommentListComponent implements OnInit {
  @Input() comments: any[] = [];
  more: boolean = false;
  @Input() public post: any;
  @Input() public media: any;
  @Input() public mediaType: any;
  @Input() user: any;
  @Input() theme: string = 'blue';
  @Input() profilePic: any;
  showReply: any[] = [];
  subscription: Subscription = new Subscription();
  constructor(private userService: UserService,
    private authService: AuthService,
    private organizationService: OrganizationService,public userStoreService:UserStoreService, 
    public organizationStoreService:OrganizationStoreService) { }

  ngOnInit(): void {
  }

  /**
 * @description
 * this method is used for updating comments in posts
 *
 * @param {*} event
 * @memberof PostCommentListComponent
 */
  updatePosts(event: any) {
    if (event.result) {
      for (let index = 0; index < this.comments.length; index++) {
        if (this.comments[index].id == event.result.id) {
          this.comments[index] = event.result;
          break;
        }
      }
    }
  }

  /**
* @description
* this method is used for like a comment
*
* @param {*} event
* @memberof PostCommentListComponent
*/
  likeComment(comment: any) {
    //if (!comment.liked) {
      const param = {
        "user_id": this.authService?.getSessionData()?.orgId,
        "item_id": comment.id,
        "item_type": 'Comment'
      }
      switch (this.authService.getSessionData()?.type?.toLowerCase()) {
        case 'user':
          this.userLikeComment(param, comment);
          break;
        case 'organization':
          this.organizationLikeComment(param, comment);
          break;
        default:
          break;
      }
    //}
  }

  /**
   * @description
   * this method is used for call the organization like api
   *
   * @param {*} [param]
   * @memberof PostCommentListComponent
   */
  organizationLikeComment(param: any, comment: any) {
    this.subscription.add(
      this.organizationService.likeCommentReply(param).subscribe((response: any) => {
        if (comment.liked) {
          this.comments[this.comments.indexOf(comment)].liked = false;
          this.comments[this.comments.indexOf(comment)].likes_count -= 1;
        } else {
          this.comments[this.comments.indexOf(comment)].liked = true;
          this.comments[this.comments.indexOf(comment)].likes_count += 1;
        }
        // this.comments[this.comments.indexOf(comment)].liked = true;
        // this.comments[this.comments.indexOf(comment)].likes_count += 1;
        //this.post.liked = true;
        //this.post.likes_count += 1;
      })
    )
  }

  /**
   * @description
   * this method is used for call the user like api
   *
   * @param {*} param
   * @memberof PostCommentListComponent
   */
  userLikeComment(param: any, comment: any) {
    this.subscription.add(
      this.userService.likeCommentReply(param).subscribe((response: any) => {

        if (comment.liked) {
          this.comments[this.comments.indexOf(comment)].liked = false;
          this.comments[this.comments.indexOf(comment)].likes_count -= 1;
        } else {
          this.comments[this.comments.indexOf(comment)].liked = true;
          this.comments[this.comments.indexOf(comment)].likes_count += 1;
        }
        //this.post.liked = true;
        //this.post.likes_count += 1;
      })
    )
  }


  /**
   * TODO: comment checkIfAbusiveComment
   * @description Checks if abusive comment
   * @param comment 
   * @returns  
   * @memberof PostCommentComponent
   */
  checkIfAbusiveComment(comment: any) {
    if (this.authService.getSessionData()?.type?.toLowerCase() == 'user') {
      if (this.userStoreService.privacySettingsModel.hide_comment_words == true) {
        if (comment.abusive == true) { return false; }
        else { return true; }
      }
      else { return true; }
    }
    else {
      if (this.organizationStoreService.privacySettingsModel.hide_comment_words == true) {
        if (comment.abusive == true) { return false; }
        else { return true; }
      }
      else { return true; }
    }
  }
}
