import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { Subscription } from 'rxjs';
import { PopupModel } from 'src/app/core/models';
import { AppService } from 'src/app/core/services/app.service';
import { AppStore } from 'src/app/core/stores/app.store';

@Component({
  selector: 'app-add-payment-card-popup',
  templateUrl: './add-payment-card-popup.component.html',
  styleUrls: ['./add-payment-card-popup.component.scss']
})
export class AddPaymentCardPopupComponent implements OnInit {
  @Input() userType: string = '';
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  stepperCount:number = 1;
  @ViewChild(StripeCardComponent)
  public card!: StripeCardComponent;
  subscription: Subscription = new Subscription();
  stripeTest!: FormGroup;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };
  isSubmitted:boolean = false;
  constructor(private fb: FormBuilder, public stripeService: StripeService,
    private router: Router, public appService: AppService, 
    public appStore:AppStore,public route:ActivatedRoute) { }

  ngOnInit(): void {
    //let popup  = this.popup;
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]]
    });
  }

  /**
   * @description
   * this method is used for pay add card
   *
   * @memberof AddPaymentCardPopupComponent
   */
  addPaymentCard(): void {
    if (this.stripeTest.valid) {
      this.isSubmitted =true;
      const name = this.stripeTest?.get('name')?.value;
      this.stripeService.stripe
        .createToken(this.card.element, { name })
        .subscribe((result) => {
          if (result.token) {

            let data = { card_token: result.token.id, name : name};
      // let data = { card_token: 'tok_1IgoWJFfJiy2k2XhaPdVXWlG' ,name : 'Davinder'};
            let apiType = this.userType == 'user' ? 'users' : 'organizations';
           
            this.subscription.add(
              this.appService.addPaymentCard(data,apiType).subscribe((response: any) => {
                if (response.success) {
                  this.appService.toggleToast({
                    type: 'success',
                    load: true,
                    position: 'bottom-left',
                    context: { description: response.success }
                  });
                  this.close.emit('icon');
                  this.router.navigate(["payment"], { relativeTo: this.route });

                } else if (response.error) {
                  this.appService.toggleToast({
                    type: 'failure',
                    load: true,
                    position: 'bottom-left',
                    context: { description: response.error }
                  });
                  this.close.emit('icon');
                  this.router.navigate(["payment"], { relativeTo: this.route });
                }
                this.isSubmitted = false;
              })
            );

          } else if (result.error) {
            // Error creating the token
            console.log(result.error.message);
          }
        });
    }
  }

    /**
  * @description
  * Click on close button this method will call
  *
  * @memberof AddPaymentCardPopupComponent
  */
     closePopup() {
      this.close.emit('icon');
    }

  /**
  * @description
  * Called once, before the instance is destroyed.
  * Add 'implements OnDestroy' to the class.
  *
  * @memberof AddPaymentCardPopupComponent
  */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
