<div class="{{appStore?.userType}} minWidth700px" >
    <div class="timeline-label header txtCenter font23 poppins-medium fontWeight600 color-122A4D mrgnbtm20">
        {{ (formGrp.value?.step_1?.is_event == "True" ? 'choose your event template' : 'choose your template') | translate}}
    </div>
    <div class="body width100 maxHeight520px overflowAuto custom-scrollbar posRelative">
        <div class="flex flexAlignItemsCenter flexJustifyStart flexWrap">
            <div class="pad10 cursorPtr bdrRadius4px" *ngFor="let template of briindTemplate"
                (click)="selectTemplate(template)">
                <div class="posRelative flex bdrRadius4px bdr-1-EEEEEE pad10">
                    <img [src]="template?.photo" class="minHeight180px maxHeight180px maxWidth150px bdrRadius4px objectFitContain">
                    <div class="bg-00007 posAbsolute posLeft bdrRadius4px width100 height100p posTop"
                        *ngIf="template.isSelected">
                        <span
                            class="d30 bdrRadius50p  posAbsolute posTop5px posRight5px bg-common displayFlex flexAlignItemsCenter flexJustifyCenter">
                            <i class="icns icon-tick-white d15"></i>
                        </span>
                    </div>
                </div>
                <div class="font15 timeline-label poppins-medium txtTransformCaps txtCenter padtop5 padbtm5">
                    {{template?.name}}
                </div>
                <!-- <div class="font16 txtTransformCaps txtCenter padbtm5 poppins-semibold">
                    {{'$'+template?.price}}
                </div> -->
            </div>
        </div>
        <div class="width100 padtop30 flex">
            <!-- <button type="button" [disabled]="formGrp?.controls?.step_2?.invalid" (click)="btnCTA('customize')"
            class="btn-user-default pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr">
                {{ 'customize' | translate}}
            </button> -->
            <div class="pad5"></div>
            <button type="button" [disabled]="formGrp?.controls?.step_2?.invalid"  (click)="btnCTA('continue')" class="btn-user-default pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr">
                {{ 'save and continue' | translate }}
            </button>
        </div>
    </div>
</div>