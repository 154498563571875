<div class="pad20 bdrRadius6px bdr-1-EEEEEE bg-white posRelative {{popup?.context?.userType}} posRelative"
    id="addLeaderPopup">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>


    <ng-container >
        <div class="header timeline-label font20 lineHeight40px poppins-semibold txtCenter posRelative">
            {{'invite' | translate }}
        </div>
        <p class="color-02457A font16 poppins-regular txtCenter">
            {{ 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.' }}
        </p>
        <div class="mrgntop20 ">
            <div class="create-post-box bdrRadius10px pad15 boxShadow-RGBA00005 bdr-1-EEEEEE bg-white">
                <div class="flex">
                   <form [formGroup]="form"  class="width100">
                 

                    <div class="width100 mrgnbtm20">
                        <input placeholder="{{'enter email id invite' | translate}}" formControlName="email" type="text" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular 
                  bdr-1-EEEEEE bg-white outline0 bdrRadius6px">
                    </div>
        
                    <div class="flex mrgnbtm20">
                        <div class="width100">
                            <textarea formControlName="description"
                                class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px minHeight150px"
                                placeholder="{{'invite message' | translate}}" rows="2"></textarea>
                        </div>
                    </div>

                   </form>
                    
                   
                </div>
               
            </div>
            <div class="pad5"></div>
            
            <div class="flex mrgntop20">
                
                <button [disabled]="form.invalid || formSubmitting" (click)="onSubmit()"
                    class="btn-get-started flex flexJustifyCenter posRelative padleft20 padright20 minWidth120px padtop10 padbtm10 btn-user-default font15 ripple poppins-medium cursorPtr"
                    >{{'invite' | translate}}
                    <div class="posAbsolute posRight5px" >
                        <!-- <app-loader [widthClass]="'d20'"></app-loader> -->
                    </div>
                </button>
            </div>
        </div>
    </ng-container>
</div>