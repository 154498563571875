import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-profile-preview',
  templateUrl: './profile-preview.component.html',
  styleUrls: ['./profile-preview.component.scss']
})
export class ProfilePreviewComponent implements OnInit {
  @Input('data') data: any;
  @Input('showEditIcon') showEditIcon: boolean = false;
  @Input('settings') settings :any;
  constructor() { }

  ngOnInit(): void {
    
  }


  /**
   * TODO: comment showUserInfo
   * @description Shows user info
   * @param settings 
   * @param showValue 
   * @returns  
   * @memberof ProfilePreviewComponent
   */
  showUserInfo(settings: any,showValue:any) {
    if (settings) {
      if (settings.is_own_profile) {
        return true;
      }
      else {
        if (settings.settings[showValue] == "everyone") {
          return true;
        }
        else if (settings.settings[showValue] == "friends" && !settings.is_friend) {
          return false;
        }
        else if (settings.settings[showValue] == "private") {
          return false;
        }
        else {
          return true;
        }
      }
    } else {
      return true;
    }
  }

}
