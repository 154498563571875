<div class="bdr-1-EEEEEE  boxShadow-RGBA00005 profile-noti posAbsolute posRight bg-white bdrRadius6px">
    <div class="">
        <div class="flex flexAlignItemsCenter padtop10 padleft20 padright20 padbtm10 ripple">
            <span class="timeline-label font16  poppins-medium">{{'recent notification'| translate}}</span>
        </div>
        <div class="bdr-1-EEEEEE"></div>
        <div *ngFor="let item of notificationList;index as uind"
            class="pad10 ripple" (click)="navigateToPost(item)">
            <div class="flex flexJustifyStart  flexAlignItemsCenter">
                <app-profile-thumbnail [image]="item?.created_by?.profile_pic" [imageClass]="'d40'"></app-profile-thumbnail>
                <div>
                    <div [innerHTML]="item.description"></div>
                    <p class="mrgn0 padleft15 color-919191 font10 poppins-light">{{item.created_at | dateAgo}}</p>
                </div>
                <!-- <div class="pad3"></div> -->
                <!-- <div class="flex">
                    <div class="dot-noti"></div>
                    <div class="dot-noti"></div>
                    <div class="dot-noti"></div>
                </div> -->
            </div>
        </div>
        <div class="bg-F2F6F8 pad10">
            <a class="txtCenter timeline-label font14 fontWeight500 poppins-medium txtUnderline "
                [routerLink]="['/notification']">
                {{ 'see all' | translate }}
            </a>
        </div>

    </div>

</div>