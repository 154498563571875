<section id="create-rbac" class="bdrRadius6px bg-white pad20 posRelative custom-scrollbar {{parent}}">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>
    <div class="width100">
        <div class="poppins-semibold font22 timeline-label padbtm20 txtCenter ">
            {{(popup.context.formValue ? 'update briind rba' :  'create briind rba')  | translate}}
        </div>
        <form [formGroup]="form" (submit)="onSubmit();" class="bdr-1-EEEEEE bdrRadius10px bg-white boxShadow-RGBA00005 organization pad15 padright5">
            <div class="width100 padright10">
                <input formControlName="name" class="card-input poppins-regular" [placeholder]="'role' | translate">
            </div>
            <div class="pad10"></div>
            <div class="width100 padright10">
                <textarea formControlName="description" class="card-input resizeNone poppins-regular" [placeholder]="'description' | translate"></textarea>
            </div>
            <div class="timeline-label font18 poppins-medium mrgntop10 mrgnbtm10 padright10">
                {{'access functionalities' | translate}}
            </div>
            <div class="width100 flex flexWrap">
                <label [for]="item.id" class="cursorPtr padright10 padbtm10" *ngFor="let item of popup?.context?.functionalityList; ">
                    <div class="custom-checkbox check-18878C flex flexAlignItemsCenter">
                        <input [id]="item.id" [checked]="item.isChecked" type="checkbox" class="flexNoShrink" (change)="onChange(item.id, $event)" >
                        <span class="mrgnleft5 poppins-regular timeline-label font14">{{item.display_name}}</span>
                    </div>
                </label>
            </div>
            <div class="mrgntop10">
                <button [disabled]="form.invalid || form.value.functionality?.length == 0" class="btn-get-started minWidth90px mrgnright10 bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad1030 btn-user-default">
                    {{(popup.context.formValue ? 'update briind rba' :  'create briind rba') | translate}}
                </button>
            </div>
        </form>
    </div>
</section>