<div id="suggested-friends" class="bdrRadius6px bg-white pad20 posRelative overflowHidden"
    [ngClass]="popup?.context?.userType.toLowerCase()">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup('false');">×</span>
    <div id="suggested-friends-popup" class="overflowHidden">
        <div class="width100 mrgnauto">
            <div class="poppins-semibold font22 timeline-label padbtm10 txtCenter ">
                {{ popup?.context?.title | translate }}
            </div>
        </div>
        <div class="width100 mrgnauto bg-FFFFFF">
            <div class="width50 mrgnauto txtCenter">
                {{ popup?.context?.data?.message | translate }}
            </div>
            <div class="maxHeight300px custom-scrollbar mrgntop15  overflowAuto">
                <div class="width70 mrgnauto mrgnbtm15 txtCenter">
                    <button type="button" (click)="closePopup('false');" class="btn-get-started cancel-btn minWidth100px bdr-1-EEEEEE color-18878C ripple cursorPtr font14 poppins-regular pad1030 mrgnright15">
                        {{'no' | translate}}
                      </button>
                      <button type="button" (click)="onDelete(popup?.context?.data)" class="btn-get-started btn-user-default minWidth100px bdr-1-EEEEEE color-18878C ripple cursorPtr font14 poppins-regular pad1030">
                        {{'yes '| translate}} 
                      </button>
                </div>
            </div>
            <div class="pad10"></div>
        </div>
    </div>
</div>