<div id="choose-acount-popup" class="bdrRadius6px bg-white pad40 posRelative">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup('false');">×</span>
    <div class="header txtCenter font23 poppins-medium fontWeight600 color-122A4D mrgnbtm40">
        {{'choose your briind account' | translate}}
    </div>
    <div class="body">
        <div class="width100 flex flexResponsive">
            <div [ngClass]="{'active': slectedAccountType == 'users'}" (click)="selectAccountCTA('users');" class="width50 bdr-1-EEEEEE ripple account-one bg-white account-box bdrRadius10px">
                <div class="flex flexJustifyEnd padtop10 padbtm5 padright10 padleft10">
                    <div class="tick bg-DEDEDE">
                        <i class="icns icon-tick account"></i>
                    </div>
                </div>
                <div [innerHTML]="'sign in a personal account' | translate" class="width100 txtCenter color-122A4D  account-title font18 poppins-medium fontWeight600 lineHeight27px">
                </div>
            </div>
            <div class="pad15"></div>
            <div [ngClass]="{'active': slectedAccountType == 'organizations'}" (click)="selectAccountCTA('organizations');" class="width50 ripple bdr-1-EEEEEE account-two  bg-white account-box bdrRadius10px">
                <div class="flex flexJustifyEnd  padtop10 padbtm5 padright10 padleft10">
                    <div class="tick bg-DEDEDE">
                        <i class="icns icon-tick account"></i>
                    </div>
                </div>
                <div [innerHTML]="'sign in an organization account' | translate" class="width100 txtCenter color-122A4D  account-title font18 poppins-medium fontWeight600 lineHeight27px">
                </div>
            </div>
        </div>
        <div class="width100 padtop40 txtCenter">
            <button class="btn-get-started ripple font16 poppins-medium cursorPtr" (click)="continuteCTA()" [ngClass]="{'btn-02457A': slectedAccountType == 'users', 'btn-18878C': slectedAccountType == 'organizations'}">
                {{'continue' | translate}}
            </button>
        </div>
    </div>
</div>