import { Component, OnInit, Output, ViewChild, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-video-preview-card',
  templateUrl: './video-preview-card.component.html',
  styleUrls: ['./video-preview-card.component.scss']
})
export class VideoPreviewCardComponent implements OnInit {
  @ViewChild("timeline", {static: true}) timeline: HTMLElement | any;
  @ViewChild("video", {static: true}) video: HTMLVideoElement | any;
  @Input('media') media: any;
  @Output() previewClicked = new EventEmitter;
  // @Output('removeVideo') removeVideo = new EventEmitter();

  constructor() { }

  /**
   * @description
   * preview clicked method
   *
   * @memberof VideoPreviewCardComponent
   */
  preview() {
    this.previewClicked.emit('clicked');
  }

  // remove(id: number) {
  //   this.removeVideo.emit(id);
  // }

  /**
   * @description
   * load video method
   *
   * @memberof VideoPreviewCardComponent
   */
  loadTime() {
    var canvas = document.createElement('canvas') as HTMLCanvasElement;
    let ctx: any = canvas.getContext('2d');
    canvas.style.width = "100%";
    canvas.style.height = "100%";
    ctx.drawImage(this.video.nativeElement, 0, 0, this.video.nativeElement.videoWidth, this.video.nativeElement.videoHeight);
    this.timeline.nativeElement.appendChild(canvas);
  }
  ngOnInit(): void {
  }

}
