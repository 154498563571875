<div class="{{appStore?.userType}} section860">
    <div class="timeline-label header txtCenter font23 poppins-medium fontWeight600 color-122A4D">
        {{'your template preview' | translate}}
    </div>

    <div class="flex flexJustifyBetween mrgnbtm15">
        <div class="flex">
            <button (click)="actionCTA('previous')" class="cancel-btn bdr-1-EEEEEE pad1020 btn-get-started ripple font16 poppins-medium cursorPtr">{{'back' | translate}}</button>
            <div class="pad5"></div>
            <button type="button" (click)="actionCTA('customize')" class="btn-user-default mrgnright25 btn-get-started btn-18878C 
        minwidth100px bdr-1-EEEEEE color-18878C ripple cursorPtr 
        font14 poppins-semibold pad1030">
            {{ 'customize' | translate }}
        </button>
        </div>
        
        <div class="d30 cursorPtr bdrRadius50p ripple flex flexAlignItemsCenter flexJustifyCenter"
            (click)="actionCTA('edit')">
            <i class="icns icon-edit {{appStore.iconClass}} d20"></i>
        </div>
    </div>

    <!-- start banner  -->
    <div class="body width100 maxHeight60vh overflowAuto custom-scrollbar posRelative">
        <span [ngSwitch]="data.template_name?.toLowerCase().split(' ').join('')" class="pointerEventsNone">
            <span *ngSwitchCase="'defaulttemplate'">
                <app-default-template [isPreview]="false"  [customizeUI]="customizeUI" [isPreview]="true" [data]="data">
                </app-default-template>
            </span>
            <span *ngSwitchCase="'template3'">
                <app-briindlet-pro-template-three [isPreview]="false"  [customizeUI]="customizeUI" [data]="data"></app-briindlet-pro-template-three>
            </span>
            <span *ngSwitchCase="'template2'">
                <app-briind-pro-template-two [isPreview]="false" [customizeUI]="customizeUI" [data]="data"></app-briind-pro-template-two>
            </span>
            <span *ngSwitchCase="'template1'">
                <app-briindlet-pro-template-one [isPreview]="false" [customizeUI]="customizeUI" [data]="data"></app-briindlet-pro-template-one>
            </span>
        </span>
    </div>
    <div class="timeline-label width100 bg-F2F6F8 pad15 txtCenter font18 
          poppins-semibold ">
        {{ 'total payment' | translate }} {{'amount in number' | translate:{amount: data?.template_amount || 0} }}
    </div>
    <div class="width100 padtop30 flex">
        <button (click)="actionCTA('proceed_to_pay')"  class="btn-user-default pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr">
            {{'proceed to pay' | translate}}
        </button>
    </div>
</div>