import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';

@Component({
  selector: 'app-briindlet-preview',
  templateUrl: './briindlet-preview.component.html',
  styleUrls: ['./briindlet-preview.component.scss']
})
export class BriindletPreviewComponent implements OnInit {
  @Output('closePopup') closePopup = new EventEmitter();
  @Input() userType: string = '';
  @Input() perviewType: string = '';
  @Input() data: any;
  @Input() photo: any;
  @Input() venue: any;
  constructor(
    public router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    console.log(this.photo);
  }

  redirectToWebsite(url:any){
    //window.location.href = url;
    window.open( 
      url, "_blank");
  }

  // onDonate() {
  //   this.router.navigate([`/organizations/${this.authService.getSessionData().orgId}/profile/bussiness/give`])
  //   this.closePopup.emit();
  // }
}
