import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { OrganizationService } from 'src/app/organizations/service/organization.service';
import { OrganizationStoreService } from 'src/app/organizations/store/organization-store.service';
import { UserService } from 'src/app/users/services/user.service';
import { UserStoreService } from 'src/app/users/store/user-store.service';

@Component({
  selector: 'app-post-profile-section-card',
  templateUrl: './post-profile-section-card.component.html',
  styleUrls: ['./post-profile-section-card.component.scss']
})
export class PostProfileSectionCardComponent implements OnInit {
  subscription: Subscription = new Subscription();
  @Input('post') post: any;
  @Output('removePost') removePost = new EventEmitter();
  id: number = 0;
  constructor(
    public authService: AuthService,
    private userService: UserService,
    public userStoreService: UserStoreService,
    public organizationStoreService: OrganizationStoreService,
    private organizationService: OrganizationService,
  ) { 
    this.id= this.authService.getSessionData()?.orgId;
  }

  /**
   * @description
   * this method is used for delete an album
   *
   * @param {*} [event]
   * @memberof PostProfileSectionCardComponent
   * 
   */
  remove(id?: number) {
    this.removePost.emit(id);
  }

  ngOnInit(): void {
  }

}
