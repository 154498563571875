<div class="bdrRadius6px boxShadow-RGBA00005 mrgntop10 mrgnbtm10 bdr-1-EEEEEE bg-white pad15 flex posRelative flexAlignItemsCenter width100">
    <div [routerLink]="'/organizations/' + data?.briind_id + '/timeline'">
        <span class="blue-img-shadow padtop8 padbtm3 padleft10 displayFlex bdrRadius50p posRelative">
            <img class="d55 bdrRadius50p brdr4Green" [src]="data?.profile_pic" />
        </span>
    </div>
    <div class="pad5"></div>
    <div class="width100Minus15MD mrgn000auto width100 mrgntop10">
        <div class="poppins-semibold flex flexJustifyBetween flexAlignItemsCenter font16 color-18878C lineHeight16px xs-center txtTransformCaps">
            {{data?.name}}
        </div>
        <div class="flex flexWrap flexResponsive">
            <div class="poppins-regular xs-center lineHeight14px font14 color-8E8E8E padbtm5 flex  mrgntop10 width100">
                <ng-container *ngIf="data?.address?.door_no">
                    {{ data?.address?.door_no}},
                </ng-container>
                <ng-container *ngIf="data?.address?.street">
                    {{ data?.address?.street}},
                </ng-container>
                <ng-container *ngIf="data?.address?.city">
                    {{ data?.address?.city}},
                </ng-container>
            </div>
            <div class="poppins-regular xs-center lineHeight14px font14 color-8E8E8E padbtm5 flex  mrgntop10 width100">
                <ng-container *ngIf="data?.address?.state">
                    {{ data?.address?.state}},
                </ng-container>
                <ng-container *ngIf="data?.address?.country">
                    {{ data?.address?.country}},
                </ng-container>
                <ng-container *ngIf="data?.address?.zip_code">
                    {{ data?.address?.zip_code}}
                </ng-container>
            </div>
            <div *ngIf="data?.address?.phone_number" class="poppins-regular lineHeight14px font14 color-8E8E8E padbtm5 flex flexJustifyCenter flexAlignItemsCenter mrgntop10">
                {{data?.address?.phone_number}}
            </div>
        </div>
    </div>
    <div class=" posAbsolute posRight15px posTop15px">
        <app-status-chip *ngIf="data?.invite_status == 'Pending'" [status]="data?.invite_status == 'Pending' ? 'Invited' : ''"></app-status-chip>
    </div>
</div>