export const socialNetworkSection: any = [
  {
    title: "drive with a purpose",
    description: "briind believes purpose with profit period briind Social life is designed for member based organizations to create impacts by outreaching and do social networking among people with the same passions go with briind to engage and impact",
    icon: 'icon-drive-purpose'
  }, {
    title: "integrated reports", 
    description: "briind is capable to generate integrated and individual reports of every business processes and social interactions that happens in a member based organization as social media and business process software are integrated into a single application briinds reports and insights will help the leaders to take learned decisions",
    icon: 'icon-integraated-reports'
  }, {
    title: "total solution",
    description: "briind is designed as a platform for developing meaningful connections in social media and simplify business functions of a member based organization Member based businesses and organizations leverage from using briind a single platform for socializing with members and managing business",
    icon: 'icon-total-solution'
  }, {
    title: "next level social media",
    description: "briind is not just a social media it is family friendly and socially pleasant briinds intention is to enhance our social life with meaningful communications and to create an impact in our life",
    icon: 'icon-next-level-social-media'
  }, {
    title: "enhance member engagement",
    description: "members are the most valued asset of all member based organizations briind provides a novel way to effectively engage and meaningfully communicate with members",
    icon: 'icon-enhance-member-management'
  }, {
    title: "simplify business functions",
    description: "briind is the answer of the social enterprise organizations quest to simplify their business functions in one single application you will enjoy using briinds different applications for your business needs",
    icon: 'icon-simplify-business-functions'
  }
]


export const featuresYouHaveSection: any = [
  {
    title: "social media integrated with business processes",
    description: "We, the promoters of Briind, created this application because of our long-standing struggles to manage all the disconnected applications we use in our member-based organizations. The goal was to create a single platform to manage different needs of a member-based organization so that the people with same passion can easily interact. Thus, Briind was borne. Briind is scalable, predictable, secure, and customizable.",
    icon: 'icon-social-media-integrated-with-bussiness-purpose'
  },{
    title: "briindlet and pro briindlet",
    description: "Briindlet and Pro-Briindlet are tools for promoting events and projects to members and other social media. Organizations and individuals use these dynamic tools to create impact in their communities. Briindlet Reports will help organizations to make informed decisions about the needs of their members.",
    icon: 'icon-briindlet-and-pro-briindlet'
  },{
    title: "in built communication tools",
    description: "briinds social media platform is designed for member based organization networking and lead generation therefore briind enables people and organizations to connect with others and simplifies communication briind can manage all kinds of communications including messaging calls emails and texting",
    icon: 'icon-in-built-communication-tools'
  }
]

export const userCardList: any = [
  {
    title: "why briind",
    description: "To connect and simplify is our mission.Briind gives user convenience and comfortability in creating and managing their social media and business functions.Briind is able to simplify operation, marketing and accessibility.",
    icon: 'icon-why-briind',
    actionText: "know more",
    link:"/users/auth/signup"
  },{
    title: "briind user",
    description: "Download Briind and start using Briind today! By using Briind, it is easy to manage your memberships in organizations and churches.Get this  powerful tool and start creating impact in your community. Briind user account is free!",
    icon: 'icon-briind-user',
    actionText: "sign up",
    link:"/users/auth/signup"
  },{
    title: "briind partner",
    description: "All member based organizations can open a Briind partner account.By creating a partner account organizations can establish their organizational profile.Member based organization includes for profit and nonprofit ones.",
    icon: 'icon-partner',
    actionText: "sign up",
    link:"/organizations/auth/signup"
  }
]

export const getInTouchList: any = [
  {
    title: "discover services",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an ",
    icon: 'icon-discover white'
  },{
    title: "network",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an ",
    icon: 'icon-network white',
  }
]

