import { Router } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { OrganizationService } from '../../../../organizations/service/organization.service';
import { Location } from '@angular/common';
import { AppService } from '../../../../core/services/app.service';

@Component({
  selector: 'app-add-role-to-member-popup',
  templateUrl: './add-role-to-member-popup.component.html',
  styleUrls: ['./add-role-to-member-popup.component.scss']
})
export class AddRoleToMemberPopupComponent implements OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  parent: string = '';
  form: FormGroup = new FormGroup({});
  btnDisable: boolean = false;
  private subscription: Subscription = new Subscription();
  constructor(
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    private appService: AppService,
    private router: Router
  ) {
    this.form = fb.group({
      role: new FormControl('', Validators.required)
    })
  }

   /**
   * @description
   * application accept method
   *
   * @memberof AddRoleToMemberPopupComponent
   */
  acceptApplication() {
    this.btnDisable = true;
    if (this.popup.context.applicationID) {
      this.subscription.add(
        this.organizationService.acceptMembershipForm(this.popup.context.applicationID, this.form.value).subscribe((response: any) => {
          // if (response.chat_id) {
          //   let toUpdateParams = {
          //     push_all: { occupants_ids: [response.chat_id] }
          //   };
          //   this.dialogService.updateDialog( chat._id, toUpdateParams).then(response => {
          //     console.log(response);
          //   }).catch(error => {
        
          //   })
          // }
          this.btnDisable = false;
          this.toggleToast();
        })
      )
    } else {
      this.subscription.add(
        this.organizationService.sendOrganizationMemberInvited({role_id: this.form.value.role, user_ids: this.popup.context.userID}).subscribe(response => {
          this.btnDisable = false;
          this.toggleToast();
          if (this.router.url.includes('membership')) {
            this.closePopup();
          } else {
            location.reload();
          }
        })
      )

    }

  }

  /**
   * @description
   * methodis used for toggle Toast
   */
  toggleToast() {
    this.appService.toggleToast({
      type: 'success',
      load: true,
      position: 'bottom-left',
      context: { description: `Successfully updated` }
    });
    this.closePopup();
  };

  /**
   * @description
   * this method is used for closing the popup
   *
   * @memberof AddRoleToMemberPopupComponent
   */
  closePopup() {
		this.close.emit('icon');
  }



  ngOnInit() {

  }

}
