import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { config } from 'src/environments/environment';
import { BriindletPerformanceStoreService } from '../store/briindlet-performance-store.service';

@Injectable({
  providedIn: 'root'
})
export class BriindletPerformanceService {

  constructor(
    public http: HttpClient,
    public briindletPerformanceStoreService:BriindletPerformanceStoreService
  ) { }


  /**
   * TODO: comment getBriindletAnalytics
   * @description Gets briindlet analytics
   * @param [id] 
   * @returns  
   * @memberof BriindletPerformanceService
   */
  getBriindletAnalytics(id?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.analyticsURI}${id}/${config.briindletAnalyticsURI}`)
      .pipe(map(response => this.briindletPerformanceStoreService.apiResp = response));
  }

   /**
   * @description
   * this method is used for causes
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof BriindletPerformanceService
   */
    getCauseDetail(id?: any) {
      const params: any = {
        id: id
      }
      return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.causesURI}`, { params: params })
        .pipe(map(response => this.briindletPerformanceStoreService.apiResp = response));
    }


  /**
   * TODO: comment saveBriindletViews
   * @description Saves briindlet views
   * @param data 
   * @returns  
   * @memberof BriindletPerformanceService
   */
  saveBriindletViews(data: any){
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.pageViewsURI}`,data)
    .pipe(map(response => this.briindletPerformanceStoreService.apiResp = response));
  }

  /**
   * TODO: comment saveBriindletClick
   * @description Saves briindlet click
   * @param data 
   * @returns  
   * @memberof BriindletPerformanceService
   */
  saveBriindletClick(data: any){
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.clickURI}`,data)
    .pipe(map(response => this.briindletPerformanceStoreService.apiResp = response));
  }


  /**
   * TODO: comment getUserOrganizationAnalytics
   * @description Gets user organization analytics
   * @returns  
   * @memberof BriindletPerformanceService
   */
  getUserOrganizationAnalytics(userType: string) {
    let params = { filter: 'this_year' };
    if(userType == "organization"){
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.analyticsURI}${config.getOrgAnalyticsURI}`, { params: params })
      .pipe(map(response => this.briindletPerformanceStoreService.apiResp = response));
    }else{
      return this.http.get(`${config.baseApiURL}${config.apiURI}${config.analyticsURI}${config.getUserAnalyticsURI}`, { params: params })
      .pipe(map(response => this.briindletPerformanceStoreService.apiResp = response));
    }
  }

  

}
