import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from 'rxjs/operators';
// import { Observable, forkJoin, Subscription, throwError, Subject } from 'rxjs';
import { AppStore } from 'src/app/core/stores/app.store';
import { config } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { UserStoreService } from '../store/user-store.service';
import { forkJoin } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    public http: HttpClient,
    public appStore: AppStore,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private userStoreService: UserStoreService
  ) { }


  /**
   * @description
   * this method is call when click on save and continue personal information profile
   *
   * @param {*} [data]
   * @returns - this api will return a observalble
   * @memberof UserService
   */
  createPersonalInfo(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.personalInformationsURI}${config.createInfoURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for updating user informations
   *
   * @param {*} [data]
   * @param {number} [id]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  updateUserInfo(data?: any, id?: number) {
    return this.http.patch(`${config.baseApiURL}${config.apiURI}${config.usersURI}${id}/`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for getting user informations
   *
   * @param {number} [id]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getUserInfo(id?: number) {
    const params: any = {
      "user_id": id 
    }
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.userDetailURI}`, { params: params })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for getting user informations
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  updateContactInfo(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.contactInformationURI}${config.createInfoURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for creating  user HighSchool
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  createUserHighSchool(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.highSchoolsURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }



  /**
   * @description
   * this method is used for getting user contact information
   * 
   * @param {*} param
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getUserContactInformation(param: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.contactInformationURI}${config.detailsURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));

  }

  /**
   * @description
   * this method is used for getting High school List
   * 
   * @param {*} param
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getHighSchoolList(param: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.highSchoolsURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  };

  /**
   * @description
   * this method is used for getting datas of work and education page
   * To make a fork join on all the APIs for a page
   * @returns this api will return a array of observalble
   * @memberof UserService
   */
  getWorkAndEduactionForkJoin(param: any) {
    return forkJoin([
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.highSchoolsURI}?user_id=${param.user_id}`),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.universitiesURI}?user_id=${param.user_id}`),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.worksURI}?user_id=${param.user_id}`),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.skillsURI}?user_id=${param.user_id}`),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.interestsURI}?user_id=${param.user_id}`)])
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for getting place component data
   * To make a fork join on all the APIs for a page
   *
   * @param {*} param
   * @returns this api will return a observable
   * @memberof UserService
   */
  getUserPlacedLivedIn(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.placesLivedURI}${config.detailsURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for getting High school List
   * 
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getUserHighSchools() {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.highSchoolsURI}`)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for deleting User High school
   * 
   * @param {*} param
   * @returns this api will return a observalble
   * @memberof UserService
   */
  deleteUserHighSchool(param?: any) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.highSchoolsURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  };

  /**
   * @description
   * this method is used for deleting User High school
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  deleteUserUniversity(param?: any) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.universitiesURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method used for getting univesity list
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getUniversityList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.universitiesURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method used for getting univesity list
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
   getDegreeList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.degreeURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method used for getting univesity list
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
   getMajorList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.majorURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }


  /**
   * @description
   * This method is used for Create University
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  createUserUniversity(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.universitiesURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting Company List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
  */
  getCompanyList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.worksURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for Create user Works
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  createUserWork(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.worksURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }


  /**
   * @description
   * this method is used for deleting User Work
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  deleteUserWork(param?: any) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.worksURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting skill List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
  */
  getSkillList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.skillsURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for Create user Skills
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  createUserSkill(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.skillsURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for deleting User Skill
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  deleteUserSkill(param?: any) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.skillsURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting Interest List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
  */
  getInterestList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.interestsURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for Create user Interest
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  createUserInterest(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.interestsURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for deleting User Interest
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  deleteUserInterest(param?: any) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.interestsURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }


  /**
   * @description
   * This method is used for getting City List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getCityList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.citiesURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }


  /**
   * @description
   * This method is used for Create user City
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  createUserCity(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.citiesURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }


  /**
   * @description
   * this method is used for deleting User City
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  deleteUserCity(param?: any) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.citiesURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }



  /**
   * @description
   * this method is used for creating place lived infos
   *
   * @param {*} [data]
   * @returns
   * @memberof UserService
   */
  createPlaceLivedInfo(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.placesLivedURI}${config.createInfoURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }


  /**
   * @description
   * This method is used for getting Movie List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getMoviesList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.moviesURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting Movie List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
   createMovie(param?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.moviesURI}`, param)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }


  /**
   * @description
   * This method is used for getting Sports List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getSportsList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.sportsURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting Sports List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
   createSports(param?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.sportsURI}`, param)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting Music List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getMusicList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.musicsURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting Music List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
   createMusic(param?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.musicsURI}`, param)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting Religious List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getReligiousList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.religiousViewsURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for create Religious
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  createReligious(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.religiousViewsURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for create political view
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  createPloiticalView(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.politicalViewsURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }


  /**
   * @description
   * This method is used for getting Political List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getPoliticalList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.politicalViewsURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for creating Genereral Interests
   *
   * @param {*} [data]
   * @returns
   * @memberof UserService
   */
  createGeneralInterests(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.generalInterestsURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting Interest List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getInterestPage(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.interestListURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting Group Details
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getGroupDetail(id?: number) {
    return forkJoin([
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.groupsURI}${id}/`),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.groupsURI}${id}/${config.groupTimelineURI}`),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.groupsURI}${id}/${config.invitesListURI}`)
    ]).pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting user Photos
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getUserPhoto(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.photosURI}`)
    .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method used for getting Group Category List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getGroupCategoryList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.groupCategoryURI}`)
    .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
  * @description
  * this method used for getting Group list
  *
  * @param {*} [param]
  * @returns this api will return a observalble
  * @memberof UserService
  */
  getGroupList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.groupsURI}`)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method used for getting create Group
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  createGroup(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.groupsURI}`, data)
    .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method used for update the group details
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  updateGroup(data?: any, id?: number) {
    return this.http.patch(`${config.baseApiURL}${config.apiURI}${config.groupsURI}${id}/`, data)
    .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for creating Post for user
   *
   * @param {*} [data]
   * @returns
   * @memberof UserService
   */
  createPost(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.postsURI}`, data)
    .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting user post List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getPosts(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.postsURI}`, { params: param })
    .pipe(map(response =>{
      this.userStoreService.apiResp = response}));
  }

  /**
   * @description
   * this method is used for creating Post Like
   *
   * @param {*} [data]
   * @returns
   * @memberof UserService
   */
  likePost(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.likesURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting users List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getUsersList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for search users List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  searchUsers(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method used for invite group members
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  inviteGroupMembers(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.groupsURI}${config.userInviteURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting user post List
   *
   * @param {*} [param]
   * @param {number} [id]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getTimelinePosts(id?: number, postParams?: any) {
    const userID: any = {
      user_id: id
    }
    let param: any = {...postParams, ...userID};
    return forkJoin([
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.userTimelineURI}`, {params: param}),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.userPhotosURI}`, {params: userID}),
    ])
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

    /**
   * @description
   * This method is used for getting user post List
   *
   * @param {*} [param]
   * @param {number} [id]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getUserTimelinePosts(id?: number, postParams?: any) {
    const userID: any = {
      user_id: id
    }
    let param: any = {...postParams, ...userID};
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.userTimelineURI}`, {params: param})
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting user Photos
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getUserPhotos(id?: any, param ?: any) {
    const userID: any = {
      user_id: id
    }
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.userPhotosURI}`, {params: {...param, ...userID}})
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting user posts
   *
   * @param {*} [param]
   * @param {number} [id]
   * @returns
   * @memberof UserService
   */
  getUserPosts(param?: any, id?: number) {
    const userID: any = {
      user_id: id
    }
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.userTimelineURI}`, {params: {...param, ...userID}})
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
    * @description
    * This method is used for getting trending organizations for user
    *
    * @param {*} [param]
    * @returns this api will return a observalble
    * @memberof UserService
    */
  getTrendingOrganizations(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting user's group post
   *
   * @param {number} [id]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getGroupPost(id: number) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.groupsURI}${id}/${config.postsListURI}`)
    .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting user Albums
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getUserAlbums(id?: any, param ?: any) {
    const userID: any = {
      user_id: id
    }
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.userAlbumsListURI}`,  {params: {...param, ...userID}})
    .pipe(map(response => {
      this.userStoreService.apiResp = response
    }
    ));
  }

  /**
   * @description
   * This method is used for getting user Videos
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getUserVideos(id?: any, param ?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.userVideosURI}`, {params: {...param, ...{user_id: id}}})
    .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting group list
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */

  getInvitesUserList(id?: number) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.groupsURI}${id}/${config.invitesListURI}`)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting home page data
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getUserHomeData(param?: any, friendstype?: string) {
    return forkJoin([
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.postsURI}`, { params: param }),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.connectionsURI}${friendstype == "friendRequest" ? config.pendingRequestsURI : config.homepageSuggestedUsersURI}`, { params: param }),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.organizationsURI}${config.trendingListURI}`, { params: param }),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.postsURI}${config.mostLikedSharedPostURI}`),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.briindadURI}${config.timelineURI}?page_number=1`)])
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting user groups
   *
   * @param {number} [id]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getUserGroupsList(id: number) {
    const param: any = {
      user_id: id
    }
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.groupsURI}`, {params: param})
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting user overview
   *
   * @param {number} [id]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getUserOverView(id: number) {
    const param: any = {
      user_id: id
    }
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.overviewURI}`, {params: param})
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is user for add friend
   *
   * @param {*} [data]
   * @returns - this api will return a observalble
   * @memberof UserService
   */
  createFriend(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.connectionsURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  notifyFriendRequest(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.friendRequestsURI}${config.notifyAgainURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }
  /**
   * @description
   * this method is user for cancel friend
   *
   * @param {*} [data]
   * @returns - this api will return a observalble
   * @memberof UserService
   */
  cancelFriend(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.connectionsURI}${data}/${config.cancelURI}`, '')
      .pipe(map(response => this.userStoreService.apiResp = response));
  }
  /**
 * @description
 * this method used for sharing user post
 *
 * @param {*} [param]
 * @returns this api will return a observalble
 * @memberof UserService
 */
  shareUserPost(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.sharesURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }


  /**
   * @description
   * This method is used for getting suggested user List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getSuggestedUserList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.connectionsURI}${config.suggestedUsersURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

    /**
   * @description
   * This method is used for getting suggested user List for home page
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
     getSuggestedHomeUserList(param?: any) {
      return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.connectionsURI}${config.homepageSuggestedUsersURI}`, { params: param })
        .pipe(map(response => this.userStoreService.apiResp = response));
    }
  

  /**
   * @description
   * This method is used for getting pending user List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getPendingUserList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.connectionsURI}${config.pendingRequestsURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is user for Accept Friend Request
   *
   * @param {*} [data]
   * @returns - this api will return a observalble
   * @memberof UserService
   */
  acceptFriendRequest(id?: number, data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.connectionsURI}${id}/${config.acceptURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is user for Reject Friend Request
   *
   * @param {*} [data]
   * @returns - this api will return a observalble
   * @memberof UserService
   */
  rejectFriendRequest(id?: number, data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.connectionsURI}${id}/${config.rejectURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is user for Unfriend
   *
   * @param {*} [data]
   * @returns - this api will return a observalble
   * @memberof UserService
   */
  unFriendRequest(id?: number) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.connectionsURI}${id}/${config.unFriendURI}`, '')
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is user for Block Friend
   *
   * @param {*} [data]
   * @returns - this api will return a observalble
   * @memberof UserService
   */
  blockFriendRequest(id?: number) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.connectionsURI}${id}/${config.blockURI}`, '')
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting friend List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getFriendsList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.connectionsURI}${config.friendsURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  getPendingFriendRequests(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.friendRequestsURI}${config.sentListURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting recently added friend List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getRecentlyAddedFriendsList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.connectionsURI}${config.recentlyAddedFriendsURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting recently added friend List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getHighSchoolFriendsList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.connectionsURI}${config.highschoolFriendsURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting connected friend List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getConnectedFriendsList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.connectionsURI}${config.connectedFriendsURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting College friend List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getCollegeFriendsList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.connectionsURI}${config.collegeFriendsURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting Organization friend List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getOrganizationFriendsList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.connectionsURI}${config.organizationFriendsURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description 
   * This method is used for getting Hometown friend List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getHometownFriendsList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.connectionsURI}${config.hometownFriendsURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description 
   * This method is used for getting Volunteer friend List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getVolunteerFriendsList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.connectionsURI}${config.volunteerFriendsURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description 
   * This method is used for getting Following friend List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getFollowingFriendsList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.followersURI}${config.getFollowedOrgURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
  * @description
  * This method is used for getting user shared and tagged posts
  *
  * @param {number} [id]
  * @returns this api will return a observalble
  * @memberof UserService
  */
  getUserSharedAndTagged(id?: number, param ?: any) {
    const obj: any = {user_id: id}
    return forkJoin([
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.userTimelineURI}`, {params: {...param, ...obj}}),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.postTagsURI}`, {params: param})
    ])
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description 
   * This method is used for getting Most Like Post List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getMostLikedPostList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.postsURI}${config.mostLikedPost}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
     * @description 
     * This method is used for getting Most Shared Post List
     *
     * @param {*} [param]
     * @returns this api will return a observalble
     * @memberof UserService
     */
  getMostSharedPostList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.postsURI}${config.mostSharedPost}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
  * @description
  * this method is used to post user comment
  *
  * @param {*} [data]
  * @returns - this api will return a observalble
  * @memberof UserService
  */
  postUserPostComment(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.commentURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
* @description
* this method is used to get user post comments
*
* @returns - this api will return a observalble
* @memberof UserService
*/
  getUserPostComments() {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.commentURI}`)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for membership data
   *
   * @returns this api will return a observalble
   * @memberof UserService 
   */
  getMembershipTrendingPageApis() {
    return forkJoin([
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.organizationsURI}${config.organizationsListURI}`),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.organizationsURI}${config.trendingListURI}`),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.membershipsURI}${config.inviteListURI}`)])
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  getFollowedOrganizationLists(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.organizationsURI}${config.organizationsListURI}`, { params: param })
    .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is user for Accept Member Invitation
   *
   * @param {*} [data]
   * @returns - this api will return a observalble
   * @memberof UserService
   */
  acceptInvitation(id?: number, data ?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.membershipsURI}${id}/${config.acceptURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is user for Accept Member Invitation
   *
   * @param {*} [data]
   * @returns - this api will return a observalble
   * @memberof UserService
   */
  rejectInvitation(id?: number, data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.membershipsURI}${id}/${config.rejectURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }


  /**
 * @description
 * this method is used for creating Post Comment/Reply
 *
 * @param {*} [data]
 * @returns
 * @memberof UserService
 */
  likeCommentReply(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.likesURI}`, data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }


    /**
   * @description
   * Gets organization promotion price calc
   * @param [param] 
   * @returns  
   * @memberof UserService
   */
    getOrganizationPromotionPriceCalc(param?: any) {
      return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.promotionsURI}${config.priceCalcURI}`, { params: param })
      .pipe(map(response => this.userStoreService.apiResp = response));
    }
  
  
    /**
    * @description
    * this method is used to create org promotion
    *
    * @param {*} [data]
    * @returns
    * @memberof UserService
    */
    createOrganizationPromotion(data?: any) {
      return this.http.post(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.promotionsURI}`, data)
        .pipe(map(response => this.userStoreService.apiResp = response));
    }
  
  
    /**
     * TODO: comment getOrganizationPromotion
     * @description Gets organization promotion
     * @returns  observable
     * @memberof UserService
     */
    getOrganizationPromotion() {
      return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.promotionsURI}`)
        .pipe(map(response => this.userStoreService.apiResp = response));
    }


  /**
   * TODO: comment getUserSettings
   * @description Gets user settings
   * @param id 
   * @returns  observable
   * @memberof UserService
   */
  getUserSettings(id: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${id}/`)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }


  /**
   * TODO: comment updateUserGeneralSettings
   * @description Updates user general settings
   * @param data 
   * @returns  observable
   * @memberof UserService
   */
  updateUserGeneralSettings(data: any) {
    return this.http.put(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.settingsURI}${config.generalSettingsURI}`,data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }


  /**
   * TODO: comment getUserPrivacySettings
   * @description Gets user privacy settings
   * @returns  
   * @memberof UserService
   */
  getUserPrivacySettings() {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.settingsURI}`)
      .pipe(map(response => this.appStore.apiResp = response));
  }


  /**
   * TODO: comment updateUserPrivacySettings
   * @description Updates user privacy settings
   * @param data 
   * @returns  
   * @memberof UserService
   */
  updateUserPrivacySettings(data: any) {
    return this.http.put(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.settingsURI}${config.updateUserSettingsURI}`,data)
    .pipe(map(response => this.appStore.apiResp = response));
  }

  getHasTagList(param?: any){
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.hashTagURI}`, { params: param }).pipe(map(response => this.userStoreService.apiResp = response));
  }

  getHashTagData(param?: any){
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.hashTagURI}`, { params: param }).pipe(map(response => this.userStoreService.apiResp = response));
  }

  getHasTagData(param?: any){
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.hashTagData}?hash_tag=${param.hash_tag}`).pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
 * @description
 * this method is used for remove photo
 *
 * @param {*} [data]
 * @returns
 * @memberof UserService
 */
   removePhoto(data?: any) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.userPhotosURI}${data}/`)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
 * @description
 * this method is used for remove post
 *
 * @param {*} [data]
 * @returns
 * @memberof UserService
 */
   removePost(data?: any) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.postsURI}${data}/`)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  removeSharedPost(data?: any) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.sharesURI}${data}/`)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }
  removeTaggedPost(data?: any) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.postTagsURI}${data}/`)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }




  /**
   * TODO: comment deleteSavedCard
   * @description Deletes saved card
   * @param [id] 
   * @returns  
   * @memberof UserService
   */
  deleteSavedCard(id?: any) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.settingsURI}${id}/${config.deleteSavedCardURI}`)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for getting photo details
   *
   * @param {number} [id]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getPhotoDetails(id?: number) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.userPhotosURI}${id}/`)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting user post List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
   getPostDetails(id?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.postsURI}${id}/`)
    .pipe(map(response =>{
      this.userStoreService.apiResp = response}));
  }

  /**
   * TODO: comment updatePhotoAlbum
   * @description Updates photo album
   * @param data 
   * @returns  observable
   * @memberof UserService
   */
   updateUserPost(id?:number, data?: any) {
    return this.http.put(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.postsURI}${id}/`,data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
 * @description
 * this method is used for remove photo
 *
 * @param {*} [data]
 * @returns
 * @memberof UserService
 */
   removeVideo(id?: any) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.userVideosURI}${id}/`)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting user post List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
   getTrendingOrganization(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.usersURI}${config.organizationsURI}${config.trendingListURI}`, { params: param })
    .pipe(map(response =>{
      this.userStoreService.apiResp = response}));
  }
}
