import { AppStore } from 'src/app/core/stores/app.store';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-customize-event-template',
  templateUrl: './customize-event-template.component.html',
  styleUrls: ['./customize-event-template.component.scss']
})
export class CustomizeEventTemplateComponent implements OnInit {
  @Input() customizeData: any;
  @Input() data: any;
  @Input() isPreview: boolean = false;
  objectKeys = Object.keys;
  selectedRadio: any;
  @Output() sendCustomizeUI = new EventEmitter();
  @Output() stepperActions = new EventEmitter();
  fontFamilyList: any = [{
    id: 'poppins-regular',
    name: 'poppins regular'
  },{
    id: 'poppins-medium',
    name: 'poppins medium'
  },{
    id: 'poppins-semibold',
    name: 'poppins semibold'
  },{
    id: 'poppins-bold',
    name: 'poppins bold'
  }, {
    id: 'segoe-ui-bold-italic',
    name: 'segoe ui bold italic'
  },{
    id: 'segoe-ui-italic',
    name: 'segoe ui italic'
  },{
    id: 'segoe-ui-regular',
    name: 'segoe ui regular'
  },{
    id: 'segoe-ui-bold',
    name: 'segoe ui bold'
  }]
  constructor(
    public appStore: AppStore
  ) { }

  sendUIData() {
    setTimeout((x:any) => {
      this.sendCustomizeUI.emit(this.customizeData)
    },100)
  }

  actionCTA(event: any) {
    this.stepperActions.emit(event)
  }

  ngOnInit(): void {
  }

}
