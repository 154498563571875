<div class="flex flexJustifyBetween mrgntop5">
    <div class="color-122A4D poppins-semibold font20 mrgnleft5">{{'social media' | translate}}</div>
    <div *ngIf="showEditIcon" [routerLink]="['/organizations',organizationID,'profile','social','social-media']"
        class="d30 flex flexJustifyCenter flexAlignItemsCenter cursorPtr ripple bdrRadius50p">
        <i class="icns icon-edit blue d20"></i>
    </div>
</div>

<ng-container *ngIf="data?.facebook_link || data?.twitter_link || data?.instagram_link || data?.youtube_link || data?.other_link">
        <div class="flex flexWrap flexResponsive">
            <div class="info-container mrgn5 bg-E5ECF1 bdrRadius6px flex pad10 width50" *ngIf="data?.facebook_link">
                <div class="d20">
                    <i class="icns icon-facebook green d20"></i>
                </div>
                <div class="padleft10 flex flexDirectionCol flexJustifyBetween">
                    <div class="color-8E8E8E font14 poppins-regular lineHeight18px">
                        {{'facebook' | translate}}
                    </div>
                    <div class="color-122A4D font14 poppins-medium wrdBreakWord">
                        {{data?.facebook_link}}</div>
                </div>
            </div>
            <div class="info-container mrgn5 bg-E5ECF1 bdrRadius6px flex pad10 width50" *ngIf="data?.twitter_link">
                <div class="d20">
                    <i class="icns icon-twitter green d20"></i>
                </div>
                <div class="padleft10 flex flexDirectionCol flexJustifyBetween">
                    <div class="color-8E8E8E font14 poppins-regular lineHeight18px">
                        {{'twitter' | translate}}
                    </div>
                    <div class="color-122A4D font14 poppins-medium wrdBreakWord">
                        {{data?.twitter_link}}</div>
                </div>
            </div>
            <div class="info-container mrgn5 bg-E5ECF1 bdrRadius6px flex pad10 width50" *ngIf="data?.instagram_link">
                <div class="d20">
                    <i class="icns icon-instagram green d20"></i>
                </div>
                <div class="padleft10 flex flexDirectionCol">
                    <div class="color-8E8E8E font14 poppins-regular lineHeight18px">
                        {{'instagram' | translate}}
                    </div>
                    <div class="color-122A4D font14 poppins-medium wrdBreakWord">
                        {{data?.instagram_link}}</div>
                </div>
            </div>
            <div class="info-container mrgn5 bg-E5ECF1 bdrRadius6px flex pad10 width50" *ngIf="data?.youtube_link">
                <div class="d20">
                    <i class="icns icon-youtube green d20"></i>
                </div>
                <div class="padleft10 flex flexDirectionCol">
                    <div class="color-8E8E8E font14 poppins-regular lineHeight18px">
                        {{'youtube' | translate}}
                    </div>
                    <div class="color-122A4D font14 poppins-medium wrdBreakWord">
                        {{data?.youtube_link}}</div>
                </div>
            </div>
            <div class="info-container mrgn5 bg-E5ECF1 bdrRadius6px flex pad10 width50" *ngIf="data?.other_link">
                    <div class="d20">
                        <i class="icns icon-public green d20"></i>
                    </div>
                    <div class="padleft10 flex flexDirectionCol">
                        <div class="color-8E8E8E font14 poppins-regular lineHeight18px">
                            {{'other link' | translate}}
                        </div>
                        <div class="color-122A4D font14 poppins-medium wrdBreakWord">
                            {{data?.other_link}}</div>
                    </div>
                </div>
        </div>
    </ng-container>