<div class=" bdrRadius6px bdr-ebebeb bg-F9F9F9">
    <div class="posRelative" style="display: grid;">
        <app-simple-carousel [autoSlide]="'false'" *ngIf="data?.photos?.length">
            <ng-container *ngFor="let item of data?.photos">
                <ng-container *carouselItem>
                    <img [src]="item?.base64 || item?.photo"
                        class="width100 bdrRadiusTopLeftt6px bdrRadiusTopRight6px  minHeight350px maxHeight350px " />
                </ng-container>
            </ng-container>
        </app-simple-carousel>
        <img src="assets/images/temp-11.png" *ngIf="!data?.photos?.length && isPreview"
            class="width100 bdrRadiusTopLeftt6px bdrRadiusTopRight6px" />
        <div style="background-image: linear-gradient(to right, #02a1f9, #29f19c); height: 12px; line-height: 12px;">
            &nbsp;</div>
    </div>

    <div class="padleft20 padtop20 bold txtTransformUp txtLeft {{customizeUI?.event_title?.title.font_family}}"
        *ngIf=" data?.name || isPreview"
        [ngStyle]="{'font-size.px': customizeUI?.event_title?.title.font, 'color': customizeUI?.event_title?.title?.color}">
        {{(data?.name ? data?.name : isPreview ? 'Briind Event' : '') | translate}} </div>
    <div class=" pad20  {{customizeUI?.event_title?.description?.font_family}}" *ngIf=" data?.description || isPreview"
        [ngStyle]="{'font-size.px': customizeUI?.event_title?.description?.font, 'color': customizeUI?.event_title?.description?.color}">
        {{(data?.description ? data?.description : isPreview ? 'lorem ipsum' : '') | translate}}
    </div>

    <ng-container *ngIf="data?.guest?.length || isPreview">
        <div class="{{customizeUI?.guest_speakers?.speakers_title?.font_family}} pad20 padtop0 padbtm0"
            [ngStyle]="{'font-size.px': customizeUI?.guest_speakers?.speakers_title?.font, 'color': customizeUI?.guest_speakers?.speakers_title?.color}">
            {{'guest_speakers' | translate}}</div>
        <div class="flex width100 flexWrap mrgnauto flexJustifyCenter pad20 padtop10">
            <ng-container *ngIf="data?.guest?.length && !isPreview">
                <div class="txtCenter width30 mrgn5 padtop15 padbtm15 bg-FFFFFF bdrRadius6px boxShadow-RGBA00005"
                    *ngFor="let item of data?.guest">
                    <app-profile-thumbnail [image]="item.photo" [imageClass]="'d70'"></app-profile-thumbnail>
                    <div class="padtop10 {{customizeUI?.guest_speakers?.speakers_name?.font_family}} txtTransformCaps"
                        [ngStyle]="{'font-size.px': customizeUI?.guest_speakers?.speakers_name?.font, 'color': customizeUI?.guest_speakers?.speakers_name?.color}">
                        {{item?.name}}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="isPreview">
                <div class="txtCenter mrgn5 width30 padtop15 padbtm15 bg-FFFFFF bdrRadius6px boxShadow-RGBA00005"
                    *ngFor="let item of briindGuest">
                    <app-profile-thumbnail [image]="item.photo" [imageClass]="'d70'"></app-profile-thumbnail>
                    <div class="padtop10 {{customizeUI?.guest_speakers?.speakers_name?.font_family}} txtTransformCaps"
                        [ngStyle]="{'font-size.px': customizeUI?.guest_speakers?.speakers_name?.font, 'color': customizeUI?.guest_speakers?.speakers_name?.color}">
                        {{item?.name}}
                    </div>
                </div>
            </ng-container>

        </div>
    </ng-container>
    <ng-container *ngIf="data?.who_can_attend || isPreview">
        <div class="padbtm5  {{customizeUI?.guest_speakers?.who_can_attend_title?.font_family}}  padleft20"
            [ngStyle]="{'font-size.px': customizeUI?.guest_speakers?.who_can_attend_title?.font, 'color': customizeUI?.guest_speakers?.who_can_attend_title?.color}">
            {{'who can attend' | translate}}
        </div>
        <div class="{{customizeUI?.guest_speakers?.who_can_attend_description?.font_family}} padleft20 padright20"
            [ngStyle]="{'font-size.px': customizeUI?.guest_speakers?.who_can_attend_description?.font, 'color': customizeUI?.guest_speakers?.who_can_attend_description?.color}">
            {{(data?.who_can_attend ? data?.who_can_attend : isPreview ? 'lorem ipsum' : '') | translate}}
        </div>
    </ng-container>



    <div class="flex padleft20 padright20 padtop20 flexAlignItemsStretch">
        <div class="width49 temp1Datebox pad5 bdrRadius6px padleft10 padright10" style="border: solid 2px;"
            *ngIf="data?.event_from || isPreview"
            [ngStyle]="{'border-color': customizeUI?.venue_and_time?.time?.color}">
            <div class="flex flexJustifyCenter flexAlignItemsCenter ">
                <div class="padright5 {{customizeUI?.venue_and_time?.time?.font_family}}"
                    [ngStyle]="{'font-size.px': customizeUI?.venue_and_time?.time?.font, 'color': customizeUI?.venue_and_time?.time?.color}">
                    {{moment(data?.event_from).format('DD')}}
                </div>
                <div class="padright10">
                    <div class=""
                        style="font-family: Segoe UI Bold Italic; font-size: 18px; color: #122a4d;text-transform: uppercase;">
                        {{moment(data?.event_from).format('MMMM')}}
                    </div>
                    <div class="" style="font-family: Segoe UI Italic; font-size: 14px; color: #6f6f6f;">
                        {{moment(data?.event_from).format('dddd')}}
                    </div>
                </div>
                <div class=" {{customizeUI?.venue_and_time?.time?.font_family}}"
                    [ngStyle]="{'font-size.px': customizeUI?.venue_and_time?.time?.font, 'color': customizeUI?.venue_and_time?.time?.color}">
                    -
                </div>
                <div class="padright5 {{customizeUI?.venue_and_time?.time?.font_family}}"
                    [ngStyle]="{'font-size.px': customizeUI?.venue_and_time?.time?.font, 'color': customizeUI?.venue_and_time?.time?.color}">
                    {{moment(data?.event_to).format('DD')}}
                </div>
                <div>
                    <div
                        style="font-family: Segoe UI Bold Italic; font-size: 18px; color: #122a4d;text-transform: uppercase;">
                        {{moment(data?.event_to).format('MMMM')}}
                    </div>
                    <div class="" style="font-family: Segoe UI Italic; font-size: 14px; color: #6f6f6f;">
                        {{moment(data?.event_to).format('dddd')}}
                    </div>
                </div>
            </div>
        </div>
        <div class="width2" *ngIf="data?.event_from || isPreview">&nbsp;</div>
        <div class="width49 temp1Placebox pad5 padleft10 padright10 bdrRadius6px flex flexDirectionCol flexJustifyCenter"
            style="border: 2px solid #05a6f3;" *ngIf="data?.venue || data?.venue_name || isPreview">
            <div class=" padbtm5 {{customizeUI?.venue_and_time?.venue?.font_family}}"
                [ngStyle]="{'font-size.px': customizeUI?.venue_and_time?.venue?.font, 'color': customizeUI?.venue_and_time?.venue?.color}">
                {{((data?.venue || data?.venue_name) ? (data?.venue?.name || data?.venue_name || data.venue) : isPreview ? 'At Briind' : '') | translate}}
            </div>

            <div class="flex">
                <div class="padright20" style="font-family: Segoe UI Regular ;font-size: 13px;
color: #767676;">{{'start' | translate}} : {{moment(data?.event_from).format('HH:MM A')}} </div>
                <div class="" style="font-family: Segoe UI Regular ;font-size: 13px;
color: #767676;">{{'end' | translate}} : {{moment(data?.event_to).format('HH:MM A')}} </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="data?.registration_guidelines || isPreview">
        <div class="poppins-semibold padtop20 padleft20 padbtm5 {{customizeUI?.registration_guideline?.title?.font_family}}"
            [ngStyle]="{'font-size.px': customizeUI?.registration_guideline?.title?.font, 'color': customizeUI?.registration_guideline?.title?.color}">
            {{'registration guidlines' | translate}}</div>
        <div class="{{customizeUI?.registration_guideline?.description?.font_family}} padleft20 padright20"
            [ngStyle]="{'font-size.px': customizeUI?.registration_guideline?.description?.font, 'color': customizeUI?.registration_guideline?.description?.color}">
            {{(data?.registration_guidelines ? data?.registration_guidelines : isPreview ? 'lorem ipsum' : '') | translate}}
        </div>
    </ng-container>

    <div class="flex padbtm10">
        <div class="padtop20 padleft20 width70 {{customizeUI?.date?.date?.font_family}}"
            *ngIf="data?.registration_last_date || isPreview"
            [ngStyle]="{'font-size.px': customizeUI?.date?.date?.font, 'color': customizeUI?.date?.date?.color}">
            {{'last date of registration' | translate}} - {{moment(data?.registration_last_date).format('DD. MMMM')}}
        </div>
        <!-- <div class="padtop20 padright20 txtRight padbtm15 width30 overflowHidden"
            style="white-space: nowrap;text-overflow: ellipsis;" *ngIf="data?.website_link || isPreview">
            <a [href]="data?.website_link" class="{{customizeUI?.button_customization?.website?.font_family}}"
                [ngStyle]="{'font-size.px': customizeUI?.button_customization?.website?.font , 'color': customizeUI?.button_customization?.website?.color}">{{data?.website_link || 'www.events.com'}}</a>
        </div> -->
    </div>

    <div class="flex temp1FooterBg">
        <div class="width80" *ngIf="countdownTimer">
            <div class="poppins-semibold bdrRadius6px padtop50 padleft20"
                style="display: inline-block;font-size: 16px;font-weight: 600;color: #ffffff;">
                {{'time left' | translate}} {{countdownTimer}}
            </div>
        </div>
        <div class="width20" *ngIf="data?.fee || isPreview">
            <div class="flex padtop20 flexAlignItemsCenter padleft20">
                <div class="{{customizeUI?.registration_fee?.title?.font_family}} txtTransformUp padright5"
                    [ngStyle]="{'font-size.px': customizeUI?.registration_fee?.title?.font, 'color': customizeUI?.registration_fee?.title?.color}">
                    {{'reg' | translate}}<br>{{'fee' | translate}}</div>
                <div class="{{customizeUI?.registration_fee?.value?.font_family}} txtCenter"
                    [ngStyle]="{'font-size.px': customizeUI?.registration_fee?.value?.font, 'color': customizeUI?.registration_fee?.value?.color}">
                    {{'amount in number' | translate: {amount: data?.fee || 0} }}
                </div>
            </div>
        </div>
    </div>

    <div class="flex flexJustifyBetween bdrRadiusBottomLeftt6px bdrRadiusBottomRight6px overflowHidden">
        <div class="flex1" *ngIf="data?.organization_link || isPreview">
            <button  (click)="saveBriindletViewProfile(data?.organization_link, 'organization_link')"
                class="btn-get-started height45px ripple cursorPtr bdrRadius0 pad10 txtCenter width100  btn-get-started"
                class="{{customizeUI?.button_customization?.view_profile?.font_family}}"
                [ngStyle]="{'font-size.px': customizeUI?.button_customization?.view_profile?.font, 'background-color': customizeUI?.button_customization?.view_profile?.background_color , 'color': customizeUI?.button_customization?.view_profile?.color}">
                {{customizeUI?.button_customization?.view_profile?.button_name | translate}}
            </button>
        </div>
        <div class="flex1" style="border-left: 2px solid #EEEEEE;" *ngIf="data?.cause  || isPreview">
            <button (click)="saveBriindletViewProfile(data?.cause, 'cause')"
                class="btn-get-started height45px ripple cursorPtr bdrRadius0 pad10 txtCenter width100  btn-get-started"
                class="{{customizeUI?.button_customization?.donate_now?.font_family}}"
                [ngStyle]="{'font-size.px': customizeUI?.button_customization?.donate_now?.font, 'background-color': customizeUI?.button_customization?.donate_now?.background_color , 'color': customizeUI?.button_customization?.donate_now?.color}">
                {{customizeUI?.button_customization?.donate_now?.button_name | translate}}
            </button>
        </div>
        <div class="flex1"  style="border-left: 2px solid #EEEEEE;" *ngIf="data?.website_link || isPreview">
            <button (click)="saveBriindletRegister(data?.website_link, 'website_link')"
                class="btn-get-started height45px ripple cursorPtr bdrRadius0 pad10 txtCenter width100  btn-get-started"
                class="{{customizeUI?.button_customization?.website?.font_family}}"
                [ngStyle]="{'font-size.px': customizeUI?.button_customization?.website?.font, 'background-color': customizeUI?.button_customization?.website?.background_color || 'rgb(46, 23, 23)' , 'color': customizeUI?.button_customization?.website?.color}">
                {{customizeUI?.button_customization?.website?.button_name || 'www.events.com'}}
            </button>
        </div>
        <!-- <div class="width100  txtCenter pad20  " style="background: #2E1717; font-family: Segoe UI Regular;font-size: 16px;
font-weight: 600; color: #ffffff;">View Profile</div> -->

    </div>



</div>