import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OrganizationService } from 'src/app/organizations/service/organization.service';
import { OrganizationStoreService } from 'src/app/organizations/store/organization-store.service';

@Component({
  selector: 'app-leader-card',
  templateUrl: './leader-card.component.html',
  styleUrls: ['./leader-card.component.scss']
})
export class LeaderCardComponent implements OnInit {
  @Input('data') data: any;
  @Input('showClose') showClose: boolean = false;
  @Output() removeItem : EventEmitter<any> = new EventEmitter();
  @Input('leaderType') leaderType: string = 'leader';
  constructor(private organizationService: OrganizationService,
    private organizationStoreService: OrganizationStoreService
    ) { }

  /**
   * @description
   * this method is used fr emitting the event of remove card
   *
   * @memberof LeaderCardComponent
   */
  removeCard() {
    this.removeItem.emit(this.data.user?.id ||this.data?.id )
  }

  ngOnInit(): void {
    console.log(this.data)
  }

  deleteLeader(cardData : any){
    this.organizationService.deleteLeaders(cardData?.id).subscribe(response => {
      this.removeItem.emit(cardData)
    })
  }

}
