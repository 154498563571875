import { Injectable } from '@angular/core';
import { observable, action } from "mobx-angular";
import {
  UserModel, PostModel, GroupModel, 
  GroupCategoryModel, VideoModel, PostPhotosModel, 
  InvitesUserModel, OrganizationModel, SuggestedUserModel, 
  PendingAcceptedUserModel, UserMembershipTrendingListModel, MemberInvitationListModel
} from '../../core/models';
import { suggestedFriends, friendsAction, friendRequest, manageAction, joinAction, groupDeleteActions, friendRequestSent  } from 'src/app/core/mock-api';
import { CommentModel } from 'src/app/core/models/comment.model';
import { PromotionPriceModel } from 'src/app/core/models/promotion.model';
import { OrganizationPromotionModel } from 'src/app/core/models/organizationpromotion.model';
import { TrendingOrganizationModel } from 'src/app/core/models/trending-organization.model';
import { GeneralSettingsModel } from 'src/app/core/models/generalsettings.model';
import { PrivacySettingsModel } from 'src/app/core/models/privacysettings.model';
import { AdvertisementTimelineModel } from 'src/app/core/models/briindlet.model';
@Injectable({
  providedIn: 'root'
})

export class UserStoreService {
  @observable apiResp: any;
  @observable userList: UserModel[] = [];
  @observable invitesUserList: InvitesUserModel[] = [];
  @observable userDetails: UserModel = new UserModel();
  @observable postDetails: PostModel = new PostModel();
  @observable profileDetail: UserModel = new UserModel();
  @observable userId: any = '';
  @observable postList: PostModel[] = [];
  @observable groupCatrgoryList: GroupCategoryModel[] = [];
  @observable groupList: GroupModel[] = [];
  @observable groupDetail: GroupModel = new GroupModel();
  @observable photoList: PostPhotosModel[] = [];
  @observable albumList: PostModel[] = [];
  @observable topHashTagData: PostModel[] = [];
  @observable public userVideoList: VideoModel[] = [];
  @observable public trendingOrganizationsList: TrendingOrganizationModel = new TrendingOrganizationModel();
  @observable suggestedUserList: SuggestedUserModel[] = [];
  @observable pendingUserList: PendingAcceptedUserModel[] = [];
  @observable friendList: PendingAcceptedUserModel[] = [];
  @observable mostLikedPostList: PostModel = new PostModel();
  @observable mostSharedPostList: PostModel = new PostModel();
  @observable topHashTag: any[] = [];
  @observable membershipTrendingList: UserMembershipTrendingListModel[] = [];
  @observable membershipOrganizationList: UserMembershipTrendingListModel[] = [];
  @observable public commentlList: CommentModel[] = [];
  @observable memberInvitationsList: MemberInvitationListModel[]  = [];
  @observable promotionPriceModel: PromotionPriceModel = new PromotionPriceModel();
  @observable organizationPromotionList: OrganizationPromotionModel[] = [];
  @observable generalSettingsModel: GeneralSettingsModel = new GeneralSettingsModel();
  @observable privacySettingsModel: PrivacySettingsModel = new PrivacySettingsModel();
  @observable advertisementTimelineList: AdvertisementTimelineModel = new AdvertisementTimelineModel();

  constructor() { }

  /**
   * @description
   * setting user data
   *
   * @memberof UserStoreService
   */
  @action getUserDetails() {
    this.userDetails = this.apiResp.result || this.apiResp;
    this.userId = this.userDetails.id;
  }

  @action getHasTagData(){
    this.topHashTagData = this.apiResp;
  }

  /**
   * @description
   * setting other user data
   *
   * @memberof UserStoreService
   */
  @action getProfileDetails() {
    this.profileDetail = this.apiResp.result || this.apiResp;
  }

  /**
   * @description
   * setting other user data
   *
   * @memberof UserStoreService
   */
  @action getProfileOverview() {
    this.profileDetail.overview = this.apiResp.overview || this.apiResp;
  }

  /**
   * @description
   * setting user contact information
   *
   * @memberof UserStoreService
   */
  @action getContactInfoDetails() {
    this.profileDetail.contact_information = this.apiResp.result || this.apiResp;
  }

  /**
   * @description
   * get user High school information
   *
   * @memberof UserStoreService
   */
  @action getUserWorkAndEducation() {
    this.profileDetail.user_high_school_information = this.apiResp[0];
    this.profileDetail.user_university_information = this.apiResp[1];
    this.profileDetail.user_work_information = this.apiResp[2];
    this.profileDetail.user_skill_information = this.apiResp[3];
    this.profileDetail.user_interest_information = this.apiResp[4];

  }

  /**
   * @description
   * setting user lived in information
   *
   * @memberof UserStoreService
   */
  @action getLivedInInfo() {
    this.profileDetail.places_information = this.apiResp.result || this.apiResp;
  }

  /**
   * @description
   * setting user Post List
   *
   * @memberof UserStoreService
   */
  @action getPostList() {
    this.postList = this.apiResp;
  }

  /**
   * @description
   * setting user Group Category List
   *
   * @memberof UserStoreService
   */
  @action getGroupCategory() {
    this.groupCatrgoryList = this.apiResp;
  }

  /**
   * @description
   * setting user Group List
   *
   * @memberof UserStoreService
   */
  @action getGroupList() {
    this.groupList = this.apiResp;
  }

  /**
   * @description
   * setting user data
   *
   * @memberof UserStoreService
   */
  @action getGroupDetails() {
    this.groupDetail = this.apiResp.result || this.apiResp;
  }

  /**
   * @description
   * setting Group Detail Page Data
   *
   * @memberof UserStoreService
   */
  @action setGroupDetailPage() {
    this.groupDetail = this.apiResp[0];
    this.postList = this.apiResp[1];
    this.invitesUserList = this.apiResp[2];
  }

  /**
   * @description
   * setting user interest information
   *
   * @memberof UserStoreService
   */
  @action getInterestInfo() {
    this.profileDetail.user_movies_information = this.apiResp.result.favourite_movies || [];
    this.profileDetail.user_musics_information = this.apiResp.result.favourite_musics || [];
    this.profileDetail.user_sports_information = this.apiResp.result.favourite_sports || [];
    this.profileDetail.user_political_information = this.apiResp.result.political_views || [];
    this.profileDetail.user_religious_information = this.apiResp.result.religious_views || [];
    console.log( this.profileDetail)
  }

  /**
   * @description
   * setting user photos information
   *
   * @memberof UserStoreService
   */
  @action getPhotosList() {
    this.photoList = this.apiResp;
  }
  /**
  * @description
   * setting video list for user
   *
   * @memberof OrganizationStoreService
   */
  @action getVideoList() {
    this.userVideoList = this.apiResp
  }

  /**
   * @description
   * setting user albul List information
   *
   * @memberof UserStoreService
   */
  @action getAlbumList() {
    this.albumList = this.apiResp;
  }

  /**
   * @description
   * setting user user List information
   *
   * @memberof UserStoreService
   */
  @action getUserList() {
    this.userList = this.apiResp;
  }

  /**
   * @description
   * setting user timeline page information
   *
   * @memberof UserStoreService
   */
  @action setTimelinePage() {
    this.postList = this.apiResp[0];
    this.photoList = this.apiResp[1];
  }
  /**
   * @description
   * Invites Users List
   *
   * @memberof UserStoreService
   */
  @action getInviteUserList() {
    this.invitesUserList = this.apiResp;
  }

  /**
* @description
 * setting trending organization list for user
 *
 * @memberof UserStoreService
 */
  @action getTrendingOrganizationsList() {
    this.trendingOrganizationsList = this.apiResp
  }

  /**
   * @description
   * setting user Home page data
   *
   * @memberof UserStoreService
   */
  @action setUserHomePage() {
    this.postList = this.apiResp[0];
    this.suggestedUserList = this.setActionsForFriends(this.apiResp[1]);
    this.trendingOrganizationsList = this.apiResp[2];
    this.mostLikedPostList = this.apiResp[3].most_liked_post  ? this.apiResp[3].most_liked_post : {};
    this.mostSharedPostList = this.apiResp[3].most_shared_post  ? this.apiResp[3].most_shared_post : {};
    this.advertisementTimelineList = this.apiResp[4]

  }

  /**
     * @description
     * getting suggested user List 
     *
     * @memberof UserStoreService
     */
  @action getSuggestedUserList() {
    this.suggestedUserList = this.setActionsForFriends(this.apiResp);

  }

  /**
   * @description
   * getting pending user List 
   *
   * @memberof UserStoreService
   */
  @action getPendingUserList() {
    this.pendingUserList = this.apiResp;
  }

  /**
   * @description
   * getting Friend List 
   *
   * @memberof UserStoreService
   */
  @action getFriendsList() {
    this.friendList = this.setActionsForFriends(this.apiResp);
  }

  /**
   * @description
   * getting Friend List 
   *
   * @memberof UserStoreService
   */
   @action getFriendsListForTag() {
    this.friendList = this.apiResp;
  }

  /**
   * @description
   * method is used for setting the actions for firends
   *
   * @private
   * @param {any[]} arrayData
   * @memberof UserStoreService
   */
  private setActionsForFriends(arrayData: any[]) {
    for (const item of arrayData) {
      if (item.status) {
        switch (item.status.toLowerCase()) {
          case 'accepted':
            item.actions = friendsAction
            break;
          case 'pending':
            if (item.is_sent == true) {
              item.actions = friendRequestSent;
            } else {
              item.actions = friendRequest;
            }
            break;
          default:
            break;
        }
      } else {
        if (item.connection_detail && item.connection_detail.id) {
          item.actions = [suggestedFriends[1]];
        } else {
          item.actions = [suggestedFriends[0]];
        }
      }
    }
    return arrayData;
  }

  /**
  * @description
  * setting shared and tagged posts for user
  *
  * @memberof UserStoreService
  */
  @action setSharedAndTaggedPosts() {
    this.postList = this.apiResp[0];
    this.postList.concat(this.apiResp[1]);
  }

  /**
   * @description
   * getting Most Liked Post List 
   *
   * @memberof UserStoreService
   */
  @action getMostLikedPostList() {
    this.mostLikedPostList = this.apiResp;
  }

  /**
   * @description
   * getting Most Shared Post List 
   *
   * @memberof UserStoreService
   */
  @action getMostSharedPostList() {
    this.mostSharedPostList = this.apiResp;
  }

  @action getHasTagList(){
    this.topHashTag = this.apiResp;
  }

  /**
   * @description
   * setting the Membership Trending Page
   *
   * @memberof UserStoreService
   */
  @action setMembershipTrendingPage() {
    this.membershipOrganizationList = this.apiResp[0];
    this.membershipTrendingList = this.apiResp[1].data;
    this.memberInvitationsList = this.apiResp[2]
    for (const item of this.membershipOrganizationList) {
      item.actions = manageAction;
    }
    for (const item of this.membershipTrendingList) {
      item.actions = joinAction;
    }

    for (const item of this.memberInvitationsList) {
      item.actions = friendRequest;
    }

  }

  /**
   * @description
   * setting comments list for user
   *
   * @memberof UserStoreService
   */
  @action setCommentsList() {
    this.commentlList = this.apiResp;
  }


  /**
 * @description
 * setting organization promotion price
 *
 * @memberof UserStoreService
 */
  @action setOrganizationPromotionPrice() {
    this.promotionPriceModel = this.apiResp;
  }


  /**
   * TODO: comment action
   * @description Actions organization store service
   * @memberof UserStoreService
   */
  @action resetOrganizationPromotionPrice() {
    this.promotionPriceModel = new PromotionPriceModel();
  }


  /**
   * TODO: comment action
   * @description Actions organization store service
   * @memberof UserStoreService
   */
  @action setOrganizationPromotionList() {
    this.organizationPromotionList = this.apiResp;
  }

  /**
   * TODO: comment action
   * @description Actions user store service
   * @memberof UserStoreService
   */
  @action setUserSettings() {
    this.userDetails = this.apiResp;
  }


  /**
   * TODO: comment action
   * @description Actions user store service
   * @memberof UserStoreService
   */
  @action setUserGeneralSettings(){
    this.generalSettingsModel = this.apiResp;
  }


  /**
   * TODO: comment action
   * @description Actions user store service
   * @memberof UserStoreService
   */
  @action setUserPrivacySettings(){
    this.privacySettingsModel = this.apiResp.data || this.apiResp;
  }

  /**
   * TODO: comment action
   * @description Actions user store service
   * @memberof UserStoreService
   */
   @action getPostDetails(){
    this.postDetails = this.apiResp;
  }
}
