<div class="flex flexAlignItemsCenter flexNoShrink" *ngIf="primaryActions?.length != 0 || moreActions?.length != 0">
    <div class="pad3"></div>
    <ng-container *ngIf="primaryActions?.length != 0">
        <ng-container *ngFor="let action of primaryActions;let last = last;">
            <button *ngIf="action.view_state" type="button" (click)="cardCTA(action)"
                [ngClass]="{'btn-user-default': action.primary_button, 'cancel-btn': !action.primary_button}"
                class="btn-get-started minWidth90px padleft10 padright10 bdr-1-EEEEEE  ripple cursorPtr font12 poppins-regular pad5">
                {{ action.action_text | translate }}
            </button>
            <div class="mrgnright5" *ngIf="!last"></div>
        </ng-container>

    </ng-container>
    <div class="cursorPtr profile-icon  mrgnleft5 flex " *ngIf="moreActions.length != 0">
        <i class="icns icon-three-dots d20 rotate-90deg ripple"></i>
        <div class="bdr-1-EEEEEE zIndex1 profile-menu posAbsolute posRight bg-white bdrRadius6px">
            <ng-container *ngFor="let action of moreActions">
                <a *ngIf="action.view_state" (click)="cardCTA(action)" class="flex moreAction flexAlignItemsCenter color-122A4D font13 bold poppins-regular padtop10 padleft15 padright15 padbtm10 ripple">
                    {{ action.action_text | translate }}
                </a>
            </ng-container>
        </div>
    </div>
</div>