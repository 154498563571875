<div class="flex">
    <div class="font16 poppins-semibold timeline-label txtTransformCaps padright10">{{post?.posted_by?.name}}
    </div>
    <div class="color-8E8E8E font14 poppins-medium padtop2">{{post?.created_at | dateAgo }}
    </div>
</div>
<ng-container *ngIf="post?.description">
    <div class="font15 color-122A4D poppins-regular wrdBreakAll" *ngIf="post?.description?.length > 200">
        {{(showMore) ? post?.description : post?.description | slice:0:200}}<span *ngIf="!showMore">...</span>
        <a href="javascript:;" class="seeMore" (click)="showMore = !showMore">{{showMore ? ' See Less': ' See more'}}</a> 
        <div class="pad5"></div>
    </div>
    <div class="font15 color-122A4D poppins-regular wrdBreakAll" *ngIf="post?.description?.length < 200">
        {{post?.description}}
        <div class="pad5"></div>
    </div>
</ng-container>