<app-popup></app-popup>
<section id="homePage" class="height100p ">
    <div class="width100 bg-02457A headerbg posRelative ">
        <app-main-header></app-main-header>

        <!-- first section start here -->
        <div class="flex flexWrap width100 posRelative section1464">
            <div class="width67 padleft6p flex flexJustifyCenter flexDirectionCol header-left-part">
                <div class="header-title poppins-semibold font40 color-FFFFFF txtLeft" [innerHTML]="'changing the way you connect' | translate"></div><br />
                <div class="header-title poppins-semibold font40 color-FFFFFF txtLeft" [innerHTML]="'socially and do business' | translate"></div>
                <div class="font15 padtop30 padbtm30 poppins-light fontWeight300 letterSpacingP36px txtLeft color-FFFFFF">
                    <p>
                        {{ 'briind has the power to engage inspire and impact briind social life is secure and different and member based organizations and businesses leverage from briinds business applications' | translate}}
                    </p>
                    <p class="bold">{{ 'engage with others manage your business' | translate }}</p>
                </div>
                <div class="padbtm15">
                    <button (click)="signInCTA();" class="btn-get-started ripple font16 poppins-medium cursorPtr">
            {{'get started' | translate}}
            <i class="icns d2410 icon-arrow-right blue padleft5"></i>
          </button>
                </div>
            </div>
            <div class="width33 flex flexAlignItemsEnd">
                <img src="assets/images/header-right.png" class="width100 height100p desktopVisible" alt="">
                <img src="assets/images/header-img-mob.png" class="width100 mobiVisible" alt="">
            </div>
            <img src="assets/images/pattern.png" class="pattern-1 posAbsolute desktopVisible">
        </div>
        <!-- first section end here -->
    </div>

    <!-- second section start here -->
    <div class="padtop100 desktopVisible"></div>
    <div class="section-2 width88 padbtm15 section1464">
        <div class="width100">
            <div class="title-section-2 color-061F45 font40 poppins-semibold mrgntop20" [innerHTML]="'we are not just another social network' | translate">
            </div><br />
            <div class="title-section-2 color-061F45 font40 poppins-semibold" [innerHTML]="'we are a total business process solution' | translate">
            </div>
            <div class="padtop15 reg-txt letterSpacingP36px  poppins-regular color-061F45 font15 lineHeight24px">
                {{ 'yes briind is unique briind integrates and harmonizes business functions with the social media needs of member based organizations member based organizations and businesses have two important components to manage member management and business management
                which are unique and critical so their operations quite often demand more than half a dozen different applications for managing their business functions and membership this complexity increases when the solutions they use are standalone
                or not connected and have dissimilar workflows reports and behaviors and because of their independency they are not capable to generate an integrated view and report' | translate}}
            </div>
            <div class="flex padtop80 flexWrap">
                <div class="social-net-column flexAlignItemsCenter flexNoShrink" *ngFor="let item of socialNetworkList">
                    <div>
                        <i class="icns {{item.icon}}"></i>
                    </div>
                    <div class="socia-net-title font22 poppins-semibold lineHeight30px color-061F45">{{item?.title | translate}}
                    </div>
                    <div class="socia-net-text lineHeight24px font16 poppins-regular padbtm30">{{item?.description | translate}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- second section end here -->

    <!-- third section start here -->
    <div class="padtop100 desktopVisible"></div>
    <div class="section-2 width88 padbtm15 width100 posRelative section1464">
        <div class="width100 flex flexWrap">
            <div class="width67">
                <div class="title-section-2 color-061F45 font40 width80 poppins-semibold mrgntop20" [innerHTML]="'manage your organization and your social media' | translate"></div>
                <div class="color-RGBA631698 letterSpacingP36px  poppins-regular color-061F45 font15 lineHeight24px">
                    <p>
                        {{ 'to efficiently manage your organization and your personal social media involvements briind has two kinds of accounts personal account and oganizational account one for people like you and me and the other one is for member based for profit and non
                        profit organizations you can leverage from both accounts to grow your organization network and personal social involvement' | translate}}
                    </p>
                </div>
                <div class="padbtm15">
                    <button (click)="signInCTA();" class="btn-get-started btn-02457A font16 ripple poppins-medium cursorPtr">
            {{'get started' | translate}}
            <i class="icns d2410 icon-arrow-right white padleft5"></i>
          </button>
                </div>
            </div>
            <div class="width33 posRelative">
                <img src="assets/images/pattern.png" class="pattern-2 posAbsolute desktopVisible">
                <img src="assets/images/brand-image.png" class="width100 desktopVisible">
            </div>
        </div>
    </div>
    <div class="padtop100 desktopVisible"></div>
    <div class="section-2 width88 padbtm15 width100 posRelative section1464">
        <div class="width100 flex flexWrap">
            <div class="width33 posRelative">
                <img src="assets/images/pattern.png" class="pattern-3 posAbsolute desktopVisible">
                <img src="assets/images/image.png" class="width100 desktopVisible">
            </div>
            <div class="pad30 desktopVisible"></div>
            <div class="width60">
                <div class="title-section-2 color-061F45 font40 width80 poppins-semibold mrgntop20 mrgnbtm20" [innerHTML]="'features you will love' | translate"></div>
                <div class="color-RGBA631698 letterSpacingP36px  poppins-regular color-061F45 font15 lineHeight24px">
                    <!-- <p>
            {{'office tools and workplace experience under one umbrella discover the best talent and vendors know people better' | translate}}
          </p> -->
                </div>
                <div>
                    <div class="width100 flex padbtm15 mrgnbtm30" *ngFor="let item of featuresList">
                        <i class="icns {{item?.icon}} home-features flexNoShrink"></i>
                        <div class="pad10"></div>
                        <div class="flex flexDirectionCol flexJustifyCenter">
                            <div class="icon-title font22 lineHeight30px color-061F45 poppins-medium">{{item.title | translate}}</div>
                            <div class="ico-text font15 lineHeight24px letterSpacingP36px color-RGBA631698 poppins-light">
                                {{item.description | translate}}</div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <div class="width100 padbtm10">
            <img src="assets/images/feature-mob.png" class="width100 mobiVisible">
        </div>
        <div class="padbtm15 txtCenter">
            <button class="btn-get-started mrgauto font16 btn-02457A ripple poppins-medium cursorPtr" [routerLink]="'/users/auth/signup'">
        {{'know more' | translate}}
        <i class="icns d2410 icon-arrow-right white padleft5"></i>
      </button>
        </div>
    </div>
    <!-- third section start here -->

    <!-- Fourth section start here -->
    <div class="padtop100 desktopVisible"></div>

    <div class="bg-02457A section1464">
        <div class="section-2 width88 mrgnauto posRelative">
            <div class="padtop15 padbtm15 flex flexWrap flexJustifyBetween">
                <!-- card start here -->
                <div class="width33 pad15 briind-home-card flex1" *ngFor="let item of cardList">
                    <div class="bg-white pad30 bdrRadius20px height100p bdr-1-707070 briind-user-card">
                        <div class="txtCenter">
                            <i class="icns home-brind-user {{item.icon}}"></i>
                        </div>
                        <div class="card-title font24 fontWeight500 color-061F45 padtop20 padbtm20 poppins-medium txtCenter">
                            {{item.title | translate}}
                        </div>
                        <div class="txtCenter card-text font18 color-333456 lineHeight24px poppins-regular">
                            {{item.description | translate}}
                        </div>
                        <div class="padtop30 padbtm20 txtCenter">
                            <button class="btn-get-started mrgauto font16 btn-02457A ripple poppins-medium cursorPtr" [routerLink]="item.link">
                {{ item.actionText | translate}}
                <i class="icns d2410 icon-arrow-right white padleft5"></i>
              </button>
                        </div>
                    </div>
                </div>
                <!-- card end here -->
            </div>
        </div>
    </div>
    <div class="pad40 bg-02457A mrgntopM5 section1464 desktopVisible"></div>
    <div class="skew-bg bg-02457A width100 section1464"></div>
    <!-- fourth section end here -->

    <!-- fifth section start here -->
    <div class="padtop100 desktopVisible"></div>
    <div class="padtop40 mobiVisible"></div>
    <div class="section-2 width88 padbtm15 width100 posRelative section1464 mrgnbtm20">
        <div class="width100 flex flexWrap padbtm30">
            <div class="width67">
                <div class="title-section-2 color-061F45 font40 width80 poppins-semibold mrgntop20" [innerHTML]="'why join as a partner' | translate"></div>
                <div class="color-RGBA631698 width80 letterSpacingP36px  poppins-regular color-061F45 font15 lineHeight24px">
                    <p>
                        {{'office tools and workplace experience under one umbrella discover the best talent and vendors know people better' | translate}}
                    </p>
                </div>
                <div class="width80">
                    <div class="width100 flex padbtm15" *ngFor="let item of featuresList">
                        <i class="icns {{item?.icon}} home-features flexNoShrink"></i>
                        <div class="pad10"></div>
                        <div class="flex flexDirectionCol flexJustifyCenter">
                            <div class="icon-title font22 lineHeight30px color-061F45 poppins-medium">{{item.title | translate}}</div>
                            <div class="ico-text font15 lineHeight24px letterSpacingP36px color-RGBA631698 poppins-light">
                                {{item.description | translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="width100 padbtm10">
                    <img src="assets/images/feature-mob.png" class="width100 mobiVisible">
                </div>
                <div class="padbtm15 txtCenter">
                    <button class="btn-get-started btn-02457A font16 ripple poppins-medium cursorPtr" [routerLink]="'/organizations/auth/signup'">
            {{'know more' | translate}}
            <i class="icns d2410 icon-arrow-right white padleft5"></i>
          </button>
                </div>

            </div>
            <div class="width33 posRelative">
                <img src="assets/images/pattern.png" class="pattern-2 posAbsolute desktopVisible">
                <img src="assets/images/img4.png" class="width100 desktopVisible">
            </div>
        </div>
    </div>
    <div class="posRelative">
        <div class="pad40 bg-white section1464 desktopVisible"></div>
        <div class="skew-bg bg-02457A width100 section1464"></div>
    </div>

    <!-- fifth section end here -->

    <!-- sixt section start here -->
    <div class=" bg-02457A section1464 mrgntopM80">
        <div class="section-2 width88 padbtm40 padtop40 posRelative mrgnauto">
            <div class="width100 flex flexResponsive">
                <div class="width50">
                    <div class="contact-title font40 fontWeight500 color-EFEFEF txtLeft lineHeight50px poppins-medium">
                        {{'get in touch' | translate}}</div>
                    <div class="contact-text font18 lineHeight24px fontWeight300 poppins-regular mrgnbtm30 color-FFFFFF letterSpacingNormal">
                        Lorem Ipsum is simply dummy text of the </div>
                    <div class="width100 flex padbtm15" *ngFor="let item of getInTouchList">
                        <i class="icns {{item?.icon}} home-features flexNoShrink"></i>
                        <div class="pad10"></div>
                        <div class="flex flexDirectionCol flexJustifyCenter">
                            <div class="icon-title font22 lineHeight30px color-FFFFFF poppins-medium">{{item.title | translate}}</div>
                            <div class="ico-text font15 lineHeight24px letterSpacingP36px color-FFFFFF poppins-light">
                                {{item.description | translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="pad10"></div>
                <div class="width50">
                    <form [formGroup]="form" (submit)="onSubmit()">
                        <div class="bdrRadius5px pad50 bg-white flex flexWrap">
                            <div class="width100 flex flexResponsive ">
                                <div class="width50">
                                    <input type="text" formControlName="first_name" placeholder="{{'first name' | translate}}" class="card-input" [ngClass]="{'bdr-red': (f('first_name')?.invalid && (f('first_name')?.dirty || f('first_name')?.touched))}">
                                </div>
                                <div class="pad10"></div>
                                <div class="width50">
                                    <input type="text" formControlName="last_name" placeholder="{{'last name' | translate}}" class="card-input" [ngClass]="{'bdr-red': (f('last_name')?.invalid && (f('last_name')?.dirty || f('last_name')?.touched))}">
                                </div>
                            </div>
                            <div class="pad10 width100"></div>
                            <div class="width100">
                                <input type="emal" formControlName="email" placeholder="{{'email address' | translate}}" class="card-input" [ngClass]="{'bdr-red': (f('email')?.invalid && (f('email')?.dirty || f('email')?.touched))}">
                            </div>
                            <div class="pad10 width100"></div>
                            <div class="width100">
                                <textarea formControlName="message" placeholder="{{'message' | translate}}" class="card-input txtLeft" [ngClass]="{'bdr-red': (f('message')?.invalid && (f('message')?.dirty || f('message')?.touched))}"></textarea>
                            </div>
                            <div class="pad10 width100"></div>
                            <div class="width100 txtRight">
                                <button type="submit" class="btn-get-started btn-02457A font16 ripple poppins-medium cursorPtr">
                      {{'submit' | translate}}
                      <i class="icns d2410 icon-arrow-right white padleft5"></i>
                    </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <!-- sixt section end here -->
    <app-footer></app-footer>
</section>