import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/core/services/app.service';
import { AppStore } from 'src/app/core/stores/app.store';
import * as _ from 'lodash';
import { observable } from 'mobx-angular';

@Component({
  selector: 'app-briindlet-region-cost',
  templateUrl: './briindlet-region-cost.component.html',
  styleUrls: ['./briindlet-region-cost.component.scss']
})

export class BriindletRegionCostComponent implements OnInit {
  subscription: Subscription = new Subscription();
  @Input() briindletType: string = '';
  @Input() region: string = '';
  @Input() previousStep: number = 0;
  @Output('getRegion') getRegion = new EventEmitter();
  @Output('stepperActions') stepperActions = new EventEmitter();
  
  @Input() formGrp: any;
  form: FormGroup;
  dropDownStatus: string = '';
  data: any = [
    {'id':'', 'name': ''},
  ];
  regionList: any;
  selectedCountry: any;
  selectedState: any;
  selectedData: any[] = [];
  selectedRegionInfo: any = [];
  selectedItems: any = [];
  JSON:any=JSON;
  constructor(
    public fb: FormBuilder,
    public appService: AppService,
    public appStore: AppStore
  ) {
    this.form = fb.group({
      'country': new FormControl(''),
      'state': new FormControl(''),
      'city': new FormControl(''),
    })
   }

  ngOnInit(): void {
    this.getRegions();
    if (this.previousStep == 16) {
      this.selectedRegionInfo = this.appStore.getSelectedRegionData();
      this.selectedItems = this.appStore.getSelectedItemsData();
      this.bindRegions();
    }
  }

  newRegion(): FormGroup {
    return this.fb.group({
      'country': new FormControl('', Validators.required),
      'state': new FormControl('', Validators.required),
      'city': new FormControl('', Validators.required),
    })
  }

  addRegion() {
    this.regions.push(this.newRegion())
  }
 
  get regions() : FormArray {
    return this.formGrp.get('step_15').get("regions") as FormArray
  }

  /**
   * @description
   * this method is used for getting region list
   * 
   * @memberof BriindletRegionCostComponent
   */
  getRegions() {
    this.subscription.add(
      this.appService.getRegions().subscribe((response: any) => {
        this.regionList = response;
        // if (this.formGrp['controls']['step_15']['controls']['regions']['value']) {
        //   for (const item of this.formGrp['controls']['step_15']['controls']['regions']['value']) {
        //     switch (true) {
        //       case item.city != '':
        //         _.filter( this.regionList, function(region) {
        //             return region.id === item.id;
        //         });
              
        //         break;
            
        //       default:
        //         break;
        //     }
        //   }
        // }
        
      })
    );
  }

  actionCTA(event : any) {
    this.stepperActions.emit(event);
  }

  /**
   * @description
   * this method is used for getting selected value from dropdown
   *
   * @param {*} [event]
   * @memberof BriindletRegionCostComponent
   */
   selectedCountryDropdown(event ?: any) {
    this.selectedCountry = event;  
    this.selectedData.push(event);
  }

  /**
   * @description
   * this method is used for getting selected value from dropdown
   *
   * @param {*} [event]
   * @memberof BriindletRegionCostComponent
   */
   selectedStateDropdown(event ?: any) {
    this.selectedState = event;  
    this.selectedData.push(event);
  }

  /**
   * @description
   * this method is used for getting selected value from dropdown
   *
   * @param {*} [event]
   * @memberof BriindletRegionCostComponent
   */
   selectedCityDropdown(event ?: any) {
    this.selectedData.push(event);
  }

  cancelRegion(region: any, index: any) {
    this.regions.removeAt(index);
    this.selectedItems.splice(index, 1);
    this.selectedRegionInfo.splice(index, 1);
  }

  bindRegions(){
    for (let index = 0; index < this.selectedItems.length; index++) {
      if (! this.formGrp['controls']['step_15']['controls']['regions']['controls'][index]) {
        this.addRegion()
      }
      this.formGrp['controls']['step_15']['controls']['regions']['controls'][index].patchValue(this.selectedItems[index])
    }
  }

  addRegions(type : string) {    if (this.selectedData.length) {
      let param: any = {}
      this.selectedData.forEach((item: any, index: any) => {
        switch (index) {
          case 0:
            param['country'] = item.id
            break;
          case 1:
            param['state'] = item.id
            break;
          case 2:
            param['city'] = item.id
            break;
          default:
            break;
        }
      });
      this.dropDownStatus = param['city']  ||  param['state'] || param['country'];
      this.selectedRegionInfo.push(this.selectedData[this.selectedData.length - 1])
      
      this.selectedItems.push(param)
      for (let index = 0; index < this.selectedItems.length; index++) {
        if (! this.formGrp['controls']['step_15']['controls']['regions']['controls'][index]) {
          this.addRegion()
        }
        this.formGrp['controls']['step_15']['controls']['regions']['controls'][index].patchValue(this.selectedItems[index])
      }      
      this.appStore.setSelectedRegionData(this.selectedRegionInfo,this.selectedItems);

      if (type == 'normal') {
        this.getRegion.emit( {selectedItemsIDs: this.selectedItems, selectedData: this.selectedData[this.selectedData.length - 1]})
      }
      this.selectedData = []; 
    }   
  }

  /**
   * @description
   * this method is used for emitting region data
   *
   * @memberof BriindletRegionCostComponent
   */
  onAdd() {
    // this.getRegion.emit(this.selectedData[this.selectedData.length - 1]);
  }
}
