import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/core/services/app.service';
import { OrganizationService } from 'src/app/organizations/service/organization.service';
import { OrganizationStoreService } from 'src/app/organizations/store/organization-store.service';

@Component({
  selector: 'app-trending-organization-card',
  templateUrl: './trending-organization-card.component.html',
  styleUrls: ['./trending-organization-card.component.scss']
})
export class TrendingOrganizationCardComponent implements OnInit, OnChanges {
  subscription: Subscription = new Subscription();
  @Input() organization: any;
  @Input('userType') userType: any;

  organizationFollowed: boolean = false;
  constructor(
    public appService: AppService,
    public organizationStoreService: OrganizationStoreService,
    private organizationService: OrganizationService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes && changes.organization.currentValue) {
      if (this.organization.is_followed) {
        this.organizationFollowed = true;
      }
    }
  }

  /**
   * @description
   * method is user for follow the organization
   * 
   * @param {number} id 
   * @param {string} name  
   * @memberof TrendingOrganizationCardComponent
   */
  followOrganization(id: number, name: string) {
    let data = {
      'status': 'following',
      'organization': id
    }
    this.subscription.add(
      this.organizationService.followOrganization(data).subscribe((response: any) => {
        this.organizationFollowed = true;
        this.organization.is_followed = true;
        if(response.status.toLowerCase() == "success") {
          this.appService.toggleToast({
            type: 'success',
            load: true,
            position: 'top-center',
            context: { description: `${name} ${response.message} !` }
          });
        }
      })
    )
  }

  // getFollowersList() {
  //   this.subscription.add(
  //     this.organizationService.getOrganizationFollowersList().subscribe((response: any) => {
  //       console.log(JSON.stringify(response))
  //     })
  //   )
  // }

  unfollowOrganization(id:number,name: string){
    let data = {
      'organization': id
    }
    this.subscription.add(
      this.organizationService.unfollowOrganization(data).subscribe((response: any) => {
        this.organizationFollowed = false;
        this.organization.is_followed = false;
        if(response.status.toLowerCase() == "success") {
          this.appService.toggleToast({
            type: 'success',
            load: true,
            position: 'top-center',
            context: { description: `${name} ${response.message} !` }
          });
        }
      })
    ) 
  }
}
