<div>
    <div class=" flex">
        <div class="briindlet-banner flex cursorPtr width100">
            <div class="posRelative width100">
                <div class="card_image posRelative">
                    <app-simple-carousel [autoSlide]="'false'" *ngIf="data?.photos?.length">
                        <ng-container *ngFor="let item of data?.photos">
                            <ng-container *carouselItem>
                                <img [src]="item?.base64 || item?.photo"
                                    class="width100 maxHeight300px minHeight300px bdrRadius6px boxShadow-RGBA00005 valignMiddle " />
                            </ng-container>
                        </ng-container>
                    </app-simple-carousel>
                    <img class="width100 minHeight300px maxHeight300px bdrRadius6px boxShadow-RGBA00005 valignMiddle"
                        *ngIf="!data?.photos?.length && isPreview" src="assets/images/briindlet-preview.png">
                    <div class="opacityp8 bg-02457A posBtm posLeft30px posAbsolute mrgnauto pad10 mrgn10"
                        style="width: 90%;" style="width: 90%;">
                        <h3 class="{{customizeUI?.event_title?.title.font_family}} txtCenter mrgnbtm10 mrgn0 padbtm5"
                            [ngStyle]="{'font-size.px': customizeUI?.event_title?.title.font, 'color': customizeUI?.event_title?.title?.color}">
                            {{(data?.name ? data?.name : isPreview ? 'Briind Event' : '') | translate}}
                        </h3>
                        <div class="width100 mrgnbtm10 flex flexResponsive flexJustifyCenter">
                            <div class="flex mrgnright15 flexAlignItemsCenter"
                                *ngIf=" data?.venue || data?.venue_name || isPreview">
                                <i class="icon-map white icns d20"></i>
                                <p class="mrgn0 {{customizeUI?.venue_and_time?.venue?.font_family}} mrgnleft10"
                                    [ngStyle]="{'font-size.px': customizeUI?.venue_and_time?.venue?.font, 'color': customizeUI?.venue_and_time?.venue?.color}">
                                    {{((data?.venue || data?.venue_name) ? (data?.venue?.name || data?.venue_name || data.venue) : isPreview ? 'At Briind' : '') | translate}}
                                </p>
                            </div>
                            <div class="flex flexAlignItemsCenter" *ngIf="data?.registration_last_date">
                                <i class="icon-time white icns d20"></i>
                                <p class="mrgn0 {{customizeUI?.date?.date?.font_family}}  mrgnleft10"
                                    [ngStyle]="{'font-size.px': customizeUI?.date?.date?.font, 'color': customizeUI?.date?.date?.color}">
                                    {{'registration last date' | translate: {date: (data?.registration_last_date | minimalDate) } }}
                                </p>
                            </div>
                        </div>
                        <p *ngIf="data?.event_from"
                            class="card_text mrgn0 padbtm5 txtCenter padbtm5 font16 {{customizeUI?.venue_and_time?.time?.font_family}}"
                            [ngStyle]="{'font-size.px': customizeUI?.venue_and_time?.time?.font, 'color': customizeUI?.venue_and_time?.time?.color}">
                            {{data?.event_from | fullDate}}
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- end banner  -->

    <div class="bg-F7F7F7 padleft15 padright15">
        <!-- start desc  -->
        <div class=" section660 padtop20  txtCenter txtCenter {{customizeUI?.event_title?.description?.font_family}} "
            *ngIf="data?.description"
            [ngStyle]="{'font-size.px': customizeUI?.event_title?.description?.font, 'color': customizeUI?.event_title?.description?.color}">
            {{(data?.description ? data?.description : isPreview ? 'lorem ipsum' : '') | translate}}
        </div>
        <!-- end desc -->
        <!-- start guest  -->
        <div class="section660 padtop20">
            <ng-container *ngIf="data?.guest?.length  || isPreview">

                <h3 class="  mrgn0 mrgnbtm10 txtCenter {{customizeUI?.guest_speakers?.speakers_title?.font_family}}"
                    [ngStyle]="{'font-size.px': customizeUI?.guest_speakers?.speakers_title?.font, 'color': customizeUI?.guest_speakers?.speakers_title?.color}">
                    {{ 'guest_speakers' | translate}}
                </h3>
                <div class=" flex flexResponsive flexJustifyCenter">
                    <ng-container *ngIf=" data?.guest?.length && !isPreview">
                        <div *ngFor="let item of data?.guest"
                            class="width30 bdrRadius6px boxShadow-RGBA00005 mrgnright10 mrgntop10 mrgnbtm10 bdr-1-EEEEEE bg-white pad15 flex flexDirectionCol flexJustifyCenter flexAlignItemsCenter width100">
                            <app-profile-thumbnail [imageClass]="'d55'" [image]="item.photo"></app-profile-thumbnail>
                            <div class="pad5"></div>
                            <div class="{{customizeUI?.guest_speakers?.speakers_name?.font_family}} txtTransformCaps"
                                [ngStyle]="{'font-size.px': customizeUI?.guest_speakers?.speakers_name?.font, 'color': customizeUI?.guest_speakers?.speakers_name?.color}">
                                {{ item.name }}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isPreview">
                        <div *ngFor="let item of briindGuest"
                            class="width30 bdrRadius6px boxShadow-RGBA00005 mrgnright10 mrgntop10 mrgnbtm10 bdr-1-EEEEEE bg-white pad15 flex flexDirectionCol flexJustifyCenter flexAlignItemsCenter width100">
                            <app-profile-thumbnail [imageClass]="'d55'" [image]="item.photo"></app-profile-thumbnail>
                            <div class="pad5"></div>
                            <div class="{{customizeUI?.guest_speakers?.speakers_name?.font_family}} txtTransformCaps"
                                [ngStyle]="{'font-size.px': customizeUI?.guest_speakers?.speakers_name?.font, 'color': customizeUI?.guest_speakers?.speakers_name?.color}">
                                {{ item.name }}
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <!-- end guest -->

        <div class="section660 pad15 txtCenter " *ngIf="data?.who_can_attend">
            <h3 class="  mrgn0 txtCenter {{customizeUI?.guest_speakers?.who_can_attend_title?.font_family}}"
                [ngStyle]="{'font-size.px': customizeUI?.guest_speakers?.who_can_attend_title?.font, 'color': customizeUI?.guest_speakers?.who_can_attend_title?.color}">
                {{ 'who can attend' | translate}}
            </h3>
            <p class="txtCenter {{customizeUI?.guest_speakers?.who_can_attend_description?.font_family}}"
                [ngStyle]="{'font-size.px': customizeUI?.guest_speakers?.who_can_attend_description?.font, 'color': customizeUI?.guest_speakers?.who_can_attend_description?.color}">
                {{(data?.who_can_attend ? data?.who_can_attend : isPreview ? 'lorem ipsum' : '') | translate}}
            </p>
        </div>

        <div class="section660 pad15 txtCenter bg-white boxShadow-RGBA00005 bdr-1-EEEEEE bdrRadius6px "
            *ngIf="data?.registration_guidelines">
            <h3 class="  mrgn0 txtCenter {{customizeUI?.registration_guideline?.title?.font_family}}"
                [ngStyle]="{'font-size.px': customizeUI?.registration_guideline?.title?.font, 'color': customizeUI?.registration_guideline?.title?.color}">
                {{ 'registration guidlines' | translate}}
            </h3>
            <p class="txtCenter font16 color-122A4D poppins-regular {{customizeUI?.registration_guideline?.description?.font_family}}"
                [ngStyle]="{'font-size.px': customizeUI?.registration_guideline?.description?.font, 'color': customizeUI?.registration_guideline?.description?.color}">
                {{(data?.registration_guidelines ? data?.registration_guidelines : isPreview ? 'lorem ipsum' : '') | translate}}
            </p>
        </div>



        <div class="  txtCenter mrgntop10 font20 fontWeight600" *ngIf="data?.fee">
            <span class="{{customizeUI?.registration_fee?.title?.font_family}}"
                [ngStyle]="{'font-size.px': customizeUI?.registration_fee?.title?.font, 'color': customizeUI?.registration_fee?.title?.color}">
                {{ 'registration fee:' | translate }}
            </span>
            <span class="{{customizeUI?.registration_fee?.value?.font_family}}"
                [ngStyle]="{'font-size.px': customizeUI?.registration_fee?.value?.font, 'color': customizeUI?.registration_fee?.value?.color}">
                {{'amount in number' | translate: {amount: data?.fee || 0} }}
            </span>
        </div>
        <div class="txtCenter" *ngIf="data?.registration_last_date">
            <p class="{{customizeUI?.date?.date?.font_family}}"
                [ngStyle]="{'font-size.px': customizeUI?.date?.date?.font, 'color': customizeUI?.date?.date?.color}">
                {{'last date of registration:' | translate}} {{ data?.registration_last_date | minimalDate}}
            </p>
        </div>

        <div class="pad10" *ngIf="countdownTimer">
            <div class="section420">
                <div
                    class="btn-user-default pad10 mrgnbtm20 txtCenter fontWeight500 font14 bdrRadius4px poppins-regular color-white">
                    {{'time' | translate}} {{countdownTimer}}
                </div>
            </div>
        </div>

    </div>
    <div class="padtop10 bg-F7F7F7 flex">
        <div class="flex1 bdr-1-EEEEEE" *ngIf="data?.organization_link">
            <button  (click)="saveBriindletViewProfile(data?.organization_link, 'organization_link')"
                class="btn-get-started height45px ripple cursorPtr bdrRadius0 pad10 txtCenter width100  btn-get-started"
                class="{{customizeUI?.button_customization?.view_profile?.font_family}}"
                [ngStyle]="{'font-size.px': customizeUI?.button_customization?.view_profile?.font, 'background-color': customizeUI?.button_customization?.view_profile?.background_color , 'color': customizeUI?.button_customization?.view_profile?.color}">
                {{customizeUI?.button_customization?.view_profile?.button_name | translate}}
            </button>
        </div>
        <div class="flex1 bdr-1-EEEEEE" *ngIf="data?.cause">
            <button (click)="saveBriindletViewProfile(data?.cause, 'cause')"
                class="btn-get-started height45px ripple cursorPtr bdrRadius0 pad10 txtCenter width100  btn-get-started"
                class="{{customizeUI?.button_customization?.donate_now?.font_family}}"
                [ngStyle]="{'font-size.px': customizeUI?.button_customization?.donate_now?.font, 'background-color': customizeUI?.button_customization?.donate_now?.background_color , 'color': customizeUI?.button_customization?.donate_now?.color}">
                {{customizeUI?.button_customization?.donate_now?.button_name | translate}}
            </button>
        </div>
        <div class="flex1 bdr-1-EEEEEE" *ngIf="data?.website_link">
            <button (click)="saveBriindletRegister(data?.website_link, 'website_link')"
                class="btn-get-started height45px ripple cursorPtr bdrRadius0 pad10 txtCenter width100  btn-get-started"
                class="{{customizeUI?.button_customization?.website?.font_family}}"
                [ngStyle]="{'font-size.px': customizeUI?.button_customization?.website?.font, 'background-color': customizeUI?.button_customization?.website?.background_color , 'color': customizeUI?.button_customization?.website?.color}">
                {{customizeUI?.button_customization?.website?.button_name | translate}}
            </button>
        </div>
    </div>
</div>