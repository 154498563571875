/**
 * @description
 * This Object using for Configure the site
 */
export const essentials: any = {
  title: "Briind",
  themes: [
    "default",
  ],
  compatibleBrowsers: { CHROME: 70, FIREFOX: 55, EDGE: 13, IE: 10, SAFARI: 10 },
  apiURI: "/api/",
  usersURI: "users/",
  userURI: "user/",
  sharedURI: "shared/",
  organizationsURI: "organizations/",
  forgotPasswordURI: "forgot_password/",
  verifyOtpURI: "verify_otp/",
  resendOtpURI: "resend_otp/",
  updatePasswordURI: "update_password/",
  socialLoginURI: "social_login/",
  loginURI: "login/",
  signupURI: "signup/",
  countriesURI: "countries/",
  statesURI: "states/",
  checkBriindAvailabiltyURI: "check_briind_availability/",
  personalInformationsURI: "personal_informations/",
  createInfoURI: "create_info/",
  userDetailURI: "user_detail/",
  organizationDetailURI: "organization_detail/",
  userAlbumsListURI: "user_albums_list/",
  organizationAlbumsListURI: "organization_albums_list/",
  contactInformationURI: "contact_information/",
  allMembersURI: "all_members/",
  detailsURI: "details/",
  highSchoolsURI: "high_schools/",
  universitiesURI: "universities/",
  degreeURI: "degree/",
  majorURI: "major/",
  worksURI: "works/",
  skillsURI: "skills/",
  interestsURI: "interests/",
  citiesURI: "cities/",
  placesLivedURI: "places_lived/",
  moviesURI: "movies/",
  musicsURI: "musics/",
  religiousViewsURI: "religious_views/",
  politicalViewsURI: "political_views/",
  sportsURI: "sports/",
  generalInterestsURI: "general_interests/",
  interestListURI: "interest_list/",
  socialProfileURI: "social_profile/",
  profileDetailURI: "profile_detail/",
  milestonesURI: "milestones/",
  milestoneListURI: "milestone_list/",
  servicesURI: "services/",
  serviceListURI: "service_list/",
  updateServicesOfferedURI: "update_services_offered/",
  socialMediasURI: "social_medias/",
  leadersURI: "leaders/",
  branchesURI: "branches/",
  invitesListURI: "invites_list/",
  inviteURI: "invite/",
  ministriesURI: "ministries/",
  createMinistryURI: "create_ministry/",
  ministryListURI: "ministry_list/",
  newToUsURI: "new_to_us/",
  createURI: "create/",
  businessProfileURI: "business_profile/",
  membershipFormURI: "membership_form/",
  bankAccountsURI: "bank_accounts/",
  causesURI: "causes/",
  postsURI: "posts/",
  likesURI: "likes/",
  postDetailURI: "post_detail/",
  photoDetailURI: "photo_detail/",
  ministryActivitiesURI: "ministry_activities/",
  videoDetailURI: "video_detail/",
  createLikeURI: "create_like/",
  groupsURI: "groups/",
  groupURI: "group/",
  membershipGroupsURI: "membership_groups/",
  groupCategoryURI: "group_categories/",
  helpsURI: "helps/",
  photosURI: "photos/",
  videosURI: "videos/",
  userInviteURI: "user_invite/",
  myPostsURI: "my_posts/",
  organizationPostsURI: "organization_posts/",
  organizationAlbumsURI: "organization_albums/",
  userPostsURI: "user_posts/",
  userAlbumsURI: "user_albums/",
  organizationPhotosURI: "organization_photos/",
  organizationVideosURI: "organization_videos/",
  userPhotosURI: "user_photos/",
  userVideosURI: "user_videos/",
  organizationInviteURI: "organization_invite/",
  postsListURI: "posts_list/",
  overviewURI: "overview/",
  connectionsURI: "connections/",
  cancelURI: "cancel/",
  suggestedUsersURI: "suggested_users/",
  pendingRequestsURI: "pending_requests/",
  acceptURI: "accept/",
  rejectURI: "reject/",
  friendsURI: "friends/",
  sentListURI: "sent_list/",
  friendRequestsURI: "friend-requests/",
  notifyAgainURI: "notify_again/",
  unFriendURI: "unfriend/",
  blockURI: "block/",
  recentlyAddedFriendsURI: "recently_added_friends/",
  highschoolFriendsURI: "high_school_friends/",
  connectedFriendsURI: "connected_users/",
  collegeFriendsURI: "university_friends/",
  organizationFriendsURI: "organization_friends/",
  hometownFriendsURI: "hometown_friends/",
  membershipURI: "membership/",
  createMembershipURI: "create_membership/",
  pendingListURI: "pending_list/",
  forwardURI: "forward/",
  functionalitiesURI: "functionalities/",
  rolesURI: "roles/",
  createRoleURI: "create_role/",
  sharesURI: "shares/",
  userTimelineURI: "user_timeline/",
  followersURI: "followers/",
  unfollowURI: "unfollow/",
  organizationTimelineURI: "organization_timeline/",
  postTagsURI: "post_tags/",
  homepageSuggestedUsersURI: "homepage_suggested_users/",
  commentURI: "comment/",
  mostLikedPost: "most_liked_post/",
  mostSharedPost: "most_shared_post/",
  invitedListURI: "invited_list/",
  deleteURI: "delete/",
  muteURI: "mute/",
  unmuteURI: "unmute/",
  groupListURI: "group_list/",
  eventsURI: "events/",
  inviteVolunteersURI: "invite_volunteers/",
  eventVolunteersURI: "event_volunteers/",
  volunteerFriendsURI: "volunteer_friends/",
  followFriendsURI: "follow_friends/",
  pendingListOrgURI: "pending_list_org/",
  sendInviteURI: "send_invite/",
  cancelInviteURI: "cancel_invite/",
  getFollowersURI: "get_followers/",
  getFollowedOrgURI: "get_followed_org/",
  manageURI: "manage/",
  cancelVolunteerURI: "cancel_volunteer/",
  trendingListURI: "trending_list/",
  organizationsListURI: "organizations_list/",
  organizationsNotFollowedURI: "organizations_not_followed/",
  membershipsURI: "memberships/",
  inviteListURI: "pending_list/",
  nonMembersURI: "non_members/",
  memberListURI: "member_list/",
  getUserFollowersURI: "get_user_followers/",
  groupTimelineURI: "group_timeline/",
  masterGifDatasURI: "master_gif_datas/",
  notificationsURI: "notifications/",
  searchURI: "search/",
  topNotificationsURI: "top_notifications/",
  briindletsURI: "briindlets/",
  analyticsURI: "analytics/",
  briindletAnalyticsURI: "briindlet_analytics/",
  getOrgAnalyticsURI: "get_org_analytics/",
  getUserAnalyticsURI: "get_user_analytics/",
  pageViewsURI: "page_views/",
  clickURI: "click/",
  proURI: "pro/",
  activityLogsURI: "activity_logs/",
  regionURI: "region/",
  paymentURI: "payment/",
  startPaymentURI: "start_payment/",
  briindletURI: "briindlet/",
  templatesURI: "templates/",
  venueURI: "venue/",
  briindadsURI: "briindads/",
  briindadURI: "briindad/",
  briindletProListURI: "briindletpro_list/",
  timelineURI: "timeline/",
  getMembersActivityURI: "get_members_activity/",
  getFriendsActivityURI:"get_friends_activity/",
  promotionsURI:"promotions/",
  cancelPromotionURI:"cancel_promotion/",
  priceCalcURI:"price_calc/",
  getOrganizationURI: "get_organization/",
  getOrganizationActivityURI:"get_organization_activity/",
  settingsURI: "settings/",
  generalSettingsURI: "general_settings/",
  deviceURI : "device/",
  changePasswordURI: "change_password/",
  countryListURI: "country_list/",
  updateUserSettingsURI: "update_user_settings/",
  deleteSavedCardURI: "delete_saved_card/",
  blockUserURI: "block_user/",
  addCardURI: "add_card/",
  getSavedCardsURI: "get_saved_cards/",
  combinedUsersURI: "combined_users/",
  menuCustomizationURI: "menu_customization/",
  createMenuCustomizationURI: "create_menu_customization/",
  settingsRegionsURI: "settings_regions/",
  onlineUsersURI: "online_users/",
  hashTagURI: "hashtag/top/",
  hashTagData: "hashtag/posts/",
  mostLikedSharedPostURI: "most_liked_shared_post/",
  onlineMembersURI: "online_members/",
  allUsersURI: "all_users/",
  checkOrganizationURI: "check_organization/",
  statusUpdateURI: "status_update/",
  onlineURI: "online/",
  cancelBriindletURI: "cancel_briindlet",
  changeRoleURI: "change_role/",
  removeMemberURI: "remove_member/",
  permissionsURI: "permissions/",
  bulkShareURI:"bulk_share/",
  getInTouchURI: "get-in-touch",
  inviteUsersURI: "invite_users/",
  getUserTypeURI: "get-user-type",
  notificationCountURI: "notification_count/"
}
