import { Component, Input, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { OrganizationService } from '../../../../organizations/service/organization.service';
import { OrganizationStoreService } from '../../../../organizations/store/organization-store.service';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/core/services/app.service';


@Component({
  selector: 'app-add-milestone-popup',
  templateUrl: './add-milestone-popup.component.html',
  styleUrls: ['./add-milestone-popup.component.scss']
})
export class AddMilestonePopupComponent implements OnDestroy,OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  form: FormGroup = new FormGroup({});
  selectedFile: any;
  subscription: Subscription = new Subscription();
  imgURL:any;
  submitted: boolean = false;
  constructor(
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    private organizationStoreService: OrganizationStoreService,
    private appService: AppService
  ) {}
  ngOnInit(): void {
    if(this.popup.context.data){
      this.form = this.fb.group({
        name: new FormControl(this.popup.context.data.name, [Validators.required]),
        description: new FormControl(this.popup.context.data.description, [Validators.required]),
        date:  new FormControl(this.popup.context.data.date, [Validators.required]),
      });
      this.imgURL = this.popup.context.data.cover_photo_url
      this.selectedFile = this.popup.context.data.cover_photo
    }
    else{ 
      this.form = this.fb.group({
        name: new FormControl('', [Validators.required]),
        description: new FormControl('', [Validators.required]),
        date:  new FormControl('', [Validators.required]),
      });
    }
  }
;

  /**
   * @description
   * this method is using for get controls of the form 
   *
   * @readonly
   * @memberof AddMilestonePopupComponent
   */
  get control() { return this.form.controls; }

  /**
   * @description
   * this method is using for setting formdata
   *
   * @readonly
   * @memberof AddMilestonePopupComponent
   */
  setFormData() {
    let formData = new FormData();
    if (this.selectedFile) {
      formData.append('cover_photo', this.selectedFile)
    }
    formData.append('organization', this.organizationStoreService.organizationId)
    for (var key in  this.form.value) {
      if (key == 'date') {
        formData.append(key, this.appService.formatDateForApi(this.form.value[key]) )
      } else {
        formData.append(key, this.form.value[key])
      }
      
    }
    return formData;
  }

   /**
   * @description
   * this method is using for setting banner image
   * @param {*} event
   * @readonly
   * @memberof AddMilestonePopupComponent
   */
  selectBannerImage(event ?: any) {
    this.selectedFile = event.target.files[0]
    var reader = new FileReader();
    reader.readAsDataURL(this.selectedFile); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
    }
  }

  removeFile(){
    this.selectedFile = null
    this.imgURL = null
  }

  /**
   * @description
   * this method is used for form submit
   *
   * @memberof AddMilestonePopupComponent
   */
  onSubmit() {
    if(this.form.invalid){
      this.submitted = true
      return
    } else{
      if(this.popup.context.data){
        this.subscription.add(
          this.organizationService.updateMilestone(this.setFormData(),this.popup.context.data.id).subscribe((response: any) => {
            this.appService.showToaster(response)
            this.organizationStoreService.profileDetail.milestone_info_list && this.organizationStoreService.profileDetail.milestone_info_list.length != 0 ?
              this.organizationStoreService.profileDetail.milestone_info_list[this.organizationStoreService.profileDetail.milestone_info_list.indexOf(this.organizationStoreService.profileDetail.milestone_info_list.filter(milestone => (milestone.id == response.id))[0])] = response :
              this.organizationStoreService.profileDetail.milestone_info_list = [response.result];
            this.closePopup();
          }, error => {
            this.closePopup();
          })
        );
      }
      else{ 
      this.subscription.add(
        this.organizationService.createMilestone(this.setFormData()).subscribe((response: any) => {
          this.appService.showToaster(response)
          this.organizationStoreService.profileDetail.milestone_info_list && this.organizationStoreService.profileDetail.milestone_info_list.length !=0  ? this.organizationStoreService.profileDetail.milestone_info_list.unshift(response.result) : this.organizationStoreService.profileDetail.milestone_info_list = [response.result];
          this.closePopup();
        }, error => {
          this.closePopup();
        })
      );
      }
    }  
  }

  /**
  * @description
  * Click on close button this method will call
  *
  * @memberof AddMilestonePopupComponent
  */
  closePopup() {
    this.close.emit('icon');
  };
  
  /**
   * @description
   * Called once, before the instance is destroyed.
   * Add 'implements OnDestroy' to the class.
   * @memberof AddMilestonePopupComponent
   */
  ngOnDestroy(): void {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
