import { UserModel } from './user.model';
export class OrganizationModel {
    id: number = 0;
    name?: string = '';
    assumed_name?: string = '';
    is_active?: boolean = false;
    date_joined?: string = '';
    last_login?: string = '';
    email?: string = '';
    briind_id?: string = '';
    countries?: any = [];
    profile_pic?: string = '';
    social_profile_completion?: any = 0;
    cover_photo?: string = '';
    user_type?: string = '';
    country_updated?: boolean = false;
    business_profile_completion?: any = 0;
    social_profile?: OrganizationSocialProfileModel = new OrganizationSocialProfileModel();
    basic_social_info: BasicSocialInfoModel = new BasicSocialInfoModel();
    milestone_info_list: OrganizationMilestoneModel[] = [];
    services_info_list: OrganizationServicesModel[] = [];
    social_media_info: OrganizationSocialMedia = new OrganizationSocialMedia();
    leaders_list_info: OrganizationLeadersModel[] = [];
    overview: OrganizationOverviewModel = new OrganizationOverviewModel();
    is_promoted: boolean = false;
    settings?: SettingModel = new SettingModel();
    has_membership_form?: boolean = false;
    is_member?: boolean = false;
    member_status?: string = '';
    is_own_profile?: boolean = false;
}

export class OrganizationOverviewModel {
    overview?: any = [];
    city?: string = '';
    country?: string = '';
    door_no?: string = '';
    email?: string = '';
    established_on?: string = '';
    phone_number?: string = '';
    state?: string = '';
    street?: string = '';
    website?: string = '';
    zip_code?: string = '';
    members?: number = 0;
    followers?: number = 0;
    leaders_list_info: OrganizationLeadersModel[] = [];
    services_info_list: OrganizationServicesModel[] = [];
}
export class OrganizationServicesModel {
    id?: number = 0;
    name?: string = '';
    is_active?: boolean = false;
    organization?: number = 0;
}

export class OrganizationSocialProfileModel {
    short_bio?: string = '';
    id?: number = 0;
    organization?: number = 0;
}


export class OrganizationSocialMedia {
    facebook_link?: string = '';
    id?: number = 0;
    instagram_link?: string = '';
    website_link?: string = '';
    youtube_link?: string = '';
    twitter_link?: string = '';
    other_link?: string = '';
}

export class OrganizationMilestoneModel {
    id?: number = 0;
    name?: string = '';
    description?: string = '';
    date?: string = '';
    cover_photo?: string = '';
    organization?: number;
}

export class BasicSocialInfoModel {
    registered_name?: string = '';
    assumed_name?: string = '';
    display_name?: string = '';
    custom_name?: string = '';
    email_name?: string = '';
    door_no?: string = '';
    street?: string = '';
    city?: string = '';
    state?: string = '';
    country?: string = '';
    zip_code?: string = '';
    phone_number?: string = '';
    established_on?: string = '';
    website?: string = '';
    address?: string = '';
    city_obj?: any;
    country_obj?: any;
    state_obj?: any;
}

export class OrganizationLeadersModel {
    briind_id?: string = '';
    email?: string = '';
    first_name?: string = '';
    id?: number = 0;
    mobile_number?: string = '';
    organization?: number = 0;
    profile_pic?: string = '';
    role?: string = '';
    status?: string = '';
    user?: number = 0;
}

export class BussinessProfileModel {
    branch_invite_list: OrganizationBranchInvitesListModel[] = [];
    ministries_list: OrganizationMinistriesModel[] = [];
    new_to_us_info: newToUsDetailModel = new newToUsDetailModel();
    about_info: AboutUsModel = new AboutUsModel();
    member_ship_template: MembershipTemplateModel = new MembershipTemplateModel();
    give_info: GiveModel = new GiveModel();
}


export class GiveModel {
    account_list?: AccountListModel[] = [];
    causes_list?: any[] = [];
}

export class CausesListModel {
    bank_account_id?: number = 0;
    description?: string = '';
    organization_id?: number = 0;
    photos: any[] = [];
    title?: string = '';
    web_link?: string = '';
}

export class AccountListModel {
    account_name?: string = '';
    account_number?: string = '';
    account_type?: string = '';
    bank_name?: string = '';
    confirmation?: boolean = false;
    id?: number = 0;
    organization?: number = 0;
    routing_number?: string = '';
}

export class MembershipTemplateModel {
    field_settings: FieldSettingsModel = new FieldSettingsModel();
    id?: number = 0;
    template_id?: number = 0;
    name?: string = '';
    profile_pic?: any = '';
}

export class FieldSettingsModel {
    fields: FieldsModel[] = [];
    name?: string = '';
    template: TemplateModel = new TemplateModel();
}

export class FieldsModel {
    field_name?: string = '';
    field_type?: string = '';
    icon?: string = '';
    id?: number = 0;
    type?: number = 0;
}


export class TemplateModel {
    id?: number = 0;
    isSelected: boolean = false
    name?: string = '';
    thumbnail?: string = '';
}
export class OrganizationBranchInvitesListModel {
    assumed_name?: string = '';
    branch_invite_id?: number = 0;
    invite_status?: string = '';
    name?: string = '';
    profile_pic?: string = '';
}

export class newToUsDetailModel {
    has_membership_form?: boolean = false;
    id?: number = 0;
    introduction?: string = '';
    membership_button?: string = '';
    organization?: number = 0;
    photo?: string = '';
    template_id?: number = 0;
    welcome_screen?: string = '';
    points?: number = 0;
    show_join?: boolean = false;
}

export class OrganizationMinistriesModel {
    activity?: OrganizationActivityModel = new OrganizationActivityModel();
    description?: string = '';
    id?: number = 0;
    ministry_leaders?: any[] = [];
    photo_url?: string = '';
    title?: string = '';
}

export class OrganizationActivityModel {
    date?: string = '';
    description?: string = '';
    id?: number = 0;
    photos: OrganizationPhotoModel[] = [];
}

export class OrganizationPhotoModel {
    id?: number = 0;
    name?: string = '';
    photo_url?: string = '';
}

export class AboutUsModel {
    founder_leader?: UserModel[] = [];
    history_of_partner?: string = '';
    id?: number = 0;
    mission_statement?: string = '';
    organization?: number = 0;
    photo?: string = '';
    small_description?: string = '';
    staffs?: UserModel[] = [];
    team_leaders?: UserModel[] = [];
    vision_statement?: string = '';
    points?: number = 0;

}

export class InvitesOrganizationModel {
    group?: number = 0;
    id?: number = 0;
    invited_at?: string = '';
    member_from_date?: string = '';
    organization?: OrganizationModel[] = [];
    role?: string = '';
    status?: string = '';
}

export class OrganizationFollowersModel {
    organization?: number = 0;
    status?: string = '';
    user_data?: UserModel[] = [];
}

export class OrganizationCauses {
    bank_account_id?: number = 0;
    description?: string = '';
    id?: number = 0;
    organization_id?: number = 0;
    photos?: any[] = [];
    title?: string = '';
    web_link?: string = '';
}

export class SettingModel {
    id: number = 0;
    server_timezone: string = '';
    timezone_hour_difference: number = 0;
    region_name: string = '';
    send_friend_request: string = '';
    see_friends_list: string = '';
    email_lookup: string = '';
    phone_lookup: string = '';
    share_your_posts: boolean = false;
    hide_comment_words: boolean = false;
    see_tagged_post: string = '';
    review_post: boolean = false;
    review_tags: boolean = false;
    language: string = '';
    show_about: string = '';
    show_gender: string = '';
    show_dob: string = '';
    show_relationship: string = '';
    show_highschool: string = '';
    show_university: string = '';
    show_works: string = '';
    show_skills: string = '';
    show_intrests: string = '';
    show_current_city: string = '';
    show_hometown: string = '';
    show_other_cities: string = '';
    show_email: string = '';
    show_sec_email: string = '';
    show_phone: string = '';
    show_sec_phone: string = '';
    show_website: string = '';
    show_fb_link: string = '';
    show_twitter_link: string = '';
    show_insta_link: string = '';
    show_youtube_link: string = '';
    show_fav_movies: string = '';
    show_religious_views: string = '';
    show_political_views: string = '';
    show_fav_sports: string = '';
    show_fav_music: string = '';
    comment_push_notify: boolean = false;
    tags_push_notify: boolean = false;
    reminders_push_notify: boolean = false;
    requests_push_notify: boolean = false;
    requests_email_notify: boolean = false;
    requests_sms_notify: boolean = false;
    birthdays_push_notify: boolean = false;
    birthdays_email_notify: boolean = false;
    birthdays_sms_notify: boolean = false;
    group_push_notify: boolean = false;
    group_email_notify: boolean = false;
    group_sms_notify: boolean = false;
    events_push_notify: boolean = false;
    events_email_notify: boolean = false;
    events_sms_notify: boolean = false;
    org_follow_push_notify: boolean = false;
    org_follow_email_notify: boolean = false;
    org_follow_sms_notify: boolean = false;
    menu_order: any[] = [];
    region: string = '';
    user: number = 0;
    organization_address: string = '';
    show_leader: string = '';
    show_services: string = '';

}