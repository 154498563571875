import { Router } from '@angular/router';
import { MessageService } from './../../../core/services/message.service';
import { QbService } from 'src/app/core/services/qb.service';
import { DialogService } from 'src/app/core/services/dialog.service';
import { AppStore } from 'src/app/core/stores/app.store';
import { AppService } from 'src/app/core/services/app.service';
import { Component, OnInit } from '@angular/core';
import { CONSTANTS } from 'src/app/core/essentials/QBconfig';

@Component({
  selector: 'app-online-people-section',
  templateUrl: './online-people-section.component.html',
  styleUrls: ['./online-people-section.component.scss']
})
export class OnlinePeopleSectionComponent implements OnInit {
  users: any = [];
  selectedUsers: any = []
  loggedinUser: any;
  _usersCache: any = [];
  constructor(
    private appService: AppService,
    private appStore: AppStore,
    private dialogService: DialogService,
    private qbService: QbService,
    private router: Router,
    private messageService: MessageService
  ) {
    this.checkUserIsOnline();
  }

  checkUserIsOnline() {
    if (this.appStore.userType.toLowerCase() == 'user') {
      this.appService.getOnlineUsersList(`${this.appStore.userType.toLowerCase()}s`).subscribe(response => {
        this.users = response;
      })
    } else {
      this.appService.getOrganizationOnlineUsersList().subscribe(response => {
        this.users = response;
      })
    }
  }


  public onSubmit(user: any) {
    this.loggedinUser = JSON.parse(localStorage.getItem('loggedinUser') || "'")
    this.selectedUsers.push(this.loggedinUser.id)
    const self = this;
    const type = 3
    this.selectedUsers.push(user.chat_id)
    const params: any = {
      type: type,
      occupants_ids: this.selectedUsers.join(','),
      name: user.name || user.first_name
    };

    this.dialogService.createDialog(params).then(dialog => {
  


      const
        systemMessage = {
          extension: {
            notification_type: 1,
            dialog_id: dialog._id
          }
        };

      (new Promise(function (resolve) {
        resolve({});
      })).then(() => {

        const userIds = dialog.occupants_ids.filter((userId: any) => {
          return userId !== self.qbService.user.id;
        });
        self.messageService.sendSystemMessage(userIds, systemMessage);
        if (self.dialogService.dialogs[dialog._id] === undefined) {
          const tmpObj: any = {};
          tmpObj[dialog._id] = dialog;
          for (const user of this.users) {
            if (user.id != this.loggedinUser.id && dialog.occupants_ids.includes(user.id)) {
              dialog['avatarUrl'] = user.avatar_url;
              break;
            }
          }
         
          self.dialogService.dialogs = Object.assign(tmpObj, self.dialogService.dialogs);
          self.dialogService.dialogsEvent.emit(self.dialogService.dialogs);
        }
        this.dialogService.currentDialog = dialog;
        this.dialogService.currentDialogEvent.emit(dialog);
        self.router.navigate(['/chat'], { queryParams: { chat_id: dialog._id } })
      });
    });
  }

  

  ngOnInit(): void {
  }

}
