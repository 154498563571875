import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Subscription } from 'rxjs';
import { PopupModel } from 'src/app/core/models';
import { AppService } from 'src/app/core/services/app.service';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { OrganizationService } from 'src/app/organizations/service/organization.service';
import { UserService } from 'src/app/users/services/user.service';

@Component({
  selector: 'app-invite-user-popup',
  templateUrl: './invite-user-popup.component.html',
  styleUrls: ['./invite-user-popup.component.scss']
})
export class InviteUserPopupComponent implements OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  subscription: Subscription = new Subscription();
  public form: FormGroup;
  formSubmitting:boolean=false;
  constructor(
    private appService: AppService,
    public loadingBar: LoadingBarService,
    public fb: FormBuilder,
  ) {

    this.form = fb.group({
      email: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });
   }

  ngOnInit(): void {
  }

  closePopup() {
    this.close.emit('icon');
  }

  onSubmit(){
    this.formSubmitting=true;
    let param={email:this.form.value.email,description:this.form.value.description}
    this.subscription.add(
      this.appService.inviteUser(param).subscribe((response: any) => {
        this.formSubmitting = false;
        this.appService.toggleToast({
          type: 'success',
          load: true,
          position: 'top-center',
          context: { description: `${response.message}` }
        });
        this.closePopup();
      }, (error: any) => {
        this.formSubmitting = false;
          if (error) {
            this.appService.toggleToast({
              type: 'error',
              load: true,
              position: 'top-center',
              context: { description: `${error.error.message}` }
            });
          }

        })
    );
  }

}
