<div class="pad20 bdrRadius6px bg-white posRelative organization" id="addMilestonepopup">
    <div class="scroller">
        <div class="header color-18878C font25 lineHeight40px poppins-semibold txtCenter" *ngIf="!this.popup.context.data">
            {{'add milestone' | translate}}
        </div>
        <div class="header color-18878C font25 lineHeight40px poppins-semibold txtCenter" *ngIf="this.popup.context.data">
            {{'edit milestone' | translate}}
        </div>
        <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>

        <form class="mrgntop20" [formGroup]="form" (submit)="onSubmit()">
            <div class="width100">
                <input [ngClass]="{ 'bdr-red': control['name'].invalid && (control['name'].touched || control['name'].dirty || submitted) }" placeholder="{{'milestone name' | translate}}" formControlName="name" type="text" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular  bdr-1-EEEEEE bg-white outline0 bdrRadius6px">
                <p class="color-red poppins-regular font11 mrgnbtm0 mrgntop0 mrgnleft5" *ngIf="control['name'].invalid && submitted">Name is required</p>
            </div>
            <div class="pad10"></div>
            <div class="width100">
                <textarea [ngClass]="{ 'bdr-red': control['description'].invalid && (control['description'].touched || control['description'].dirty || submitted) }" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px minHeight150px"
                    formControlName="description" placeholder="{{'milestone description' | translate}}" name="" id="" rows="3"></textarea>
                <p class="color-red poppins-regular font11 mrgnbtm0 mrgntop0 mrgnleft5" *ngIf="control['description'].invalid && submitted">Description is required</p>
            </div>
            <div class="pad10"></div>
            <div class="width55">
                <!-- <input [ngClass]="{ 'bdr-red': control['date'].invalid && (control['date'].touched || control['date'].dirty) }" placeholder="{{'date' | translate}}" type="date" formControlName="date" class="font16 width100 boxShadow-RGBA00005 pad13 input-icon date-green poppins-regular  bdr-1-EEEEEE bg-white outline0 bdrRadius6px"> -->
                <!-- <app-datepicker [formCntrlName]="'date'" [formGrp]="form" [placeholder]="'date'" [iconCss]="'calender-green input-icon'" [value]="form?.value?.date"  class="width100"></app-datepicker> -->
                <input [owlDateTime]="dt1" [ngClass]="{ 'bdr-red': control['name'].invalid && (control['date'].touched || control['date'].dirty || submitted) }" [owlDateTimeTrigger]="dt1" [placeholder]="'date'" formControlName="date" type="text" readonly="true" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px calender-green input-icon">
                <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                <p class="color-red poppins-regular font11 mrgnbtm0 mrgntop0 mrgnleft5" *ngIf="control['date'].invalid && submitted">Date is required</p>
            </div>
            <div class="pad10"></div>
            <div class="width55">
                <button class="bg-rgba24135140 bdrRadius6px width100 pad20 flex flexAlignItemsCenter cursorPtr ripple flexJustifyCenter positionRelative outline0 bdrNone">
                    <i class="icns icon-camera green d20"></i>
                    <div class="pad5"></div>
                    <span class="poppins-medium font14 color-18878C">
                        {{'upload your banner' | translate}}
                    </span>
                    <input (change)="selectBannerImage($event)" type="file" accept="image/*" class="posAbsolute top0 btm0 left0 right0 width100 height100 opacity0">
                </button>
                <div class="posRelative mrgnbtm5 mrgntop10" *ngIf="selectedFile">
                    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="removeFile()">×</span>
                    <div class="width100 height100p">
                        <div class="width100">
                            <img [src]="imgURL" class="width100 bdrRadius4px">
                        </div>
                    </div>
                </div>
            </div>
            <div class="pad15"></div>
            <div class="flex padbtm15">
                <button (click)="closePopup();" type="button" class="btn-get-started  cancel-btn minWidth107px flex flexAlignItemsCenter flexJustifyCenter bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad0815">
                    {{'cancel' | translate}}
                </button>
                <div class="pad5"></div>
                <button class="btn-get-started btn-user-default minWidth107px bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad0815">
                   <ng-container *ngIf="!this.popup.context.data">{{'create' | translate}}</ng-container>
                   <ng-container *ngIf="this.popup.context.data">{{'save' | translate}}</ng-container>
                </button>
            </div>
            <!-- [ngClass]="{ 'bdr-red': control['registered_name'].invalid && (control['registered_name'].touched || control['registered_name'].dirty) }" -->
        </form>
    </div>

</div>