import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeElementsService, StripeService } from 'ngx-stripe';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/core/services/app.service';
import { AppStore } from 'src/app/core/stores/app.store';
import { OrganizationService } from 'src/app/organizations/service/organization.service';
import { OrganizationStoreService } from 'src/app/organizations/store/organization-store.service';

@Component({
  selector: 'app-organization-promote-stripe',
  templateUrl: './organization-promote-stripe.component.html',
  styleUrls: ['./organization-promote-stripe.component.scss']
})
export class OrganizationPromoteStripeComponent implements OnInit {
  @Input() userType: string = '';
  @Output('close') close = new EventEmitter();
  @ViewChild(StripeCardComponent)
  card: StripeCardComponent = new StripeCardComponent(this.stripeElementsService);
  subscription: Subscription = new Subscription();
  stripeTest!: FormGroup;
  @Input('amount') amount: any;
  @Output() createPromotion = new EventEmitter();
  @Output('stepperCount') stepperCount  = new EventEmitter();
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };
  constructor(private fb: FormBuilder, public stripeService: StripeService, 
    public stripeElementsService: StripeElementsService,
    public organizationService: OrganizationService, 
    public organizationStoreService: OrganizationStoreService,private router: Router,
    public appService: AppService, public appStore:AppStore) { }

  ngOnInit(): void {
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]]
    });
  }

  /**
   * @description
   * this method is used for pay organization promotion
   *
   * @memberof OrganizationPromoteStripeComponent
   */
  payOrganizationPromotion(): void {
    if (this.stripeTest.valid) {
      const name = this.stripeTest?.get('name')?.value;
      this.stripeService
        .createToken(this.card.element, { name })
        .subscribe((result) => {
          if (result.token) {
            let data = { card_token: result.token.id, amount: this.amount };
            //let data = { card_token: "tok_1Ibn8UFfJiy2k2Xh6UfaHMoV" };

            this.subscription.add(
              this.organizationService.briindletProPayment(data).subscribe((response: any) => {
                // this.appService.toggleToast({
                //   type: 'success',
                //   load: true,
                //   position: 'bottom-left',
                //   context: { description: 'Payment successfull' }
                // });

                // if (response.status == "success") {
                //   this.createPromotion.emit("success");
                // }

                if (response.status.toLowerCase() == "success") {
                  this.appStore.setPaymentSuccess();
                  this.appService.toggleToast({
                    type: 'success',
                    load: true,
                    position: 'bottom-left',
                    context: { description: 'Payment successfull' }
                  });
                  this.createPromotion.emit("success");
                  this.stepperCount.emit('3');
                } else if (response.status.toLowerCase() == "failure") {
                  this.appService.toggleToast({
                    type: 'failure',
                    load: true,
                    position: 'bottom-left',
                    context: { description: 'Payment failed' }
                  });
                  this.stepperCount.emit('4');
                }

              })
            );
          } else if (result.error) {
            // Error creating the token
            console.log(result.error.message);
          }
        });
    }
  }

  /**
  * @description
  * Called once, before the instance is destroyed.
  * Add 'implements OnDestroy' to the class.
  *
  * @memberof OrganizationPromoteStripeComponent
  */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
