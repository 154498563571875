<div class="height100vh bg-white flex flexResponsive" id="viewPost">
    <div class="width70 bg-black posRelative height100p displaySlider">
        <div class="posAbsolute zIndex1 posTop width100 flex pad15 flexAlignItemsCenter flexJustifyBetween">
            <div (click)="closePopup();"
                class="d40 cursorPtr ripple bg-E7E8EB bdrRadius50p font25 poppins-regular color-black flex flexAlignItemsCenter flexJustifyCenter">
                ×
            </div>
            <div class="icon-expand icns d30 white cursorPtr" (click)="fullScreenCTA();"></div>
        </div>
        <div class="width100 height100p posRelative flex flexAlignItemsCenter flexJustifyCenter displaySlider">
            <div *ngIf=" popup?.context?.postType != 'photo' &&  popup?.context?.postType != 'video'"
                class="posAbsolute flex flexAlignItemsCenter flexJustifyCenter leftNav posLeft10px d40 cursorPtr ripple bg-E7E8EB bdrRadius50p">
                <i class="icns icon-left-arrow d20 green mrgnright5" (click)="sliderCTA('previous');"></i>
            </div>
            <div [ngSwitch]="true" class="width100 height100p">
                <div *ngSwitchCase=" popup?.context?.postType == 'albumPhoto'  || popup?.context?.postType == 'photo'"
                    class="flex flexAlignItemsCenter height100p flexJustifyCenter">
                    <ng-container *ngFor="let photo of popup?.context?.medias;let i = index">
                        <img [src]="photo?.data?.photo_url || photo?.photo_url || photo?.photo || photo?.get_data?.photo_url || photo?.get_data?.photo || photo?.data?.gif_url || photo?.gif_url || photo?.gif || photo?.get_data?.gif_url || photo?.get_data?.gif" class="maxWidth100p maxHeight100vh" *ngIf="selectedIndex == i" />
                    </ng-container>
                </div>
                <div *ngSwitchCase="popup?.context?.postType == 'albumVideo'  || popup?.context?.postType == 'video'"
                    class="flex flexAlignItemsCenter height100p flexJustifyCenter">
                    <ng-container *ngFor="let video of popup?.context?.medias;let i = index">
                        <app-video-display [video]="video || video?.get_data" class="maxWidth100p maxHeight100vh"
                            *ngIf="selectedIndex == i"></app-video-display>
                        <!-- <img [src]="photo?.photo_url" class="maxWidth100p maxHeight100vh" *ngIf="selectedIndex == i"/> -->
                    </ng-container>
                </div>
                <div *ngSwitchCase="popup?.context?.postType == 'post'"
                    class="flex flexAlignItemsCenter height100p flexJustifyCenter">
                    <ng-container *ngFor="let media of popup?.context?.medias;let i = index">
                        <ng-container [ngSwitch]="media?.type">
                            <ng-container *ngSwitchCase="'video'">
                                <app-video-display [video]="media?.data" class="maxWidth100p maxHeight100vh"
                                    *ngIf="selectedIndex == i"></app-video-display>
                            </ng-container>
                            <ng-container *ngSwitchCase="'photo'">
                                <img [src]="media?.data?.photo_url" class="maxWidth100p maxHeight100vh"
                                    *ngIf="selectedIndex == i" />
                            </ng-container>
                            <ng-container *ngSwitchCase="'gif'">
                                <img [src]="media?.data?.gif_url" class="maxWidth100p maxHeight100vh"
                                    *ngIf="selectedIndex == i" />
                            </ng-container>
                        </ng-container>
                        <!-- <app-video-display [video]="video" class="maxWidth100p maxHeight100vh"  *ngIf="selectedIndex == i"></app-video-display> -->
                        <!-- <img [src]="photo?.photo_url" class="maxWidth100p maxHeight100vh" *ngIf="selectedIndex == i"/> -->
                    </ng-container>
                </div>
                <div *ngSwitchDefault>output2</div>
            </div>
            <div class="posAbsolute flex flexAlignItemsCenter flexJustifyCenter rightNav d40 posRight10px cursorPtr ripple bg-E7E8EB bdrRadius50p"
                (click)="sliderCTA('next');" *ngIf=" popup?.context?.postType != 'photo' &&  popup?.context?.postType != 'video'">
                <i class="icns icon-right-arrow d20 green"></i>
            </div>
        </div>
    </div>
    <div class="width30 pad15 displaySlider flex flexJustifyBetween flexDirectionCol minWidth320px">
        <div class="overflowAuto height100p ">
            <app-post-profile-section-card [post]="post" (removePost)="removePost($event)"></app-post-profile-section-card>
            <div class="pad10"></div>
            <ng-container *ngIf="post?.post?.description || post?.description">
                <div class="font15 color-122A4D poppins-regular wrdBreakAll" *ngIf="post?.post?.description || post?.description">
                    {{post?.post?.description || post?.description}}
                    <div class="pad5"></div>
                </div>
            </ng-container>
            <div class="pad10"></div>
            <app-post-action [post]="post" [user]="popup.context?.user" [comments]="comments" [media]="media" [mediaType]="mediaType" (postAction)="postCTA($event);"></app-post-action>
            <div class="pad10"></div>

            <app-post-comment-list *ngIf="comments" [comments]="comments" [media]="media" [mediaType]="mediaType" [post]="post"></app-post-comment-list>
            <div class="pad10"></div>
        </div>
        <app-post-comment [post]="post" [theme]="popup?.context?.theme" [media]="media" [mediaType]="mediaType"
            [profilePic]="popup?.context?.posted_by?.profile_pic" (update)="updatePosts($event)">
        </app-post-comment>
    </div>
</div>