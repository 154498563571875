export const platformConfig = {
   /* `local` or `beta` or `develop` or `staging` or `prod` */
  local: {
    // baseApiURL: "https://api.briind.com",
    baseApiURL: "https://briind-api.fortelogic.in",
    // baseApiURL: "https://briind-api-staging.fortelogic.in",
    webAppURL: "http://localhost:4200",
    facebookAppId: "392396485062988",
    googleClientId: "1063909761046-unhln4miacp7v618a12sa0rleshpi9ip.apps.googleusercontent.com",
    postFileSize: 15,
    cutomDomain: '',
    stripeKey: "pk_test_51ISobPFfJiy2k2XhkeJLdmsJEa04X1qyM3Du1fVRsVmqjs2JLuxIxim3wMbDMQMqsoTHUermX3oKVgTd4YDxi8xo00xCwr8O1b",
    firebase: {
      apiKey: "AIzaSyCudizVB9OOxmsQ2IdOs7KVaSRyKnnjz88",
      authDomain: "briind-web-push.firebaseapp.com",
      databaseURL: "https://briind-web-push-default-rtdb.firebaseio.com",
      projectId: "briind-web-push",
      storageBucket: "briind-web-push.appspot.com",
      messagingSenderId: "214967122538",
      appId: "1:214967122538:web:876b8ee5d986720923e813",
      measurementId: "G-0HLPXFCSMR"
    }
  },
  beta: {
    baseApiURL: "https://briind-api-test.fortelogic.in",
    webAppURL: "https://beta-briind.fortelogic.in",
    facebookAppId: "392396485062988",
    googleClientId: "1063909761046-unhln4miacp7v618a12sa0rleshpi9ip.apps.googleusercontent.com",
    postFileSize: 15,
    cutomDomain: '.beta-briind.fortelogic.in',
    stripeKey: "pk_test_51ISobPFfJiy2k2XhkeJLdmsJEa04X1qyM3Du1fVRsVmqjs2JLuxIxim3wMbDMQMqsoTHUermX3oKVgTd4YDxi8xo00xCwr8O1b",
    firebase: {
      apiKey: "AIzaSyCudizVB9OOxmsQ2IdOs7KVaSRyKnnjz88",
      authDomain: "briind-web-push.firebaseapp.com",
      databaseURL: "https://briind-web-push-default-rtdb.firebaseio.com",
      projectId: "briind-web-push",
      storageBucket: "briind-web-push.appspot.com",
      messagingSenderId: "214967122538",
      appId: "1:214967122538:web:876b8ee5d986720923e813",
      measurementId: "G-0HLPXFCSMR"
    }
  },
  develop: {
    baseApiURL: "https://briind-api.fortelogic.in",
    webAppURL: "https://briind.fortelogic.in",
    facebookAppId: "392396485062988",
    googleClientId: "1063909761046-unhln4miacp7v618a12sa0rleshpi9ip.apps.googleusercontent.com",
    postFileSize: 15,
    cutomDomain: '.briind.fortelogic.in',
    stripeKey: "pk_test_51ISobPFfJiy2k2XhkeJLdmsJEa04X1qyM3Du1fVRsVmqjs2JLuxIxim3wMbDMQMqsoTHUermX3oKVgTd4YDxi8xo00xCwr8O1b",
    firebase: {
      apiKey: "AIzaSyCudizVB9OOxmsQ2IdOs7KVaSRyKnnjz88",
      authDomain: "briind-web-push.firebaseapp.com",
      databaseURL: "https://briind-web-push-default-rtdb.firebaseio.com",
      projectId: "briind-web-push",
      storageBucket: "briind-web-push.appspot.com",
      messagingSenderId: "214967122538",
      appId: "1:214967122538:web:876b8ee5d986720923e813",
      measurementId: "G-0HLPXFCSMR"
    }
  },
  staging: {
    baseApiURL: "https://briind-api-staging.fortelogic.in",
    webAppURL: "https://briind-staging.ileafsolutions.com",
    facebookAppId: "392396485062988",
    googleClientId: "1063909761046-unhln4miacp7v618a12sa0rleshpi9ip.apps.googleusercontent.com",
    postFileSize: 15,
    cutomDomain: '.briind-staging.ileafsolutions.com',
    stripeKey: "pk_live_51ISobPFfJiy2k2XhgikPaPNNwv66S5qx2rpx7tS0LWaqoq5TarNRt6rRYh41j3As6veo6NjkiPa85YzQa34UuCQQ00Sbo4clkB",
    firebase: {
      apiKey: "AIzaSyCudizVB9OOxmsQ2IdOs7KVaSRyKnnjz88",
      authDomain: "briind-web-push.firebaseapp.com",
      databaseURL: "https://briind-web-push-default-rtdb.firebaseio.com",
      projectId: "briind-web-push",
      storageBucket: "briind-web-push.appspot.com",
      messagingSenderId: "214967122538",
      appId: "1:214967122538:web:876b8ee5d986720923e813",
      measurementId: "G-0HLPXFCSMR"
    }
  },
  prod: {
    baseApiURL: "https://api.briind.com",
    webAppURL: "https://beta.briind.com",
    facebookAppId: "392396485062988",
    googleClientId: "1063909761046-unhln4miacp7v618a12sa0rleshpi9ip.apps.googleusercontent.com",
    postFileSize: 15,
    stripeKey: "pk_live_51ISobPFfJiy2k2XhgikPaPNNwv66S5qx2rpx7tS0LWaqoq5TarNRt6rRYh41j3As6veo6NjkiPa85YzQa34UuCQQ00Sbo4clkB",
    firebase: {
      apiKey: "AIzaSyCudizVB9OOxmsQ2IdOs7KVaSRyKnnjz88",
      authDomain: "briind-web-push.firebaseapp.com",
      databaseURL: "https://briind-web-push-default-rtdb.firebaseio.com",
      projectId: "briind-web-push",
      storageBucket: "briind-web-push.appspot.com",
      messagingSenderId: "214967122538",
      appId: "1:214967122538:web:876b8ee5d986720923e813",
      measurementId: "G-0HLPXFCSMR"
    }
  }
};
