import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {
  @Input('searchPlaceholder') searchPlaceholder: string = 'search'
  @Output('search') search = new EventEmitter();
  @Input('icon') icon: string = '';
  @Input() serachValue: string = '';
  @Input() isLimitWidth: boolean = true;
  constructor() { }

  /**
   * @description
   * this method is used for emittin the input key
   *
   * @param {*} event
   * @memberof SearchInputComponent
   */
  searchValue(event: any) {
    this.search.emit(this.serachValue);
  }

  ngOnInit(): void {
  }

}
