import { AppService } from './../../../../core/services/app.service';
import { EventEmitter, Input, Output, Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PopupModel } from 'src/app/core/models';
import { OrganizationStoreService } from 'src/app/organizations/store/organization-store.service';
import { OrganizationService } from '../../../../organizations/service/organization.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-create-organization-event-popup',
  templateUrl: './create-organization-event-popup.component.html',
  styleUrls: ['./create-organization-event-popup.component.scss']
})
export class CreateOrganizationEventPopupComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  @Input('popup') popup: PopupModel = new PopupModel();
  moment = moment;
  @Output('close') close = new EventEmitter();
  selectedFileData: any;
  searchText: string = '';
  stepper: number = 1;
  list: any = [];
  selectedDropdownItem: string = 'few_members';
  buttonName: string = 'next';
  form: FormGroup = new FormGroup({});
  eventData: any;
  selectedList: any = [];
  showCropper:any = false;
  imageChangedEvent: any = '';
  imageType:any = "";
  selectedFile: any = {
    cover_photo: '',
    file: ''
  };
  showImageError: string = "";
  public min = new Date();
  constructor(
    private organizationService: OrganizationService,
    private organizationStoreService: OrganizationStoreService,
    private fb: FormBuilder,
    private appService: AppService
  ) {

  }

  ngOnInit(): void {
    if (this.popup.context.data) {
      this.form = this.fb.group({
        name: new FormControl(this.popup.context.data.title, Validators.required),
        description: new FormControl(this.popup.context.data.description, Validators.required),
        event_date: new FormControl(this.popup.context.data.event_date, Validators.required),
        location: new FormControl(this.popup.context.data.location, Validators.required)
      });
    }
    else {
      this.form = this.fb.group({
        name: new FormControl('', Validators.required),
        description: new FormControl('', Validators.required),
        event_date: new FormControl('', Validators.required),
        location: new FormControl('', Validators.required)
      });
    }
  }
  

  cardActionCTA() {
    this.selectedList = this.list.filter((x: any) => x.isChecked)
  }

  changesMembersType(event: any) {
    if (event.target) {
      this.selectedDropdownItem =  event.target.value;
      switch ( event.target.value) {
        case 'group':
          this.getGroupList();
          break;
        case 'few_members':
          this.getMemberInvitedList();
          break;
        default:
          break;
      }
    } 
  }

  closePopup() {
    this.close.emit('icon')
  }

  /**
   * @description
   * this method is used for get the group cover photo
   *
   * @param {*} event - file event
   * @memberof CreateBriindletDetailComponent
   */
   uploadPhoto(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.imageChangedEvent = event;
      this.showCropper =true;
      var reader = new FileReader();
      reader.onload = (ev: any) => {
        this.selectedFile = {
          cover_photo: ev.target.result,
          file: event.target.files[0]
        }
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  uploadImage(event: any) {
    if(this.selectedFile.file != "") {
      this.showImageError = "";
    }
    this.selectedFile.file = event.croppedImage;
    this.selectedFile.cover_photo = event.croppedImageBase64;
    this.showCropper = false;
  }

  // selectedFile(event: any) {
  //   if (event && event.target && event.target.files) {
  //     this.selectedFileData = event.target.files[0];
  //     this.showCropper = false;
  //   }
  // }

  continueNext() {
    if(this.selectedFile.file == "" && !this.popup.context.data) {
      this.showImageError = "Please select image!"
      return;
    }else {
      this.showImageError = "";
    }
    this.stepper = this.stepper + 1;
    
    switch (this.stepper) {
      case 2:
        if (this.popup.context.data && this.popup.context.data.id) {
          this.getOrgganizationEventMembers();
        } else {
          this.getMemberInvitedList();
        }
        
        this.buttonName = 'send invite';
        break;
      case 3:
        this.selectedList = this.list.filter((x: any) => x.isChecked)
        this.buttonName = 'confirm';
        break;
      case 4:
        if (this.popup.context.data && this.popup.context.data.id) {
          this.updateEvent()
        } else {
          this.createEvent();
        }
        break;  
      default:
        break;
    }
  }

  checkData() {
    if (this.list && this.popup.context.data && this.popup.context.data.volunteers && this.popup.context.data.volunteers.length > 0) {
      let list = this.list.filter((x: any) => !this.popup.context.data.volunteers.includes((x.user_id)))
      if (list.length == 0) {
        return true;
      }
    }
  }

  setFormData() {
    let formData = new FormData();
    formData.append('title', this.form.value.name)
    formData.append('event_date', this.moment(this.form.value.event_date).format('YYYY-MM-DD HH:MM'))
    formData.append('location', this.form.value.location)
    formData.append('description', this.form.value.description)
    formData.append('organization', this.popup.context.organizationID)
    // formData.append('photo', this.selectedFileData)
    if(this.selectedFile.file != '')  {
      formData.append('photo', this.selectedFile.file);
    };
    return formData;
  }

  inviteEventVolunteers() {
    let selectedIDs: any = [];
    this.selectedList = this.list.filter((x: any) => x.isChecked)
    if (this.selectedDropdownItem == 'group') {
      selectedIDs = this.selectedList.map((x: any) => x.id)
    } else {
      selectedIDs = this.selectedList.map((x: any) => x.user_id)
    }
    const param = {
      event_id: this.eventData.id,
      all_members: this.selectedDropdownItem == 'all_members' ? true : false,
      group: this.selectedDropdownItem == 'group' ? true : false,
      few_members: this.selectedDropdownItem == 'few_members' ? true : false,
      user_ids: this.selectedDropdownItem != 'group' ? selectedIDs: [],
      group_ids: this.selectedDropdownItem == 'group' ? selectedIDs: [],
    }
    this.subscription.add(
      this.organizationService.inviteEventVolunteers(param).subscribe((response: any) => {
        this.appService.toggleToast({
          type: 'success',
          load: true,
          position: 'top-center',
          context: { description: response.message }
        });
        this.closePopup();
      })
    )
  }

  getMemberInvitedList() {
    const param = {
      q: this.searchText,
      organization_id: this.popup.context.organizationID
    }
    this.subscription.add(
      this.organizationService.getOrganizationMemberInvitedList(param).subscribe(response => {
        this.list = response;
        if (this.selectedDropdownItem == 'all_members') {
          for (const item of this.list) {
            item.isChecked = true;
          }
        } else {
          for (const item of this.list) {
            item.isChecked = false;
          }
        }
      })
    )
  }

  getOrgganizationEventMembers() {
    const param = {
      q: this.searchText,
      organization_id: this.popup.context.organizationID
    }
    this.subscription.add(
      this.organizationService.getOrgganizationEventMembers(this.popup.context.data.id, param).subscribe(response => {
        this.list = response;
        if (this.selectedDropdownItem == 'all_members') {
          for (const item of this.list) {
            item.isChecked = true;
          }
        } else {
          for (const item of this.list) {
            item.isChecked = false;
          }
        }
      })
    )
  }

  /**
   * @description
   * method is used for getting the membership group list
   */
  getGroupList() {
    const param = {
      organization_id: this.popup.context.organizationID,
      q: this.searchText
    }
    this.subscription.add(
      this.organizationService.getOrganizationMembersGroupList(param).subscribe(response => {
        this.organizationStoreService.setMembershipGroupPage();
        this.list = this.organizationStoreService.organizationMembershipGroupList;
      })
    )
  }

  createEvent() {
    this.subscription.add(
      this.organizationService.createOrganizationEvents(this.setFormData()).subscribe((response: any) => {
        this.eventData = response.result || response;
        this.inviteEventVolunteers();
      })
    );
  }

  updateEvent() {
    this.subscription.add(
      this.organizationService.updateEventDetail(this.popup.context.data.id, this.setFormData()).subscribe((response: any) => {
        if (this.stepper == 2) {
          this.eventData = response.result;
          this.inviteEventVolunteers();
        } else {
          this.closePopup();
        }
        // this.appService.toggleToast({
        //   type: 'success',
        //   load: true,
        //   position: 'top-center',
        //   context: { description: `Event Updated Successfully` }
        // });
  

      })
    );
  }

  searchList(event: any) {
    this.searchText = event;
    if (this.selectedDropdownItem == 'group') {
      this.getGroupList();
    } else {
      this.getMemberInvitedList();
    }
  }



  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.subscription)
    this.subscription.unsubscribe();
  }

}
