<ng-container *ngIf="!isCreatePost">
    <div class="flex pad15 bdr-1-EEEEEE boxShadow-RGBA00005 posRelative privacy-container flexAlignItemsCenter bdrRadius6px cursorPtr ripple"
        (click)="privacyCTA()" #privacy>
        <i class="icns {{theme}} d20"
            [ngClass]="{'icon-lock': selectedPrivacy == 'private', 'icon-friends': selectedPrivacy == 'friends','icon-public': selectedPrivacy == 'everyone'}"></i>
        <div class="pad2"></div>
        <i class="icns icon-down-arrow {{theme}} d12"></i>
    </div>

    <div class="boxShadow-RGBA00005 posRight bg-white mrgn1 bdrRadius6px bdr-1-EEEEEE minWidth200px posAbsolute zIndex1 show-privacy"
        [ngClass]="{'color-02457A': theme == 'blue', 'color-18878C': theme == 'green'}" *ngIf="isShowPrivacyList"
        #privacyList>
        <div class="pad10 ripple flex cursorPtr" (click)="selectPrivacy('everyone')">
            <i class="icns icon-public {{theme}} d20"></i>
            <div class="pad5"></div>
            <div class="privacy-label font15 poppins-regular ">
                {{'public' | translate}}
            </div>
        </div>
        <div class="pad10 ripple flex cursorPtr" (click)="selectPrivacy('friends')">
            <i class="icns icon-friends {{theme}} d20"></i>
            <div class="pad5"></div>
            <div class="privacy-label  font15 poppins-regular ">
                {{'friends' | translate}}
            </div>
        </div>
        <div class="pad10 ripple flex cursorPtr" (click)="selectPrivacy('private')">
            <i class="icns icon-lock {{theme}} d20"></i>
            <div class="pad5"></div>
            <div class="privacy-label font15 poppins-regular">
                {{'private' | translate}}
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="isCreatePost">
    <div class="maxWidth120px posRelative">
        <div class="displayFlex pad5 bdr-1-EEEEEE boxShadow-RGBA00005 posRelative privacy-container flexAlignItemsCenter flexJusifyBetween bdrRadius6px cursorPtr ripple"
            (click)="privacyCTA()" #privacy>
            <div class="flex flexAlignItemsCenter color-02457A font14 poppins-medium">
                <i class="icns {{theme}} d20"
                [ngClass]="{'icon-lock': selectedPrivacy == 'private', 'icon-friends': selectedPrivacy == 'friends','icon-public': selectedPrivacy == 'everyone'}"></i>
                <div class="pad4"></div>
                {{selectedPrivacy | translate}}
            </div>
            <div class="pad4"></div>
            <i class="icns icon-down-arrow {{theme}} d12"></i>
            
        </div>
        <div class="boxShadow-RGBA00005 posRight bg-white mrgn1 bdrRadius6px bdr-1-EEEEEE minWidth200px posAbsolute zIndex1 show-privacy"
            [ngClass]="{'color-02457A': theme == 'blue', 'color-18878C': theme == 'green'}" *ngIf="isShowPrivacyList"
            #privacyList>
            <div class="pad10 ripple flex cursorPtr" (click)="selectPrivacy('everyone')">
                <i class="icns icon-public {{theme}} d20"></i>
                <div class="pad5"></div>
                <div class="privacy-label font15 poppins-regular ">
                    {{'public' | translate}}
                </div>
            </div>
            <div class="pad10 ripple flex cursorPtr" (click)="selectPrivacy('friends')">
                <i class="icns icon-friends {{theme}} d20"></i>
                <div class="pad5"></div>
                <div class="privacy-label  font15 poppins-regular ">
                    {{'friends' | translate}}
                </div>
            </div>
            <div class="pad10 ripple flex cursorPtr" (click)="selectPrivacy('private')">
                <i class="icns icon-lock {{theme}} d20"></i>
                <div class="pad5"></div>
                <div class="privacy-label font15 poppins-regular">
                    {{'private' | translate}}
                </div>
            </div>
        </div>
    </div>
</ng-container>