import { AppService } from 'src/app/core/services/app.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppStore } from './../../../../../core/stores/app.store';

@Component({
  selector: 'app-briindlet-event-briind',
  templateUrl: './briindlet-event-briind.component.html',
  styleUrls: ['./briindlet-event-briind.component.scss']
})
export class BriindletEventBriindComponent implements OnInit {
  @Input() formGrp: any;
  @Output() stepperActions = new EventEmitter();
  isError: boolean = false;
  constructor(
    public appStore: AppStore,
    private appService: AppService
  ) { }

  actionCTA(event : any) {
    this.stepperActions.emit(event);
  }

  searchBriindId() {
    this.formGrp['controls']['step_11'].patchValue({
      organization_link: ""
    })
    this.appService.searchBriindId({briind_id: this.formGrp['value']['step_11']['briind_id']}).subscribe((response: any) => {
      this.isError = false;
      this.formGrp['controls']['step_11'].patchValue({
        organization_link: `/organizations/${response.id}/profile/bussiness`
      })
    }, err => {
      this.isError = true;
      if(this.formGrp.value.step_11.briind_id == '') {
        this.isError = false;
      }
    })
  }

  ngOnInit(): void {
    // this.formGrp.controls['step_11'].patchValue({
    //   briind_id: this.appService.cacheData1?.step_11?.briind_id
    // })
    // this.appService.cacheData1.subscribe(res=>{
    //   console.log('steps 11', res)
    //   let steps:any = []
    //   steps = res
    //   this.formGrp.controls['step_11'].patchValue({
    //     briind_id: steps.step_11?.briind_id
    //    })
    // })
  }


}
