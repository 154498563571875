import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
import { UserService } from '../../../../users/services/user.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { OrganizationService } from '../../../../organizations/service/organization.service';
import { AppService } from 'src/app/core/services/app.service';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
  selector: 'app-create-post-popup',
  templateUrl: './create-post-popup.component.html',
  styleUrls: ['./create-post-popup.component.scss']
})
export class CreatePostPopupComponent implements OnChanges,OnDestroy {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  @Input('uploadedFiles') uploadedFiles: any = [];
  showCreatePost: boolean = true;
  subscription: Subscription = new Subscription();
  description: string = '';
  isShowGifSection: boolean = false;
  gifList: any = [];
  selectedData: any[] = [];
  taggedNames: any = '';
  isShowTag: boolean = false;
  btnPost: boolean = true;
  submittingForm: boolean = false;
  loader = this.loadingBar.useRef();
  postPermmision: string = 'everyone';
  constructor(
    private appService: AppService,
    private userService: UserService,
    private authService: AuthService,
    private organizationService: OrganizationService,
    public loadingBar: LoadingBarService
  ) { }

   getGifDatas() {
    this.subscription.add(
      this.appService.getGifDatas({}).subscribe(response => {
        this.gifList = response;
        this.isShowGifSection = true;
      })
    )
  }


  /**
  * @description
  * Click on close button this method will call
  *
  * @memberof CreatePostPopupComponent
  */
  closePopup() {
    this.close.emit('icon');
  }

  /**
   * @description
   * this method is used for remove the files
   *
   * @param {number} index
   * @memberof CreatePostPopupComponent
   */
  removeFile(index: number) {
    this.uploadedFiles.splice(index,1)
  }

  /**
   * @description
   * this method is used for adding files
   *
   * @param {*} [event]
   * @memberof CreatePostPopupComponent
   */
  uploadedFile(event ?: any) {
    this.isShowGifSection = false;
    this.isShowTag = false;
    switch (event.type) {
      case 'emoji':
        this.description = this.description + event.value;
        this.btnPost = false;
        break;
      case 'gif':
        this.getGifDatas();
        break;
      case 'tag':
        this.isShowTag = true;
        break;  
      default:
        if (this.uploadedFiles && this.uploadedFiles.length != 0) {
          this.uploadedFiles.push(event);
        } else {
          this.uploadedFiles = [event];
        }
        break;
    }
    
  }

  /**
   * @description
   * this method is used for create post
   *
   * @memberof CreatePostPopupComponent
   */

  postbtn() {
    if (this.description.length > 0 && this.description.trim().length > 0) {
      this.btnPost = false;
    } else {
      this.btnPost = true;
    }
  }

  selectPrivacy(event: any) {
    this.postPermmision = event;
    if (this.postPermmision == 'private') {
      this.selectedData = [];
      this.taggedNames = '';
    }
  }

  createPost() {
    this.loader.start();
    this.submittingForm=true
    let formData = new FormData();
    if (this.selectedData) {
      if (this.popup.context.userType == 'user') {
        this.selectedData.forEach((element, i) => {
          formData.append(`briind_ids[${i}]`, element.briind_id)
        })
      }
    }
    formData.append('description', this.description);
    formData.append('post_type', this.popup.context.postType);
    formData.append('post_permission', this.postPermmision);
    formData.append('post_to_type', this.popup.context.postToType || this.authService.getSessionData().type);
    formData.append('post_to_id', this.popup.context.postToId || this.authService.getSessionData().orgId);

    for (let index = 0; index < this.uploadedFiles.length; index++) {
      switch (this.uploadedFiles[index].type) {
        case 'photo':
          formData.append(`photos[${index}][photo]`, this.uploadedFiles[index].file)
          break;
        case 'video':
          formData.append(`videos[${index}][video]`, this.uploadedFiles[index].file)
          break;
        case 'gif':
          formData.append(`gifs[${index}][gif_url]`, this.uploadedFiles[index].url);
          break;
        default:
          break;
      }
    }

    switch (this.authService.getSessionData().type.toLowerCase()) {
      case 'user':
        this.createUserPost(formData);
        break;
      case 'organization':
        this.createOrganizationPost(formData);
        break;
      default:
        this.submittingForm = false;
        this.loader.stop();
        break;
    }
   
  }

  /**
   * @description
   * create user post
   *
   * @param {*} formData
   * @memberof CreatePostPopupComponent
   */
  createUserPost(formData: any) {
    this.subscription.add(
      this.userService.createPost(formData).subscribe((response: any) => {
        this.closePopup();
        this.submittingForm = false;
        this.loader.stop();
      })
    )
  }

  /**
   * @description
   * create organization post
   *
   * @param {*} formData
   * @memberof CreatePostPopupComponent
   */
  createOrganizationPost(formData: any) {
    this.subscription.add(
      this.organizationService.createPost(formData).subscribe((response: any) => {
        this.closePopup();
        this.submittingForm = false;
        this.loader.stop();
      })
    )
  }


  gifOrTagActionCTA(event: any, type: string) {
    switch (type) {
      case 'gif':
        this.uploadedFiles = [];
        if (event) {
          this.uploadedFiles.push( {file: event, type: 'gif', url: event})
        }
        this.isShowGifSection = false;
        break;
      case 'tag':
        this.selectedData = event || [];
        this.isShowTag = false;
        if (this.selectedData.length) {
          switch (true) {
            case this.selectedData.length  == 1 : case this.selectedData.length  == 2 :
              this.taggedNames = this.selectedData.map((x: any) => x.name).join(' and ');
              break;
            case this.selectedData.length  > 2 :
              this.taggedNames = this.selectedData[0].name + ` and ${this.selectedData.length - 2} others` ;
              break;
            default:
              break;
          }
        }
        break;  
      default:
        break;
    }
   
  }


  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add 'implements OnChanges' to the class.
    if (changes.popup && changes.popup.currentValue) {
      switch (this.popup.context.fileType) {
        case 'gif':
          this.getGifDatas();
          break;
        case 'emoji':
          this.description = this.description + this.popup.context.emoji;
          this.btnPost = false;
          break;
        case 'tag':
          this.isShowTag = true;
          break;
        default:
          break;
      }
     
    }
  }

  
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  
}
