<header class="width100 posFixed" id="navbarTop" (scroll)="onWindowScroll($event);">
  <div class="container flex width90 section1464 mrgnauto minHeight75px pad150150 flexJustifyBetween flexAlignItemsCenter">
    <div class="siteLogo">
      <a class="navbar-brand" href="#">
        <img src="assets/images/brind-logo-white.png" class="headerLogo desktopVisible" width="170" alt="">
        <img src="assets/images/brind-logo-blue.png" class="align-top img-fluid mobiVisible" width="120" alt="">
      </a>
    </div>
    <div class="desktopVisible flex1">
      <ul class="navUl flex mrgn0">
        <li class="navItem">
          <a class="navLink pad10 font18 letterSpacing0px cursorPtr" routerLinkActive="active" [routerLink]="['/home'] || ['/']" >{{'home' | translate}}</a>
        </li>
        <!-- <li class="navItem">
          <a [routerLink]="['/coming-soon/main']" class="navLink pad10 font18 letterSpacing0px cursorPtr">{{'pro briind' | translate}}</a>
        </li>
        <li class="navItem">
          <a [routerLink]="['/coming-soon/main']" class="navLink pad10 font18 letterSpacing0px cursorPtr">{{'about' | translate}}</a>
        </li> -->
        <li class="navItem">
          <a class="navLink pad10 font18 letterSpacing0px cursorPtr" routerLinkActive="active" [routerLink]="['/signin']" (click)="signInCTA();">{{'sign in' | translate}}</a>
        </li>
      </ul>
    </div>
    <button class="outline0 bdrNone bgTransparent mobiVisible ripple" (click)="mobileNavToggleCTA()">
      <i class="icns icon-hamburger"></i>
    </button>
  </div>
  <div class="width100 mobile-nav" #mobileNav>
    <div class="mobiVisible">
        <div class="ripple">
            <a class="color-061F45 padtop10 padbtm10 displayblock font18 letterSpacing0px cursorPtr" routerLinkActive="active" [routerLink]="['/']" >{{'home' | translate}}</a>
        </div>
        <div class="ripple">
            <a class="color-061F45 padtop10 padbtm10 displayblock font18 letterSpacing0px cursorPtr" routerLinkActive="active">{{'pro briind' | translate}}</a>
        </div>
        <div class="ripple">
            <a class="color-061F45 padtop10 padbtm10 displayblock font18 letterSpacing0px cursorPtr" routerLinkActive="active" >{{'about' | translate}}</a>
        </div>
        <div class="ripple">
            <a class="color-061F45 padtop10 padbtm10 displayblock font18 letterSpacing0px cursorPtr" routerLinkActive="active" (click)="signInCTA();">{{'sign in' | translate}}</a>
        </div>
    </div>
  </div>
</header>
