<div class="flex bdr-1-EEEEEE flexResponsive boxShadow-RGBA00005 bg-FFFFFF bdrRadius6px pad10">
    <div class="txtCenter flex flexAlignItemsCenter flexJustifyCenter">
        <span class="blue-img-shadow padtop8 padbtm3 padleft10 displayFlex bdrRadius50p posRelative">
            <img [src]="data?.cover_photo" class="d90 bdrRadius50p" />
        </span>

    </div>
    <div class="width100">
        <div class="width100 width100Minus15MD mrgn000auto">
            <div class="poppins-semibold font14 color-18878C padbtm10 padtop5">{{data?.name}}</div>
            <div class="poppins-regular font12 color-8e8e8e padbtm5 wrdBreakAll">{{data?.description}}</div>
            <div class="bdr-btm-2-EEEEEE mrgntop10 mrgnbtm10"></div>
            <div class="poppins-medium font12 color-525151 padbtm5 padtop5">{{data?.date | date: 'MMM dd, yyyy' }}
            </div>
        </div>

    </div>
    <div (click)="editMilestonePopupCTA(data)" class="d30 flex flexJustifyCenter flexAlignItemsCenter cursorPtr ripple bdrRadius50p">
        <i class="icns icon-edit blue d20"></i>
    </div>
</div>