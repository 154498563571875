import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-organization-social-media-preview',
  templateUrl: './organization-social-media-preview.component.html',
  styleUrls: ['./organization-social-media-preview.component.scss']
})
export class OrganizationSocialMediaPreviewComponent implements OnInit {
  @Input('data') public data: any;
  @Input('organizationID') public organizationID: any;
  @Input('showEditIcon') showEditIcon: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
