import { AppService } from 'src/app/core/services/app.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getTimeForDate } from 'src/app/core/essentials/common.libs';
import { AppStore } from './../../../../../core/stores/app.store';
import * as moment from 'moment';

@Component({
  selector: 'app-briindlet-event-date',
  templateUrl: './briindlet-event-date.component.html',
  styleUrls: ['./briindlet-event-date.component.scss']
})
export class BriindletEventDateComponent implements OnInit {
  @Input() formGrp: any;
  @Output() stepperActions = new EventEmitter();
  minDate: any = new Date();
  minEndDate: any = new Date();
  isInvalid: boolean = false;
  constructor(
    public appStore: AppStore,
    private appService: AppService
  ) { 
    this.minDate.setHours(0, 0, 0, 0);
    this.minEndDate.setHours(0, 0, 0, 0);
  }

  getDateValue(date: any) {
    return new Date(date);
  }

  checkEndDateValid() {
    this.isInvalid = false;
    let endDate = new Date(`${moment(this.formGrp['controls']['step_6']['value']['event_to_date']).format('YYYY-MM-DD')} ${moment(this.formGrp['controls']['step_6']['value']['event_to_time']).format('HH:mm')}`);
    let startDate = new Date(`${moment(this.formGrp['controls']['step_6']['value']['event_from_date']).format('YYYY-MM-DD')} ${moment(this.formGrp['controls']['step_6']['value']['event_from_time']).format('HH:mm')}`);
    if (endDate.getTime() <  startDate.getTime()) {
      this.isInvalid = true;
      // return true;
    }
  }

  startDateChange() {
    this.minEndDate = new Date(`${moment(this.formGrp['controls']['step_6']['value']['event_from_date']).format('YYYY-MM-DD')} ${moment(this.formGrp['controls']['step_6']['value']['event_from_time']).format('HH:mm')}`);
    this.checkEndDateValid();
  }

  actionCTA(event: any) {
    this.stepperActions.emit(event)
  }

  ngOnInit(): void {
    // this.formGrp.controls['step_6'].patchValue({
    //   event_from_date: this.appService.cacheData?.step_6?.event_from_date,
    //   event_from_time: this.appService.cacheData?.step_6?.event_from_time,
    //   event_to_date: this.appService.cacheData?.step_6?.event_to_date,
    //   event_to_time: this.appService.cacheData?.step_6?.event_to_time,
    //  })
    // this.appService.cacheData.subscribe(res=>{
    //   console.log('steps 6', res)
    //   let steps:any = []
    //   steps = res
    //   this.formGrp.controls['step_6'].patchValue({
    //     event_from_date: steps.step_6.event_from_date,
    //     event_from_time: steps.step_6.event_from_time,
    //     event_to_date: steps.step_6.event_to_date,
    //     event_to_time: steps.step_6.event_to_time,
    //    })
    // })
  }

}
