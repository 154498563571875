<div id="createalbum-popup"
    class="bdrRadius6px bg-white pad20 padright10 posRelative custom-scrollbar {{appStore?.userType}}">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>
    <div id="social-create-album-pop" class="overflowHidden">
        <div class="color-122A4D poppins-semibold font18 padtop20 padbtm20 txtCenter">{{'share to people' | translate}}
        </div>
        <div class="maxHeight70vh overflowAuto custom-scrollbar minHeight50vh">
            <app-share-post-detail [user]="popup.context?.user" [post]="popup?.context?.post"
                (shareMessage)="shareMessageEmit($event)"></app-share-post-detail>
            <div class="flex flexAlignItemsCenter flexJustifyBetween padbtm20 padright10">
                <div class="color-122A4D font18 poppins-semibold">{{'all friends' | translate}}</div>
                <app-search-input (search)="searchInput = $event"></app-search-input>
                <!-- <div class="posRelative width55 mrgnauto">
                <i class="icon-search green icns d15 posAbsolute posTop9px posLeft7px"></i>
                <input class="searchInput boxShadow-RGBA00005 width100" placeholder="Search" [(ngModel)]="searchInput">
            </div> -->
            </div>

            <div class="maxHeight150px overflowAuto custom-scrollbar">
                <div class="width100">
                    <div class="width100 flex flexResponsive flexWrap">
                        <div class="padright10 padbtm10 width50  "
                            *ngFor="let item of this.popup?.context?.list | search: searchInput"
                            (click)="selectUsers(item.id)">
                            <span class="ripple show bdrRadius6px">
                                <app-general-card
                                    [customClass]="(selectedUsers?.includes(item.id) ? 'blue-img-shadow': '')"
                                    [type]="'SearchUserCard'" [data]="item"></app-general-card>
                            </span>
                            <!-- <app-share-to-group-card  [userID]="userID" [data]="item" [type]="'Groups'" [selectedGroup]="selectedUserGroup">
                        </app-share-to-group-card> -->
                        </div>
                    </div>
                </div>
                <!-- <app-empty-state [stateType]="'groupList'" *ngIf="(this.popup?.context?.groupList | search: searchInput).length == 0"></app-empty-state> -->
            </div>
        </div>
        <div class="padtop30 padbtm20 flex">
            <button type="button" (click)="closePopup();"
                class="btn-get-started minwidth100px  ripple cancel-btn cursorPtr font14 poppins-regular pad1030">
                {{'cancel' | translate}}
            </button>
            <div class="pad5"></div>
            <button type="button" [disabled]="!selectedUsers.length || isLoader" (click)="shareToUser();"
                class="btn-get-started  minwidth100px btn-user-default ripple cursorPtr font14 poppins-regular pad1030">
                {{'share' | translate}}
            </button>
        </div>
    </div>
</div>