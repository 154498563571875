import { AppService } from 'src/app/core/services/app.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { AppStore } from './../../../../../core/stores/app.store';

@Component({
  selector: 'app-briindlet-event-lastdate',
  templateUrl: './briindlet-event-lastdate.component.html',
  styleUrls: ['./briindlet-event-lastdate.component.scss']
})
export class BriindletEventLastdateComponent implements OnInit {
  @Input() formGrp: any;
  minDate: any = new Date();
  miniDate:any;
  maxDate: any = new Date();
  @Output() stepperActions = new EventEmitter();

  constructor(
    public appStore: AppStore,
    private appService: AppService
  ) {
    this.minDate.setHours(0, 0, 0, 0)
  }

  actionCTA(event : any) {
    this.stepperActions.emit(event);
    this.appService.setCache(this.formGrp.value)
    // sessionStorage.setItem('briindletCache', JSON.stringify(this.formGrp.value))
  }

  ngOnInit(): void {
    this.maxDate =  new Date(`${moment(this.formGrp['controls']['step_6']['value']['event_to_date'])}`);
    this.maxDate.setDate(this.maxDate.getDate() - 1)

    const dateStr = new Date()
    this.miniDate = new Date(new Date(dateStr).setDate(new Date(dateStr).getDate() - 7));
 
    // this.appService.cacheData.subscribe(res=>{
    //   console.log('steps 7', res)
    //   let steps:any = []
    //   steps = res
    //   this.formGrp.controls['step_7'].patchValue({
    //     registration_last_date: steps.step_7?.registration_last_date
    //    })
    // })
    // this.formGrp.controls['step_7'].patchValue({
    //   registration_last_date: this.appService.cacheData?.step_7?.registration_last_date,
    //  })
  }

}
