<app-set-user-details (inIt)="currentData($event)"></app-set-user-details>
<header class="width100 bg-white posFixed posTop zIndex120 {{userType}} padleft15 padright15" id="user-navbar">
    <div class="container flex width100  section1464 mrgnauto minHeight75px flexJustifyBetween flexAlignItemsCenter">
        <div class="siteLogo">
            <a class="navbar-brand flex" href="#">
                <img src="assets/images/brind-logo-blue.png" class="header-logo" alt="">
            </a>
        </div>
        <div class="flex flexAlignItemsCenter">
            <div class="flex1 flex flexAlignItemsCenter">
                <div class="desktopVisible">
                    <ul class="nav-ul flex mrgn0 flexAlignItemsCenter">
                        <li class="nav-item cursorPtr">
                            <a class="nav-link pad10" (click)=openSearchPopup()>
                                <i class="icon-search {{iconClass}} icns d25 "></i>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link timeline-label pad10 poppins-medium font16 letterSpacing0px cursorPtr" routerLinkActive="active" [routerLink]="['/',parentRouter,'home']">{{'home' | translate}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link timeline-label pad10 poppins-medium font16 letterSpacing0px cursorPtr" *ngIf="parentRouter == 'users'" routerLinkActive="active" [routerLink]="['/',parentRouter,(currentUserBriindID || userData?.briind_id),'membership']">{{'memberships' | translate}}</a>
                            <a class="nav-link timeline-label pad10 poppins-medium font16 letterSpacing0px cursorPtr" [ngClass]="{'active': isActiveLink}" *ngIf="parentRouter == 'organizations'" routerLinkActive="active" [routerLink]="['/',parentRouter,(currentUserBriindID || userData?.briind_id),'membership', 'submitted']">{{'membership' | translate}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link timeline-label pad10 poppins-medium font16 letterSpacing0px cursorPtr" routerLinkActive="active" [routerLink]="['/',parentRouter,(currentUserBriindID || userData?.briind_id),'activity']">{{'activity' | translate}}</a>
                        </li>
                        <li class="nav-item posRelative profile-icon cursorPtr">
                            <a class="nav-link timeline-label pad10 poppins-medium font16 letterSpacing0px cursorPtr" routerLinkActive="active" [routerLink]="['/briindlet','engage']">{{'engage' | translate}}</a>
                            <div class="bdr-1-EEEEEE profile-menu posAbsolute posRight bg-white bdrRadius6px zIndex20">
                                <div class="">
                                    <div class="flex flexAlignItemsCenter padtop10 padleft20 padright20 padbtm10 ripple">
                                        <a [routerLink]='' (click)="openCreatePostPopup()"><span class="color-122A4D font15 poppins-regular">{{'create a post'| translate}}</span></a>
                                    </div>
                                    <div class="flex flexAlignItemsCenter padtop10 padleft20 padright20 padbtm10 ripple">
                                        <a [routerLink]="['/briindlet','banner']"><span class="color-122A4D font15 poppins-regular">{{'promote an event'|
                            translate}}</span></a>
                                    </div>
                                    <div class="flex flexAlignItemsCenter padtop10 padleft20 padright20 padbtm10 ripple">
                                        <span class="color-122A4D font15 poppins-regular" routerLinkActive="active" [routerLink]="['/briindlet','engage','promote-organization']">{{'promote organization' | translate}}</span>
                                    </div>
                                    <div class="flex flexAlignItemsCenter padtop10 padleft20 padright20 padbtm10 ripple">
                                        <span class="color-122A4D font15 poppins-regular" [routerLink]="['/advertisement','list']">{{'create advertisement' | translate}}</span>
                                    </div>
                                    <div class="flex flexAlignItemsCenter padtop10 padleft20 padright20 padbtm10 ripple">
                                        <span class="color-122A4D font15 poppins-regular" [routerLink]="['/',parentRouter,(currentUserBriindID || userData?.briind_id),'groups']">{{'create a group' | translate}}</span>
                                    </div>
                                    <div class="flex flexAlignItemsCenter padtop10 padleft20 padright20 padbtm10 ripple">
                                        <span class="color-122A4D font15 poppins-regular" [routerLink]="['/organizations',(currentUserBriindID || userData?.briind_id),'membership','volunteer']">{{'volunteer management' | translate}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link timeline-label pad10 poppins-medium font16 letterSpacing0px cursorPtr" routerLinkActive="active" [routerLink]="['/briindlet','banner']">{{'briindlet' | translate}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link timeline-label pad10 poppins-medium font16 letterSpacing0px cursorPtr" [routerLink]="" (click)="openInviteUserPopup()">{{'invite' | translate}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="pad10 posRelative" [routerLink]="['/chat']">
                                <div class="header-icons ripple">
                                    <i class="icns icon-comment {{iconClass}} d15"></i>
                                </div>
                                <span class="red-dot-num" *ngIf="unreadMessage">{{unreadMessage}}</span>
                            </a>
                        </li>
                        <li class="nav-item posRelative profile-icon cursorPtr">
                            <a class="pad10 posRelative">
                                <div class="header-icons ripple">
                                    <i class="icns icon-bell {{iconClass}} d15"></i>
                                </div>
                                <span class="red-dot-num" *ngIf="notificationCount != 0">{{notificationCount}}</span>
                            </a>
                            <app-header-notification [notificationList]="notificationList"></app-header-notification>
                        </li>

                        <li class="nav-item posRelative profile-icon cursorPtr">
                            <a class="pad10 posRelative flex">
                                <span class="online-img-shadow {{iconClass}} bdrRadius50p">
                          <img [src]="userData?.profile_pic" class="bdrRadius50p d50">
                      </span>
                            </a>
                            <div class="bdr-1-EEEEEE profile-menu posAbsolute posRight bg-white bdrRadius6px zIndex20">
                                <div class="pad15">
                                    <div class="name-container txtCenter padbtm10 bdr-btm-2-EEEEEE " [routerLink]="routerLinkForProfile">
                                        <div class=" timeline-label poppins-semibold font18 txtTransformCaps">{{userData?.name}}</div>
                                        <div class="color-8E8E8E font14 poppins-regular  txtNoWrap overflowHidden txtOverflowEllipsis">{{userData?.personal_information?.about_me || data?.social_profile?.short_bio}}</div>
                                    </div>
                                </div>
                                <div class="">
                                    <div class="flex flexAlignItemsCenter padtop10 padleft15 padright15 padbtm10 ripple" [routerLink]="routerLinkForProfile">
                                        <i class="icns icon-user {{iconClass}} d18"></i>
                                        <div class="pad5"></div>
                                        <span class="timeline-label font15 poppins-regular">{{'my profile'| translate}}</span>
                                    </div>
                                    <div class="flex flexAlignItemsCenter padtop10 padleft15 padright15 padbtm10 ripple" [routerLink]="['/faq']">
                                        <i class="icns icon-help {{iconClass}} d18"></i>
                                        <div class="pad5"></div>
                                        <span class="timeline-label font15 poppins-regular">{{'help'| translate}}</span>
                                    </div>
                                    <div class="flex flexAlignItemsCenter padtop10 padleft15 padright15 padbtm10 ripple" [routerLink]="['/',parentRouter,(currentUserBriindID || userData?.briind_id),'settings']">
                                        <i class="icns icon-settings {{iconClass}} d18"></i>
                                        <div class="pad5"></div>
                                        <span class="timeline-label font15 poppins-regular"> {{'settings' | translate}}</span>
                                    </div>
                                    <div class="flex flexAlignItemsCenter padtop10 padleft15 padright15 padbtm10 ripple" *ngIf="parentRouter == 'users'" [routerLink]="['/','briindlet-performance','user-analytics-report']">
                                        <i class="icns icon-search {{iconClass}} d18"></i>
                                        <div class="pad5"></div>
                                        <span class="timeline-label font15 poppins-regular">{{'report analysis' | translate}}</span>
                                    </div>
                                    <div class="flex flexAlignItemsCenter padtop10 padleft15 padright15 padbtm10 ripple" *ngIf="parentRouter == 'organizations'" [routerLink]="['/','briindlet-performance','organization-analytics-report']">
                                        <i class="icns icon-search {{iconClass}} d18"></i>
                                        <div class="pad5"></div>
                                        <span class="timeline-label font15 poppins-regular">{{'report analysis' | translate}}</span>
                                    </div>
                                    <div (click)="logoutCTA()" class="flex flexAlignItemsCenter padtop10 padleft15 padright15 padbtm10 ripple">
                                        <i class="icns icon-logout {{iconClass}} d18"></i>
                                        <div class="pad5"></div>
                                        <span class="timeline-label font15 poppins-regular">{{'logout'| translate}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="mobiVisible">
                    <ul class="nav-ul flex mrgn0 flexAlignItemsCenter">
                        <li class="nav-item">
                            <a class="pad5 ripple posRelative bdrRadius50p">
                                <i class="icns icon-comment d25"></i>
                                <span class="red-dot"></span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="pad5 ripple posRelative bdrRadius50p">
                                <i class="icns icon-bell d25"></i>
                                <span class="red-dot"></span>
                            </a>
                        </li>
                        <li class="nav-item posRelative profile-icon cursorPtr">
                            <a class="pad10 posRelative flex">
                                <span class="online-img-shadow {{iconClass}} bdrRadius50p">
                          <img [src]="userData?.profile_pic" class="bdrRadius50p d50">
                      </span>
                            </a>
                            <div class="bdr-1-EEEEEE profile-menu posAbsolute posRight bg-white bdrRadius6px zIndex20">
                                <div class="pad15">
                                    <div class="name-container txtCenter padbtm10 bdr-btm-2-EEEEEE " [routerLink]="['/',parentRouter,data?.id,'timeline']">
                                        <div class=" timeline-label poppins-semibold font18 txtTransformCaps">{{userData?.name}}</div>
                                        <div class="color-8E8E8E font14 poppins-regular  txtNoWrap overflowHidden txtOverflowEllipsis">{{userData?.personal_information?.about_me || data?.social_profile?.short_bio}}</div>
                                    </div>
                                </div>
                                <div class="">
                                    <div class="flex flexAlignItemsCenter padtop10 padleft15 padright15 padbtm10 ripple" [routerLink]="['/',parentRouter,(currentUserBriindID || userData?.briind_id),'timeline']">
                                        <i class="icns icon-user {{iconClass}} d18"></i>
                                        <div class="pad5"></div>
                                        <span class="timeline-label font15 poppins-regular">{{'my profile'| translate}}</span>
                                    </div>
                                    <div class="flex flexAlignItemsCenter padtop10 padleft15 padright15 padbtm10 ripple" [routerLink]="['/faq']">
                                        <i class="icns icon-help {{iconClass}} d18"></i>
                                        <div class="pad5"></div>
                                        <span class="timeline-label font15 poppins-regular">{{'help'| translate}}</span>
                                    </div>
                                    <div class="flex flexAlignItemsCenter padtop10 padleft15 padright15 padbtm10 ripple" [routerLink]="['/',parentRouter,userData?.id,'settings']">
                                        <i class="icns icon-settings {{iconClass}} d18"></i>
                                        <div class="pad5"></div>
                                        <span class="timeline-label font15 poppins-regular">{{'settings' | translate}}</span>
                                    </div>
                                    <div class="flex flexAlignItemsCenter padtop10 padleft15 padright15 padbtm10 ripple" *ngIf="parentRouter == 'users'" [routerLink]="['/','briindlet-performance','user-analytics-report']">
                                        <i class="icns icon-search {{iconClass}} d18"></i>
                                        <div class="pad5"></div>
                                        <span class="timeline-label font15 poppins-regular">{{'report analysis' | translate}}</span>
                                    </div>
                                    <div class="flex flexAlignItemsCenter padtop10 padleft15 padright15 padbtm10 ripple" *ngIf="parentRouter == 'organizations'" [routerLink]="['/','briindlet-performance','organization-analytics-report']">
                                        <i class="icns icon-search {{iconClass}} d18"></i>
                                        <div class="pad5"></div>
                                        <span class="timeline-label font15 poppins-regular">{{'report analysis' | translate}}</span>
                                    </div>
                                    <div (click)="logoutCTA()" class="flex flexAlignItemsCenter padtop10 padleft15 padright15 padbtm10 ripple">
                                        <i class="icns icon-logout {{iconClass}} d18"></i>
                                        <div class="pad5"></div>
                                        <span class="timeline-label font15 poppins-regular">{{'logout'| translate}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <button class="outline0 bdrNone padtop5 padbtm5 bgTransparent mobiVisible ripple" (click)="mobileNavToggleCTA()">
              <i class="icns icon-hamburger"></i>
            </button>
        </div>

    </div>
    <div class="width100 mobile-nav" #mobileNav>
        <div class="mobiVisible width100">
            <div class="ripple">
                <a class="color-061F45 padtop10 padbtm10 displayblock font18 letterSpacing0px cursorPtr" routerLinkActive="active" [routerLink]="['/',parentRouter,'home']">{{'home' | translate}}</a>
            </div>
            <div class="ripple">
                <a class="color-061F45 padtop10 padbtm10 displayblock font18 letterSpacing0px cursorPtr" *ngIf="parentRouter == 'users'" routerLinkActive="active" [routerLink]="['/',parentRouter,'/membership']">{{'memberships' | translate}}</a>
                <a class="color-061F45 padtop10 padbtm10 displayblock font18 letterSpacing0px cursorPtr" *ngIf="parentRouter == 'organizations'" routerLinkActive="active" [routerLink]="['/',parentRouter,'/membership','submitted']">{{'membership' | translate}}</a>
            </div>
            <div class="ripple">
                <a class="color-061F45 padtop10 padbtm10 displayblock font18 letterSpacing0px cursorPtr" routerLinkActive="active" [routerLink]="['/',parentRouter,'/activity']">{{'activity' | translate}}</a>
            </div>
            <div class="ripple">
                <a class="color-061F45 padtop10 padbtm10 displayblock font18 letterSpacing0px cursorPtr" routerLinkActive="active" [routerLink]="['/briindlet','engage']">{{'engage' | translate}}</a>
                <div class="bdr-1-EEEEEE profile-menu posAbsolute posRight bg-white bdrRadius6px">
                    <div class="">
                        <div class="flex flexAlignItemsCenter padtop10 padleft20 padright20 padbtm10 ripple">
                            <span class="color-122A4D font15 poppins-regular">{{'create a post'| translate}}</span>
                        </div>
                        <div class="flex flexAlignItemsCenter padtop10 padleft20 padright20 padbtm10 ripple">
                            <span class="color-122A4D font15 poppins-regular">{{'promote an event'| translate}}</span>
                        </div>
                        <div class="flex flexAlignItemsCenter padtop10 padleft20 padright20 padbtm10 ripple">
                            <span class="color-122A4D font15 poppins-regular" routerLinkActive="active" [routerLink]="['/briindlet','engage','promote-organization']">{{'promote organization' | translate}}</span>
                        </div>
                        <div class="flex flexAlignItemsCenter padtop10 padleft20 padright20 padbtm10 ripple">
                            <span class="color-122A4D font15 poppins-regular">{{'create advertisement' | translate}}</span>
                        </div>
                        <div class="flex flexAlignItemsCenter padtop10 padleft20 padright20 padbtm10 ripple">
                            <span class="color-122A4D font15 poppins-regular">{{'create a group' | translate}}</span>
                        </div>
                        <div class="flex flexAlignItemsCenter padtop10 padleft20 padright20 padbtm10 ripple">
                            <span class="color-122A4D font15 poppins-regular">{{'volunteer management' | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ripple">
                <a class="color-061F45 padtop10 padbtm10 displayblock font18 letterSpacing0px cursorPtr" routerLinkActive="active" [routerLink]="['/briindlet','banner']">{{'briindlet' | translate}}</a>
            </div>
        </div>
    </div>
</header>