<div id="popupContainer" class="flex flexJustifyCenter flexAlignItemsCenter cropperPopUp">
  <div id="popupOverlay" (click)="closePopup()"></div>
  <div id="popupContentBox">
    <div class="defaultPopUp">
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [roundCropper]="imageType == 'profile_pic' ? true : false "
        [aspectRatio]=" (cropingRatio ? cropingRatio : (imageType == 'profile_pic' ? 1 / 1 : 4 / 2 ))"
        [transform]="transform"
        [resizeToWidth]="100"
        format="png"
        (imageCropped)="imageCropped($event)"
      ></image-cropper>
      <div class="mt-4 text-center zoom-btns">
        <span (click)="zoomOut()" class="displayFlex bdrRadius5px cursorPtr flexAlignItemsCenter flexJustifyCenter d35"
          [ngClass]="{'bg-02457A': userType.toLowerCase() == 'user', 'bg-18878C': userType.toLowerCase() == 'organization'}">-</span>
        <span (click)="zoomIn()" class="displayFlex bdrRadius5px cursorPtr flexAlignItemsCenter flexJustifyCenter d35"
          [ngClass]="{'bg-02457A': userType.toLowerCase() == 'user', 'bg-18878C': userType.toLowerCase() == 'organization'}">+</span>
      </div>
      <div class="text-center mt-4">
        <button type="button" (click)="uploadImage()" class="btn-get-started font13 ripple poppins-medium cursorPtr"
          [ngClass]="{'btn-02457A': userType.toLowerCase() == 'user', 'btn-18878C': userType.toLowerCase() == 'organization'}">
          {{'save' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>