import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { OrganizationService } from '../../../../organizations/service/organization.service';
import { OrganizationStoreService } from '../../../../organizations/store/organization-store.service';

@Component({
  selector: 'app-add-leader-popup',
  templateUrl: './add-leader-popup.component.html',
  styleUrls: ['./add-leader-popup.component.scss']
})
export class AddLeaderPopupComponent implements OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  form: FormGroup = new FormGroup({});
  selectedFile: any;
  disabledOnClick: boolean = false;
  subscription: Subscription = new Subscription();
  imagePath: any = 'assets/images/add-pic-layer.png';
  listError: any;
  leaderPrivacy: string = '';
  constructor(
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    private organizationStoreService: OrganizationStoreService
  ) {
    this.form = fb.group({
      first_name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      mobile_number: new FormControl('', [Validators.required]),
      search: new FormControl('')
    })
  }


  /**
   * @description
   * check the form validation based on briind id text box
   *
   * @memberof AddLeaderPopupComponent
   */
  changeBriindId() {
    if (this.form.value.search) {
      this.form.controls['first_name'].setErrors(null);
      this.form.controls['first_name'].clearValidators();              
      this.form.controls['email'].setErrors(null);
      this.form.controls['email'].clearValidators();              
      this.form.controls['mobile_number'].setErrors(null);
      this.form.controls['mobile_number'].clearValidators();
      this.form.patchValue({
        email: '',
        first_name: '',
        mobile_number: ''
      })            
    } else {
      this.form.controls['first_name'].setValidators([Validators.required]);
      this.form.controls['email'].setValidators([Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]);
      this.form.controls['mobile_number'].setValidators([Validators.required]);
    }
    this.form.controls['first_name'].updateValueAndValidity();
    this.form.controls['email'].updateValueAndValidity();
    this.form.controls['mobile_number'].updateValueAndValidity();
  }

  /**
   * @description
   * this method is used for setting profile image of leader
   *
   * @param {*} [event]
   * @memberof AddLeaderPopupComponent
   */
  uploadImage(event ?: any) {
    var reader = new FileReader();
    this.selectedFile = event.target.files[0];
    reader.readAsDataURL(event.target.files[0]); 
    reader.onload = (_event) => { 
      this.imagePath = reader.result; 
    }
  }

  /**
   * @description
   * this method is using for get controls of the form 
   *
   * @readonly
   * @memberof AddLeaderPopupComponent
   */
  get control() { return this.form.controls; }

  /**
   * @description
   * this method will call when form submit
   *
   * @memberof AddLeaderPopupComponent
   */
  onSubmit() {
    this.disabledOnClick = true
    this.listError = undefined;
    let param = {...this.form.value, ...{organization: this.organizationStoreService.organizationId}, ...{show_leader: this.leaderPrivacy ? this.leaderPrivacy : 'private'} }
    if (!param.search) {
      delete param.search;
    } else {
      delete param.email;
      delete param.mobile_number;
      delete param.first_name;
      delete param.designation;
    }
    this.subscription.add(
      this.organizationService.createLeader(param).subscribe((response: any) => {
        this.organizationStoreService.profileDetail.leaders_list_info ? this.organizationStoreService.profileDetail.leaders_list_info.unshift(response.result) :  this.organizationStoreService.profileDetail.leaders_list_info = [response.result];
        this.closePopup();
      }, error => {
        this.listError = [];
        if  (typeof error.error.message === 'string' || error.error.message instanceof String) {
          this.listError = [error.error.message];
        } else {
          for (const key in error.error.message) {
            for (const item of  error.error.message[key]) {
              this.listError.push(item)
            }
          }
        }
        
      })
    )
  }

  onLeaderPrivacySelect(event: any) {
    this.leaderPrivacy = event;
  }
  /**
  * @description
  * Click on close button this method will call
  *
  * @memberof AddLeaderPopupComponent
  */
  closePopup() {
    this.close.emit('icon');
  }


  ngOnInit(): void {
    if(this.popup.context.data){
      console.log(this.popup.context.data)
    }
  }

  cancel(){
    this.form.reset()
  }

}
